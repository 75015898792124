<template>
  <section class="view-legal" v-html="state.imprint">
  </section>
</template>

<script lang="ts" setup>
import { useTenantStore } from "@/stores/useTenant";
import { onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";

const { getTenantContent, state } = useTenantStore();
const { locale } = useI18n();

watch(locale, async () => {
  await getTenantContent("imprint", locale.value);
});


onBeforeMount(async () => {
  await getTenantContent("imprint", locale.value);
});
</script>

<style lang="postcss" scoped>
.view-legal {
  @apply mx-3 p-10  flex flex-col bg-white rounded-xl;

  :deep(h1) {
    @apply text-3xl mb-4 font-bold
  }

  :deep(p){
    @apply text-base
  }

  :deep(h2) {
    @apply text-2xl font-bold
  }
}
</style>