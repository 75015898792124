<script setup lang="ts">
import BaseTranscript from "@/components/library/readers/transcriptReader/BaseTranscript.vue";
import { useMediaStore } from "@/stores/useMedia";
import VirtualList from "vue3-virtual-scroll-list";
import { Ref, ref, watch } from "vue";

/**
 * Docs
 * @URL https://github.com/Akryum/vue-virtual-scroller/blob/next/packages/vue-virtual-scroller/README.md
 */

const { state } = useMediaStore();

const transcriptContainerNode = ref<Ref<HTMLDivElement>>();
const virtualListComponent = ref<Ref<HTMLDivElement>>();

watch(() => state.player.currentTime, async (currentTime, oldValue) => {
  if (!state.transcription) return;
  let els = Array.from(document.querySelectorAll('pre'))
  els.filter(e => e.dataset.time < currentTime)
    .forEach(e => e.classList.add('active'))
  els.filter(e => e.dataset.time > currentTime)
    .forEach(e => e.classList.remove('active'))
  const words = document.querySelectorAll(".word.active")
  if(words.length > 0 && state.transcriptConfig.autoscroll){
    words[words.length - 1].scrollIntoView({ behavior: "smooth", block: "center" })
  }
  if(currentTime - oldValue > 10 || 0 > currentTime - oldValue){
    words[words.length - 1].scrollIntoView({ behavior: "smooth", block: "center" })
  }
});
</script>

<template>
  <transition name="fade">
    <div v-if="state.transcription" ref="transcriptContainerNode">
      <virtual-list ref="virtualListComponent" class="h-[calc(100vh-18rem)] overflow-y-auto"
                    data-key="id" :data-sources="state.transcription.paragraphs"
                    :data-component="BaseTranscript" />
    </div>
  </transition>
</template>

<style scoped lang="postcss">


</style>