import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive } from "vue";
import api from "@/services/tenantService";

export const useTenantStore = defineStore("tenantStore", () => {
  const state = reactive({
    privacy: null,
    imprint: null,
  });

  async function getTenantContent(content, languageCode = "de") {
    const response = await api.getTenantResource(content, languageCode);
    const { data, status } = await response.json();
    if(status === "success") {
      state[content] = data.text
    }
    return data;
  }

  return { state, getTenantContent };
});

// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useTenantStore, import.meta.hot));
}
