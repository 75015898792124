<template>
  <div
    :class="{'wrapper--thumbnail': imgMode === 'thumbnail', 'wrapper--image': imgMode === 'image', 'blur': isLoading, 'channels-thumb': mode === 'channels' }">
    <router-link v-if="route !== ''" :to="route" class="block" :class="{'pointer-events-none': !id}">
      <img :class="{'opacity-0': mediaType === 'audio'}"
           ref="image" :src="source" :alt="alt" />
      <div :class="['audio-wrapper', {'audio-wrapper--grid': view === 'grid'}]" v-if="mediaType === 'audio'">
        <font-awesome-icon v-if="view === 'grid'" class="audio-wrapper__icon"
                           icon="fa-regular fa-waveform-lines "></font-awesome-icon>
        <img class="audio-wrapper__icon audio-wrapper__icon--list" v-if="view === 'list'" :src="audioIcon" :alt="alt" />
      </div>
    </router-link>
    <img ref="image" :src="source" :alt="alt" v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useFetchFullURL } from "@/utils/useService.ts";
import defaultImage from "@/assets/icon.png";
import audioIcon from "@/assets/audio-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { mapStores } from "pinia";
import { useAppStore } from "@/stores/useApp";

export default defineComponent({
  name: "BaseImage",
  components: { FontAwesomeIcon },
  props: {
    id: {
      type: String,
      required: false
    },
    alt: {
      type: String,
      required: true
    },
    source: {
      type: String,
      default: defaultImage
    },
    thumbnail: {
      type: Object,
      required: false
    },
    imgMode: {
      type: String,
      default: "thumbnail",
      validator: (value: string) => ["thumbnail", "image"].includes(value)
    },
    route: {
      type: String,
      default: ""
    },
    mediaType: {
      type: String,
      validator: (val: string) => ["video", "image", "audio", "document"].includes(val),
      default: "video"
    },
    view: {
      type: String,
      validator: (val: string) => ["list", "grid"].includes((val)),
      default: "grid"
    }
  },
  inject: ['mode'],
  data() {
    return {
      isLoading: false,
      audioIcon: audioIcon

    };
  },

  computed: {
    ...mapStores(useAppStore)
  },

  methods: {
    async fetchThumbnail(data) {
      if (!data) return;
      const { url = "" } = data;
      if (url === "") return;
      try {
        this.isLoading = true;
        const response = await useFetchFullURL(url);
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        this.setImageSource(imageObjectURL);
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    setImageSource(imageObjectURL) {
      const image: HTMLImageElement = this.$refs.image;
      if (!image) return;
      image.setAttribute("src", imageObjectURL);
      image.onload = () => URL.revokeObjectURL(imageObjectURL);
    }
  },

  watch: {
    thumbnail: {
      handler(source) {
        if (this.imgMode === "thumbnail") {
          this.fetchThumbnail(this.thumbnail);
        }
      },
      immediate: true
    }
  }
})
;
</script>

<style lang="postcss" scoped>
.channels-thumb {
  img {
    @apply !object-cover;
  }
}
.wrapper--thumbnail {
  @apply transition-all w-full overflow-hidden rounded-lg relative;

  img {
    @apply w-full h-full aspect-video object-contain object-center;
  }

  &.no-aspect-radio {
    img {
      @apply aspect-auto
    }
  }

  .audio-wrapper {
    @apply absolute h-full w-full top-0 left-0 flex items-center justify-center;

    &--grid {
      @apply bg-gradient-to-br from-[#1264d4] to-purple-800
    }

    &__icon {
      @apply z-10 h-12 object-contain object-center text-white;

    }

    &__icon--list {
      @apply h-8
    }
  }
}

</style>
