<template>
  <detail-layout class="layout" v-if="mediaState.details">
    <template #top>
    </template>
    <template #default>
      <media-panel :key='mediaId' v-if="mediaState" :media-details="mediaState.details" />
    </template>
  </detail-layout>
</template>

<script lang="ts" setup>
// ** IMPORT COMPONENTS ** //
import DetailLayout from "@/components/library/layouts/DetailLayout.vue";
import MediaPanel from "@/components/views/channelMediaView/MediaPanel.vue";
// ** IMPORT FUNCTIONS ** //
import { onMounted  } from "vue";
// ** IMPORT STORES ** //
import { useMediaStore } from "@/stores/useMedia";
import { useConfigStore } from "@/stores/useConfig";
// ** IMPORT COMPOSABLES ** //

// ** STORE ** //
const { state: mediaState, fetchDetails: fetchMediaDetails } = useMediaStore();
const { setLayout, getTenantResources } = useConfigStore();

type Props = {
  mediaId: String // passed by router
}
const props = defineProps<Props>();

onMounted(async () => {
  try {
    await Promise.all([getTenantResources(), fetchMediaDetails(props.mediaId)]);
    document.title = "Media |" + mediaState.details?.name;
  } catch (e) {
    e.mediaId = props.mediaId;
    throw e;
  } finally {
    document.title = "Portal - " + mediaState.details?.name;
  }
});
</script>

<style lang="postcss" scoped>
.layout {
  display: flex !important;
  justify-content: center;
  align-items: stretch !important;
}

:deep(.layout__top) {
  @apply hidden;
}

:deep(.layout__right) {
  @apply hidden;
}

:deep(.layout__left) {
  @apply relative grow basis-full max-w-5xl 2xl:max-w-7xl -translate-x-1/2 left-1/2;
}
</style>
