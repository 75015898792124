<template>
  <span
      :class="[
      'badge',
      'badge--' + mode,
      'badge--' + size,
      'badge--' + borderRadius
    ]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: "primary",
      validator: (value) =>
          ["primary", "neutral", "dark", "light", "prominent", "primary-inverse"].includes(value)
    },
    borderRadius: {
      type: String,
      default: "r-normal",
      validator: (value) => ["r-normal", "r-large"].includes(value)
    },
    size: {
      type: String,
      default: "normal",
      validator: (value) => ["smallest", "small", "normal", "large"].includes(value)
    }
  }
};
</script>

<style lang="postcss" scoped>
.badge {
  @apply inline-block text-center text-neutral-50;

  /*////////////////
  // ** Radius ** //*/

  &--r-normal {
    @apply rounded-md
  }

  &--r-large {
    @apply rounded-full
  }


  /*////////////////
 // ** SIZE ** //*/

  &--smallest {
    @apply py-1 px-2 text-sm;
  }

  &--small {
    @apply p-2 text-sm;
  }

  &--normal {
    @apply py-2 px-5;
    /* default */
  }

  &--large {
    @apply py-4 px-8;
  }

  /*//////////////////
  // ** COLORS ** //*/

  &--dark {
    @apply bg-neutral-600;
  }

  &--primary {
    @apply bg-secondary-500;
  }

  &--primary-inverse {
    @apply bg-primary-200 text-neutral-800;
  }

  &--neutral {
    @apply bg-neutral-200 text-neutral-700;
  }

  &--light {
    @apply bg-neutral-100;
  }

  &--prominent {
    @apply bg-primary-500;
  }
}


</style>
