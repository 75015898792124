import { useFetch } from "@/utils/useService";
import { useConfigStore } from "@/stores/useConfig";
import { computed } from "vue";

export default function() {
  const { apiConfig } = useConfigStore();
  const url = computed(() => `/channels/${apiConfig.tenantName && !apiConfig.tenantName.includes("localhost") ? apiConfig.tenantName : "dev-internal"}/public`);

  async function getPublicChannels(queryString: string = "") {
    return queryString === "" ? useFetch(url.value, { method: "GET" }) : useFetch(`${url.value}?expand=media&${queryString}`, { method: "GET" });
  }

  return { getPublicChannels, url };
};