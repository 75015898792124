import { toRaw, reactive } from "vue";

interface Error {
  code: 400 | 401 | 404 | 403 | 404 | 405 | 406 | 422,
  message: string | null,
  occurred: boolean,
}

export async function useAsyncFetch<T, L>(promise) {
  const state = reactive<{ data: T | null, headers: L | null, isFetching: Boolean, error: Error }>({
    isFetching: false,
    data: null,
    headers: null,
    error: {
      code: null,
      message: null,
      occurred: false
    }
  });
  try {
    state.isFetching = true;
    const response = await promise;

    if (response.status >= 400) {
      const res = await response.json();
      state.error.code = response.status;
      state.error.message = res.message;
      state.error.occurred = true;
      throw new Error(res.message);
    } else {
      state.headers = response.headers;
      const { data: resData } = await response.json();
      state.data = resData;
    }
  } catch (e) {
    console.error(e);
    state.error.message = e;
    throw toRaw(state.error);
  } finally {
    state.isFetching = false;
  }
  return {
    state
  };
}