<script setup lang="ts">
import BaseBadge from "@/components/library/ui/BaseBadge.vue";
import { getTimeFromDuration, formatDuration } from "@/utils/helpers";
import { useMediaStore } from "@/stores/useMedia";
import { Emitter } from "mitt";
import { computed, inject } from "vue";

interface Word {
  confidence: number,
  duration: number,
  startTime: number,
  word: string
}

interface Paragraph {
  paragraphId: string,
  speaker: string,
  speakerId: string,
  text: string,
  words: Word[],
}

interface Props {
  source: Paragraph;
}

const props = defineProps<Props>();

const { state } = useMediaStore();
const emitter = inject<Emitter<any>>("emitter");

const paragraphDuration = computed(() => {
  const duration = props.source.words.reduce((acc, word) => {
    return acc + Number(word.duration);
  }, 0);
  if(props.source.words && props.source.words.length > 0){
    const startTime = props.source.words[0].startTime;
    const endTime = props.source.words.at(-1).startTime + props.source.words.at(-1).duration;
    const duration = endTime - startTime;
    return formatDuration(duration);
  }
  return '';
});

function handleJumpToWord(startTime: number) {
  emitter.emit("player-jump-to-seconds", startTime);
}
</script>

<template>
  <div class="py-1.5 px-4">
    <header class="flex items-center gap-2">
      <base-badge border-radius="r-large" size="smallest" mode="primary-inverse">
        {{ getTimeFromDuration(+source.words[0].startTime) }}
      </base-badge>
      <h6 class="text-blue-800 !text-[0.875rem]">{{ source.speaker }}</h6>
      <span class="text-blue-800 !text-[0.875rem]">- {{ paragraphDuration }}</span>
    </header>
<!--    <article class="flex flex-wrap gap-1 text-sm font-inherit">-->
<!--      <pre-->
<!--        class="word"-->
<!--        v-for="(text, index) in source.words"-->
<!--        :data-time="text.startTime"-->
<!--        :key="index + text.word"-->
<!--      >-->
<!--        {{ text.word }}-->
<!--      </pre>-->
<!--    </article>-->
    <div class="words">
      <article
        class="words">
          <pre
            v-for="(word, index) in source.words"
            :key="'w-' + index"
            class="word"
            :id="source.paragraphId + '-w-' + index"
            :data-index="source.paragraphId + '-w-' + index"
            :data-time="word.startTime"
            :data-duration="word.duration + word.startTime"
            @dblclick.stop="handleJumpToWord(word.startTime)"
          > {{ word.word }}</pre>
      </article>
    </div>
  </div>
</template>

<style scoped lang="postcss">

p {
  @apply text-black;
}

pre {
  font-family: inherit;
}

.active{
  @apply !text-black;
}

.words {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  @apply inline-block w-full max-w-full;
}

.word {
  @apply transition-all duration-200 text-dark-500 inline max-w-full;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-word;
  outline: 0px solid transparent;
}

</style>