<script setup lang="ts">
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import MediaItem from "@/components/views/channelMediaView/MediaItem.vue";
import type { ChannelDetail } from "@/types/channelTypes";
import type { Media } from "@/types/mediaTypes";
import { useInfiniteScroll } from "@/composables/useInfiniteScroll";
import BaseObserver from "@/components/library/util/BaseObserver.vue";

defineProps<{ channelDetails: ChannelDetail, channelMedia: Media }>();

const { handleInfiniteScroll } = useInfiniteScroll();

</script>

<template>
  <header class='header'>

    <base-title tag="h6" class="title"> Playlist: {{ channelDetails.name }}</base-title>
  </header>
  <ul v-if="channelMedia.length" class="h-fit">
    <media-item v-for="media in channelMedia" v-bind="media" :channel-id="channelDetails.id" />
    <base-observer key="observer-card" :intersection-options="{root: null, rootMargin: '0px', threshold: 0.5,}"
                   @callback="handleInfiniteScroll" />
  </ul>
</template>

<style scoped lang="postcss">
.header {
  @apply p-4 rounded-t-md bg-neutral-200;
}
</style>