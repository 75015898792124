<script lang="ts">
import { defineComponent, resolveComponent, h, renderList, computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      required: true,
      default: []
    }
  },
  setup(props) {
    return () => h("nav", [
      h("ul", { class: "breadcrumb" }, renderList(props.items, (item, index) => {
        const isLastItem = index === props.items.length - 1;

        const content = item.path
          ? h(resolveComponent("router-link"), { class: "link", to: item.path }, () => item.label)
          : h("span", () => item.label);

        const chevron = !isLastItem ? h(resolveComponent("font-awesome-icon"), {
          class: "chevron",
          icon: ["far", "chevron-right"]
        }) : null;

        return h("li", { key: item.path, class: 'listItem' }, [content, chevron]);
      }))
    ]);
  }
});
</script>

<style scoped lang="postcss">
.breadcrumb {
  @apply flex items-center text-[[var(--mainColor)]] text-xl font-normal;

  > :last-child {
    @apply font-bold text-xl overflow-hidden text-ellipsis
  }
}
.listItem {
  @apply flex items-center;
}
.chevron {
  @apply w-6 h-4 flex flex-col text-[12px] text-dark-500;
}
</style>