import { createRouter, createWebHistory } from "vue-router";
import { paths } from "@/settings/settings";
import ChannelsView from '@/components/views/ChannelsView.vue';
import ChannelView from '@/components/views/ChannelView.vue';
import ChannelMediaView from '@/components/views/ChannelMediaView.vue';
import MediaView from '@/components/views/MediaView.vue';
import PrivacyPolicyView from '@/components/views/PrivacyPolicyView.vue';
import LegalNoticeView from '@/components/views/LegalNoticeView.vue';
import NotFoundView from '@/components/views/errors/NotFoundView.vue';
import NetworkErrorView from '@/components/views/errors/NetworkErrorView.vue';
import NProgress from "nprogress";

NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });

const router = createRouter({
  //  Webpack uses node env
  // history: createWebHistory(process.env.BASE_URL),
  // Vite
  // used es module system
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: paths.CHANNELS_ROUTE,
      name: "ChannelsView",
      component: ChannelsView
    },
    {
      path: paths.ROOT_ROUTE,
      redirect: paths.CHANNELS_ROUTE
    },
    {
      path: `${paths.CHANNELS_ROUTE}:channelId`,
      name: "ChannelView",
      component: ChannelView,
      props: true
      // children: [
      //   {
      //     path: `${paths.MEDIA_ROUTE}:mediaId`,
      //     name: "MediaDetail",
      //     component: () => import("@/components/views/MediaView.vue"),
      //     props: true
      //   }
      // ]
    },
    {
      path: `${paths.CHANNELS_ROUTE}:channelId${paths.MEDIA_ROUTE}:mediaId`,
      name: "ChannelMediaDetail",
      component: ChannelMediaView,
      props: true
    },
    {
      path: `${paths.MEDIA_ROUTE}:mediaId`,
      name: "MediaDetail",
      component: MediaView,
      props: true
    },
    {
      path: paths.PRIVACY_ROUTE,
      name: "datenschutz",
      component: PrivacyPolicyView
    },
    {
      path: paths.IMPRINT_ROUTE,
      name: "impressum",
      component: LegalNoticeView
    },
    // {
    //   path: "/:catchAll(.*)",
    //   name: "routeError",
    //   component: () => import("@/components/views/errors/ErrorView.vue")
    // },
    {
      path: "/404/:resource",
      name: "404Resource",
      component: NotFoundView,
      props: true
    },
    {
      path: "/network-error",
      name: "NetworkError",
      component: NetworkErrorView
    }

  ]
});

router.beforeEach(() => {
  NProgress.start();
});

router.afterEach(() => {
  NProgress.done();
});


export default router;