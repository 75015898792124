export function createQueryString(array, filterProperty = "tags") {
  let qs = "";
  array.forEach((value) => {
    qs += `%2b${filterProperty}=${value.name}`;
  });
  // remove %2b from start
  return qs.slice(3);
}

export function getFormattedDuration(timeString: string) {
  const [h, m, s] = timeString.split(":");
  let hour = parseInt(h);
  let minutes = parseInt(m);
  let seconds = parseInt(s);

  // Convert hour, minutes, and seconds to two-digit format
  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHour === "00" ? "" : formattedHour + ":"}${formattedMinutes}:${formattedSeconds}`;
}

export function getTimeFromDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  return hours.toString().padStart(2, '0') + ':' +
    minutes.toString().padStart(2, '0') + ':' +
    seconds.toString().padStart(2, '0');
}

export function formatDuration(duration) {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const hoursString = hours > 0 ? hours + 'h ' : '';
  const minutesString = minutes > 0 ? minutes + 'm ' : '';
  const secondsString = seconds > 0 ? seconds + 's' : '';

  return hoursString + minutesString + secondsString;
}

export class CustomError extends Error {
  public statusCode: number;
  public occurred: boolean;

  constructor(message, statusCode) {
    super(message);
    this.statusCode = statusCode;
    this.occurred = true;
  }
}