<template>
  <section class="view-privacy" v-html="state.privacy">
  </section>
</template>

<script setup>
import { useTenantStore } from "@/stores/useTenant";
import { onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";

const { getTenantContent, state } = useTenantStore();
const { locale } = useI18n();

watch(locale, async () => {
  await getTenantContent("privacy", locale.value);
});

onBeforeMount(async () => {
  await getTenantContent("privacy", locale.value);
});
</script>

<style lang="postcss" scoped>
.view-privacy {
  @apply mx-3 p-10  flex flex-col bg-white rounded-xl;

  :deep(h1) {
    @apply text-3xl mb-4
  }

  :deep(h2) {
    @apply text-2xl mb-2
  }

  :deep(h3) {
    @apply text-xl
  }

  :deep(p) {
    @apply text-base
  }

  :deep(li) {
    @apply list-disc ml-8;
  }
}
</style>