<template>
  <div class="h-fit max-h-[75vh] relative flex flex-col">
    <div v-if="isLoading" class="notifaction-panel absolute z-10 inset-0">
      {{ t('pdf.loading') }}
    </div>
    <div v-if="errorMsg" class="notifaction-panel text-2xl text-red-600">
      {{ errorMsg }}
    </div>
    <div v-if="!errorMsg" class="header">
      <div class="text-white flex items-center gap-3">
        <input
          min="1"
          type="number"
          :value="currentPage"
          class="w-[40px] text-center text-black"
          @change="handlePageChange($event.target.value)"
        />
        <span>/</span>
        <span>{{ totalPages }}</span>
      </div>
    </div>
    <VuePdfEmbed
      :id="`${id}-page`"
      class="overflow-y-auto flex-grow h-full w-full z-20"
      :annotation-layer="false"
      :text-layer="false"
      :source="docSource"
      @rendered="onRendered"
      @loading-failed="onError"
    />
  </div>
</template>

<script setup lang="ts">
import VuePdfEmbed from 'vue-pdf-embed'
import { ref, onBeforeMount, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFetchFullURL } from '@/utils/useService'

const { t } = useI18n()

const props = defineProps({
  id: {
    type: String
  },
  source: {
    type: String
  },
  textLayer: {
    type: Boolean,
    default: true
  },
  annotationLayer: {
    type: Boolean,
    default: true
  },
  showHeader: {
    type: Boolean,
    default: false
  },
  page: {
    type: Number
  }
})

const filterPage = ref(null)
const isLoading = ref(true)
const errorMsg = ref('')
const currentPage = ref(1)
const totalPages = ref(0)
const isScrolling = ref(false)
const docSource = ref('')

onMounted(() => {
  if (props.page) {
    filterPage.value = props.page
  }
})

async function fetchDoc() {
  try {
    const response = await useFetchFullURL(props.source)
    const docBlob = await response.blob()
    const docObjectURL = URL.createObjectURL(docBlob)
    docSource.value = docObjectURL
  } catch (e) {
    console.error(e)
  }
}

function onRendered() {
  isLoading.value = false
  const pages = document.querySelectorAll("div[id*='-page-']")
  const toArray = Array.from(pages)
  totalPages.value = toArray.length
  const rootElement = document.getElementById(`${props.id}-page`)
  if (filterPage.value) {
    currentPage.value = filterPage.value
    const element = document.getElementById(`${props.id}-page-${filterPage.value}`)
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const targetPage = entry.target.id.split('-')
        if (entry.intersectionRatio > 0.3 && !isScrolling.value) {
          currentPage.value = Number(targetPage.at(-1))
        }
      })
    },
    {
      root: rootElement,
      rootMargin: '0px',
      threshold: 0.5
    }
  )
  rootElement?.addEventListener('scroll', () => {
    if (filterPage.value) {
      if (filterPage.value !== currentPage.value) {
        currentPage.value = filterPage.value
      }
      isScrolling.value = true
    }
  })
  rootElement?.addEventListener('scrollend', () => {
    if (filterPage.value) {
      filterPage.value = null
    }
    isScrolling.value = false
  })
  // Track all sections that have an `id` applied
  pages.forEach((section) => {
    observer.observe(section)
  })
}
function onError(error) {
  if (error.name === 'InvalidPDFException') {
    errorMsg.value = t('pdf.invalidPDF')
  } else {
    errorMsg.value = t('pdf.unknownPDF')
  }
  isLoading.value = false
}
function handlePageChange(value) {
  currentPage.value = value
  const element = document.getElementById(`${props.id}-page-${value}`)
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth'
    })
  }
}
onBeforeMount(async function () {
  fetchDoc()
  const rootElement = document.getElementById(`${props.id}-page`)
  if (rootElement) {
    rootElement.removeEventListener('scroll', () => {
      isScrolling.value = true
    })
    rootElement.removeEventListener('scrollend', () => {
      isScrolling.value = false
    })
  }
})
</script>

<style scoped>
.notifaction-panel {
  @apply w-full h-full bg-white text-center flex items-center justify-center;
}

.header {
  @apply w-full p-3 sticky top-0 rounded-md rounded-b-none bg-neutral-700 flex justify-center;
}
</style>

<style>
.layout__left {
  max-height: fit-content !important;
}
</style>
