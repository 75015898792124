<template>
  <main ref="mainElement" class="main">
    <the-header v-if="!isStandAlone" ref="headerElement" class="main__header" />
    <div class="main__content" ref="mainContent">
      <slot></slot>
    </div>
  </main>
</template>

<script lang="ts" setup>
// *** COMPONENTS *** //
import TheHeader from "@/components/TheHeader.vue";
import SideMenu from "@/components/library/menus/SideMenu.vue";

// *** FUNCTIONS *** //
import { onMounted, ref, Ref, nextTick, computed, ComponentPublicInstance } from "vue";
import { useAppStore } from "@/stores/useApp";

interface CustomComponent extends ComponentPublicInstance {
}

const headerElement: Ref<CustomComponent | null> = ref(null);
const mainElement: Ref<HTMLDivElement | null> = ref(null);
const mainContent: Ref<HTMLDivElement | null> = ref(null);

const { state: appState } = useAppStore();

const isStandAlone = computed(() => appState.isStandAlone);

onMounted(() => {
  nextTick(() => {
    if (headerElement.value.$el) {
      const headerRectangle = headerElement.value.$el.getBoundingClientRect();
      // mainElement.value.style.paddingTop = headerRectangle.height + "px";
      // To make the main menu scrollable
      mainContent.value.style.height = `calc(100vh - ${headerRectangle.height}px`;
    }
  });
});

</script>

<style lang="postcss" scoped>
.main {
  @apply flex flex-col relative min-h-screen;
  background-color: var(--pageBackgroundColor);

  &__header {
    @apply sticky left-0 top-0;
    z-index: 50;
  }

  &__content {
    @apply grow overflow-y-auto overflow-x-hidden;
  }
}
</style>