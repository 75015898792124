<script setup lang="ts">
import { computed } from "vue";
import TranscriptReader from "@/components/library/readers/TranscriptReader.vue";
import SdSwitch from "@/components/library/forms/SdSwitch.vue";
import { useMediaStore } from "@/stores/useMedia";

// const media = computed(() => state.details);
const { state, setTranscriptConfig } = useMediaStore();

// const autoPause = computed({
//   get() {
//     return state.transcriptConfig.autoPause;
//   },
//   set(value) {
//     setTranscriptConfig({ key: "autoPause", value });
//   }
// });

const autoscroll = computed({
  get() {
    return state.transcriptConfig.autoscroll;
  },
  set(value) {
    setTranscriptConfig({ key: "autoscroll", value });
  }
});
</script>

<template>
  <div class="flex gap-4 px-4">
    <!--    <sd-switch v-model:value="autoPause" :label="$t('transcriptions.labels.autoPause')"></sd-switch>-->
    <sd-switch v-model:value="autoscroll" :label="$t('transcriptions.labels.autoscroll')"></sd-switch>
  </div>
  <transcript-reader />
</template>

<style scoped lang="postcss">
</style>