import { useFetch } from "@/utils/useService";

const resourceMedia = "/media";
const resourceMetadata = "metadata";
const resourceTranscript = "transcripts";
export default {
    fetchTranscript({ mediaId, transcriptId }: { mediaId: string, transcriptId: string }) {
        return useFetch(`${resourceMedia}/${mediaId}/${resourceMetadata}/${resourceTranscript}/${transcriptId}`, { method: "GET" });
    },
    fetchMetadata(id: string) {
        return useFetch(`${resourceMedia}/${id}/${resourceMetadata}`, { method: "GET" });
    }
};