<template>
  <aside :class="{'aside--closed': !isMenuOpen, aside: true}">
    <transition name="slide-left" appear>
      <upper-menu @click="handleClick" v-if="isMenuOpen" />
    </transition>
    <transition v-if="isMenuOpen" name="slide-left">
      <lower-menu @click="handleClick" />
    </transition>
    <base-button @click="toggleMenu" :aria-label="$t('altText.chevronBtn')" class="btn-chevron"
                 :class="{'btn-chevron--closed': !isMenuOpen}"
                 :icon="{name: 'chevron-left', type: 'fas'}" />
  </aside>
</template>

<script setup lang="ts">
import { nextTick, ref, watchEffect } from "vue";
import UpperMenu from "@/components/library/menus/sideMenu/UpperMenu.vue";
import LowerMenu from "@/components/library/menus/sideMenu/LowerMenu.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

const isMenuOpen = ref(true);
const breakpoints = useBreakpoints(breakpointsTailwind);

if (breakpoints.isSmallerOrEqual("lg")) {
  isMenuOpen.value = false;
}

watchEffect(() => {
  // Freezes when menu is open and screen is resized to lg or larger
  if (breakpoints.isSmallerOrEqual("lg") && isMenuOpen.value) {
    nextTick(() => {
      const body = document.getElementById("mainContent");
      body.style.overflow = "hidden";
    });
  }
  if (breakpoints.isSmallerOrEqual("lg") && !isMenuOpen.value) {
    nextTick(() => {
      const body = document.getElementById("mainContent");
      body.style.overflow = "auto";
    });
  }
});

function handleClick(event) {
  if (breakpoints.isSmallerOrEqual("lg")) {
    // Prevents the click event from bubbling up to the parent element
    // Closes the menu on mobile when a link is clicked
    toggleMenu();
  }
}

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<style lang="postcss" scoped>
.aside {
  @apply pl-3 pb-4 w-[15rem] lg:w-[16rem] flex flex-col justify-between transition-all h-full;

  &--closed {
    @apply w-0 p-0;
  }

  .btn-chevron {
    @apply absolute z-20 bottom-2 right-0 bg-dark-500 flex items-center justify-center w-[2.5rem] h-[2.5rem] text-white text-[1.5rem] rounded-lg transition-transform;

    &--closed {
      @apply rotate-180 translate-x-20;
    }
  }
}
</style>