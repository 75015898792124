import { defineStore, acceptHMRUpdate } from 'pinia'
import { reactive } from 'vue'
import useApiService from '@/services/channelsService'
import { useAsyncFetch } from '@/composables/useAsyncFetch'
// import { StorageSerializers, useSessionStorage } from "@vueuse/core";
import { useConfigStore } from '@/stores/useConfig'
import type { PublicChannel } from '@/types/publicChannels'
import { useRouter } from 'vue-router'

export const useChannelsStore = defineStore('channelsStore', () => {
  const router = useRouter()
  const { getPublicChannels, url } = useApiService()

  const state = reactive<{
    featuredList: PublicChannel[] | []
    data: PublicChannel[] | []
    isFetchingAll: boolean
    totalCount: number
    isFetchingFeatured: boolean
    isFetchingCompressedList: boolean
    isFetchingMoreChannels: boolean
  }>({
    featuredList: [],
    data: [],
    totalCount: 0,
    isFetchingAll: false,
    isFetchingFeatured: false,
    isFetchingCompressedList: false,
    isFetchingMoreChannels: false
  })
  const { filters, apiConfig, setMaxPage } = useConfigStore()
  const { payloadConfig } = apiConfig

  async function fetchAll() {
    const channelsFilter = filters.channels
    // Create querystring comes from local storage
    let queryString = `page=${payloadConfig.channels.page}&perpage=${payloadConfig.channels.PER_PAGE}`
    queryString += '&sort=' + channelsFilter.property
    if (channelsFilter.sort !== '') {
      queryString += channelsFilter.sort
    }
    try {
      state.isFetchingAll = true
      // Cache Request
      // const cached = useSessionStorage(api.url, null, {
      //   serializer: StorageSerializers.object
      // });
      //
      // if (cached.value) {
      //   console.log(`Getting value from cache for ${api.url}`);
      //   state.data = cached.value;
      //   return cached.value;
      // }
      // console.log("cached", cached.value);
      // Fetch Request
      const { state: resState } = await useAsyncFetch<PublicChannel[], Object>(
        getPublicChannels(queryString)
      )
      // cached.value = resState.data;

        // @ts-ignore
        const totalChannelsCount = resState.headers.get("x-total-count");
        state.totalCount = totalChannelsCount;
        setMaxPage({ storeKey: "channels", count: +totalChannelsCount });
        state.data = resState.data;
        return resState;
      } catch (e) {
        throw e;
      } finally {
        state.isFetchingAll = false;
      }
    }

  async function fetchMore() {
    state.isFetchingMoreChannels = true
    try {
      // Create Query String
      let queryString = `page=${payloadConfig.channels.page}&perpage=${payloadConfig.channels.PER_PAGE}&sort=${filters.channels.property}${filters.channels.sort}`
      // Fetch query
      const { state: resState } = await useAsyncFetch<PublicChannel[], Object>(
        getPublicChannels(queryString)
      )
      // push new data to state
      // @ts-ignore type is declared? PublicChannel[]
      state.data.push(...resState.data)
      return resState
    } catch (e) {
      throw e
    } finally {
      state.isFetchingMoreChannels = false
    }
  }

  async function fetchAllFeatured() {
    const channelsFilter = filters.channels
    // Create querystring comes from local storage
    let queryString = ''
    queryString += 'sort=' + channelsFilter.property

    if (channelsFilter.sort !== '') {
      queryString += channelsFilter.sort
    }
    queryString += '&compressed=true'
    try {
      state.isFetchingFeatured = true
      const { state: resState } = await useAsyncFetch<PublicChannel[], Object>(
        getPublicChannels(queryString)
      )

      const featuredChannel = resState.data
      // WE HAVE TO UPDATE THIS ENDPOINT ASAP API POSSIBLE
      state.featuredList = featuredChannel
      return featuredChannel
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      state.isFetchingFeatured = false
    }
  }

  return { state, fetchAll, fetchMore, fetchAllFeatured }
})

// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useChannelsStore, import.meta.hot))
}
