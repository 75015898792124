<template>
  <div :class="{ 'switch-wrapper': useFlex }">
    <div class="toggle-container">
      <input
        :id="id"
        :checked="value"
        @input="handleInput"
        class="toggle toggle-checkbox"
        name="toggle"
        type="checkbox"
        v-tippy="{content:tooltip, theme: 'light'}"
      />
      <label
        :class="['toggle-label', 'label--' + labelSize]"
        :for="id"
      ></label>
    </div>
    <label class="label-switch" :class="{ labelFirst: setLabelFirst }" for="toggle">{{
        label
      }}</label>
  </div>
</template>

<script>
import uniqueId from "lodash.uniqueid";

export default {
  name: "SdSwitch",
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: null },
    labelSize: {
      type: String,
      default: "small",
      validator(value) {
        return ["small", "normal", "large"].includes(value);
      }
    },
    useFlex: { type: Boolean, default: false },
    setLabelFirst: { type: Boolean, default: false },
    tooltip: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: uniqueId()
    };
  },
  methods: {
    handleInput(event) {
      this.$emit("update:value", event.target.checked);
      this.$emit("change", event.target.checked);
      this.$emit("input", event.target.checked);
      // this.selectedValue = event.target.checked;
    }
  },

};
</script>

<style lang="postcss" scoped>
.switch-wrapper {
  @apply flex items-center gap-2;
}

.toggle {
  @apply absolute block w-6 h-6 rounded-full bg-white border-0 appearance-none cursor-pointer;
}

.toggle-label {
  @apply block overflow-hidden h-6 rounded-full bg-neutral-400 cursor-pointer transition-all duration-200 ease-in;
}

.toggle-container {

  @apply relative inline-block mr-2 w-10 align-middle select-none transition-all duration-200 ease-in;
}

label {
  @apply text-neutral-500 self-center mb-1;
}

.label--small {
  @apply text-xs;
}

.label--normal {
  @apply text-base;
}

.label--large {
  @apply text-lg;
}

.toggle-checkbox {
  @apply ml-0 border-0;
  transform: scale(0.8);
  transition: margin 200ms ease-in;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.25);
}

.toggle-checkbox:checked {
  @apply border-secondary-500 ml-4;
}

.toggle-checkbox:checked + .toggle-label {
  @apply bg-secondary-500;
}

.labelFirst {
  @apply -order-1;
}
</style>
