<template>
  <section>
    <!-- Only show if changes were made   -->
    <search-filter-section
      v-for="option in options"
      :key="option.title"
      v-bind="option"
    />
  </section>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { useRoute } from "vue-router";
import type { OptionsSetting } from "@/components/library/search/types/options";
import SearchFilterSection from "@/components/library/search/SearchFilterSection.vue";

const route = useRoute();

const options = ref<OptionsSetting[]>([
  {
    type: "btn",
    title: "search.reset",
    storeKey: "reset"
  },
  {
    type: "dropdown",
    title: "search.fieldsTitle",
    children: [
      {
        title: "search.titles.title",
        storeKey: "fields_name",
        type: "toggle"
      },
      {
        title: "search.titles.description",
        storeKey: "fields_description",
        type: "toggle"
      },
      {
        title: "search.titles.tags",
        storeKey: "fields_tag",
        type: "toggle"
      },
      {
        title: "search.titles.transcription",
        storeKey: "fields_transcript",
        type: "toggle"
      },
      {
        title: "search.titles.speakers",
        storeKey: "fields_speakers",
        type: "toggle"
      },
      {
        title: "search.titles.entities",
        storeKey: "fields_entities",
        type: "toggle"
      },
      {
        title: "search.titles.keywords",
        storeKey: "fields_keywords",
        type: "toggle"
      },
      {
        title: "search.titles.actions",
        storeKey: "fields_actions",
        type: "toggle"
      },
      {
        title: "search.titles.objects",
        storeKey: "fields_objects",
        type: "toggle"
      }
    ]
  },
  // { still not implemented in the API
  //   type: 'dropdown',
  //   title: 'date.date',
  //   children: [
  //     {
  //       title: "search.titles.from",
  //       storeKey: 'date.from',
  //       type: 'date'
  //     },
  //     {
  //       title: "search.titles.to",
  //       storeKey: 'date.to',
  //       type: 'date'
  //     }
  //   ]
  // },
  {
    title: "search.titles.files",
    type: "dropdown",
    children: [
      {
        title: "search.titles.from",
        storeKey: "filesize.from",
        type: "range",
        placeholder: "in MB"
      },
      {
        title: "search.titles.to",
        storeKey: "filesize.to",
        type: "range",
        placeholder: "in MB"
      },
      {
        title: "search.titles.video",
        storeKey: "assetTypes_video",
        type: "toggle"
      },
      {
        title: "search.titles.image",
        storeKey: "assetTypes_image",
        type: "toggle"
      },
      {
        title: "search.titles.audio",
        storeKey: "assetTypes_audio",
        type: "toggle"
      }
    ]
  },
  {
    title: "search.bitrate",
    type: "dropdown",
    children: [
      // children of children?
      {
        title: "search.titles.from",
        storeKey: "bitrate.from",
        type: "range",
        placeholder: "in Mbps"
      },
      {
        title: "search.titles.to",
        storeKey: "bitrate.to",
        type: "range",
        placeholder: "in Mbps"
      }
    ]
  },
  {
    title: "search.duration",
    type: "dropdown",
    children: [
      {
        title: "search.titles.from",
        storeKey: "duration.from",
        type: "range",
        placeholder: "Seconds"
      },
      {
        title: "search.titles.to",
        storeKey: "duration.to",
        type: "range",
        placeholder: "Seconds"
      }
    ]
  },
  {
    title: "search.width",
    type: "dropdown",
    children: [
      {
        title: "search.titles.from",
        storeKey: "width.from",
        type: "range",
        placeholder: "Pixels"
      },
      {
        title: "search.titles.to",
        storeKey: "width.to",
        type: "range",
        placeholder: "Pixels"

      }
    ]
  },
  {
    title: "search.height",
    type: "dropdown",
    children: [
      {
        title: "search.titles.from",
        storeKey: "height.from",
        type: "range",
        placeholder: "Pixels"

      },
      {
        title: "search.titles.to",
        storeKey: "height.to",
        type: "range",
        placeholder: "Pixels"
      }
    ]
  }
]);

watch(() => route.name, (newVal) => {
  if (newVal === "ChannelsView") {
    options.value = [
      {
        type: "btn",
        title: "search.reset",
        storeKey: "reset"
      },
      {
        type: "dropdown",
        title: "search.fieldsTitle",
        children: [
          {
            title: "search.titles.title",
            storeKey: "fields_name",
            type: "toggle"
          },
          {
            title: "search.titles.description",
            storeKey: "fields_description",
            type: "toggle"
          }
        ]
      }];
  } else {
    options.value = [
      {
        type: "btn",
        title: "search.reset",
        storeKey: "reset"
      },
      {
        type: "dropdown",
        title: "search.fieldsTitle",
        children: [
          {
            title: "search.titles.title",
            storeKey: "fields_name",
            type: "toggle"
          },
          {
            title: "search.titles.description",
            storeKey: "fields_description",
            type: "toggle"
          },
          {
            title: "search.titles.tags",
            storeKey: "fields_tag",
            type: "toggle"
          },
          {
            title: "search.titles.transcription",
            storeKey: "fields_transcript",
            type: "toggle"
          },
          {
            title: "search.titles.speakers",
            storeKey: "fields_speakers",
            type: "toggle"
          },
          {
            title: "search.titles.entities",
            storeKey: "fields_entities",
            type: "toggle"
          },
          {
            title: "search.titles.keywords",
            storeKey: "fields_keywords",
            type: "toggle"
          },
          {
            title: "search.titles.actions",
            storeKey: "fields_actions",
            type: "toggle"
          },
          {
            title: "search.titles.objects",
            storeKey: "fields_objects",
            type: "toggle"
          }
        ]
      },
      // { still not implemented in the API
      //   type: 'dropdown',
      //   title: 'date.date',
      //   children: [
      //     {
      //       title: "search.titles.from",
      //       storeKey: 'date.from',
      //       type: 'date'
      //     },
      //     {
      //       title: "search.titles.to",
      //       storeKey: 'date.to',
      //       type: 'date'
      //     }
      //   ]
      // },
      {
        title: "search.titles.files",
        type: "dropdown",
        children: [
          {
            title: "search.titles.from",
            storeKey: "filesize.from",
            type: "range",
            placeholder: "in MB"
          },
          {
            title: "search.titles.to",
            storeKey: "filesize.to",
            type: "range",
            placeholder: "in MB"
          },
          {
            title: "search.titles.video",
            storeKey: "assetTypes_video",
            type: "toggle"
          },
          {
            title: "search.titles.image",
            storeKey: "assetTypes_image",
            type: "toggle"
          },
          {
            title: "search.titles.audio",
            storeKey: "assetTypes_audio",
            type: "toggle"
          }
        ]
      },
      {
        title: "search.bitrate",
        type: "dropdown",
        children: [
          // children of children?
          {
            title: "search.titles.from",
            storeKey: "bitrate.from",
            type: "range",
            placeholder: "in Mbps"
          },
          {
            title: "search.titles.to",
            storeKey: "bitrate.to",
            type: "range",
            placeholder: "in Mbps"
          }
        ]
      },
      {
        title: "search.duration",
        type: "dropdown",
        children: [
          {
            title: "search.titles.from",
            storeKey: "duration.from",
            type: "range",
            placeholder: "Seconds"
          },
          {
            title: "search.titles.to",
            storeKey: "duration.to",
            type: "range",
            placeholder: "Seconds"
          }
        ]
      },
      {
        title: "search.width",
        type: "dropdown",
        children: [
          {
            title: "search.titles.from",
            storeKey: "width.from",
            type: "range",
            placeholder: "Pixels"
          },
          {
            title: "search.titles.to",
            storeKey: "width.to",
            type: "range",
            placeholder: "Pixels"

          }
        ]
      },
      {
        title: "search.height",
        type: "dropdown",
        children: [
          {
            title: "search.titles.from",
            storeKey: "height.from",
            type: "range",
            placeholder: "Pixels"

          },
          {
            title: "search.titles.to",
            storeKey: "height.to",
            type: "range",
            placeholder: "Pixels"
          }
        ]
      }
    ];
  }
}, {
  immediate: true
});


</script>

<style lang="postcss" scoped>

</style>
