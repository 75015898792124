<script setup lang="ts">

</script>

<template>
<div>
  <p>LINKS TAB</p>
</div>
</template>

<style scoped lang="postcss">

</style>