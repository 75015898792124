<template>
  <div class="w-full" ref="element" id="element">
    <search-time-header
      :active-section="activeSection"
      :sections="sections"
      :time-stamps="timeStamps"
      @updatePage="updateSection($event)"
    />
    <!--  Text  -->
    <p class="transcript"
       :class="'hide'"
       v-html="text">
    </p>
  </div>
</template>
<script setup lang="ts">
// SdCard Search Transcript Component
import { computed, inject, ref, watchEffect } from 'vue';

// import {Transcript} from "@Core/store/search/state";
import SearchTimeHeader from "@/components/library/search/SearchTimeHeader.vue";

type Props = {
  transcriptions: {
    words: {
      word: string,
      startTime: number
    }[]
  }[]
};

// const { $moment } = useNuxtApp();
const emitter = inject('emitter')
const props = defineProps<Props>();

const element = ref(null);
const getWidth = ref(0);
const activeSection = ref(0);

const splitText = computed(() => {
  const maxChars = 1000; // update no word limit from api;
  const group = props.transcriptions.map(matches => {
    return matches.words.reduce((acc, cur, index) => {
      const findIndex = acc.findIndex(e => e.words && ((e.words.length + cur.word.length + 1) < maxChars));
      if (findIndex >= 0) {
        acc[findIndex].words += " " + cur.word;
        if (cur.word.includes("<em>")) {
          acc[findIndex].time = [...acc[findIndex].time, cur.startTime];
        }
      } else {
        acc.push({
          words: cur.word,
          time: [cur.startTime]
        });
      }
      return acc;
    }, []);
  }).flat(2);
  return group.filter(checkTime => checkTime.time.length > 0);
});

const text = computed(() => {
  return splitText.value[activeSection.value].words;
});

const sections = computed(() => {
  return splitText.value.length;
});
const timeStamps = computed(() => {
  if (splitText.value[activeSection.value].time[0]) {
   return splitText.value[activeSection.value].time[0]
  } else {
    return "";
  }
});

function updateSection(val) {
    activeSection.value = val;
    const curTime = splitText.value[activeSection.value].time[0];
    emitter.emit('player-jump-to-seconds', curTime)
}
watchEffect(() => {
  if (element.value) {
    getWidth.value = element.value.offsetWidth;
  }
});

</script>
<style>
.transcript > em {
  @apply text-black bg-success-200;
}
</style>
<style scoped>
.transcript {
  @apply w-full text-black text-sm not-italic font-normal;
}

.hide {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 68px;
}
</style>
