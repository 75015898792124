<template>
  <nav class="nav">
    <ul class="nav__list">
      <li class="nav__item">
        <router-link :class="{'router--active': $route.path === '/', nav__link: true}" to="/">
          {{ $t("menuNavigation.home") }}
        </router-link>
      </li>
<!--      <li class="nav__item">-->
<!--        <a href="https://streamdiver.com/" target="_blank">-->
<!--          {{ $t("menuNavigation.website") }}-->
<!--        </a>-->
<!--      </li>-->
      <li class="nav__item" v-if="!isLoggedIn">
        <a href="/center/login">
          {{ $t("menuNavigation.login") }}
        </a>
      </li>
      <template v-else>
        <li class="nav__item">
          <a href="/center/media">
            {{ $t("menuNavigation.center") }}
          </a>
        </li>
        <li class="nav__item">
          <base-button class='logout-btn' @click="handleLogout">
            {{ $t("menuNavigation.logout") }}
          </base-button>
        </li>
      </template>
      <li class="body-2">
        <base-selector :options="languages" :default="publicPortalLanguage" :tabindex="0"
                       @get:selection="changeLanguage" />
      </li>
    </ul>
  </nav>
</template>

<script setup>
import BaseSelector from "@/components/library/dropdowns/BaseSelector.vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useConfigStore } from "@/stores/useConfig";
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import Cookies from "js-cookie";

const open = ref(false);
const activeOption = ref(null);
const { availableLocales, locale } = useI18n();
const { setPortalLanguage, publicPortalLanguage } = useConfigStore();
let centerToken = ref(null);

const isLoggedIn = computed(() => {
  // const authCookie = document.cookie.split(";").find(row => row.trim().startsWith("auth._token.local"));
  const authCookie = Cookies.get("auth.token");
  if (authCookie && authCookie != "false") {
    // const [key, token] = authCookie.split("=");
    centerToken.value = authCookie;
  }

  return !!centerToken.value;
});

const languages = computed(() => {
  return availableLocales.map((langShortcode) => {
    return {
      value: langShortcode, translation: langShortcode
    };
  });
});

function openMenu() {
  open.value = !open.value;
}

function closeMenu() {
  open.value = false;
}

function changeLanguage(language) {
  locale.value = language.value;
  setPortalLanguage(language.value);
  // console.log(language);
}

function handleLogout() {
  Cookies.remove("auth.token");
  Cookies.remove("auth.refresh-token");
  localStorage.removeItem("auth._token.local");
  localStorage.removeItem("auth._refresh_token.local");
  // reruns
  centerToken.value = null;
}

</script>

<style lang="postcss" scoped>

/*
#header {
    @apply md:h-16 md:flex md:flex-row md:w-full md:justify-between md:p-2 sticky top-0 z-50 shadow;
    background-color: var(--headerColor);
    /* color: var(--headerFontColor); */
/*
    font-family: 'headerFont';
}
*/
/*
#header img {
    @apply h-full px-2;
}

.header-left {
    @apply md:w-auto md:m-0 md:p-0 md:h-auto flex flex-row flex-grow p-2 h-16;
}

#nav {
    @apply md:flex md:flex-row md:items-center md:h-auto md:static flex flex-col w-full shrink justify-end overflow-hidden z-50;
    background-color: var(--headerColor);
    color: var(--headerFontColor);
}

#open-button {
    @apply md:hidden cursor-pointer px-2;
}

.menu-btn {
    @apply bg-white h-[2px] mt-2 mb-2 block w-8 relative;
}

.nav-btn {
    @apply m-4 px-6 md:m-0 font-thin;
}

.nav-btn a {
    @apply font-normal;
}

.mobile {
    @apply md:hidden;
}
*/
/* NEW */

.nav {

  &__list {
    @apply flex gap-4
  }

  &__item {
    @apply text-[var(--headerFontColor)] transition-all hover:text-white;
  }

  &__link {
    @apply p-4 rounded-lg;

  }
  .logout-btn{
    @apply text-xl lg:text-base;
  }
  :deep(.container) {
    @apply text-base text-inherit;
  }

  :deep(.selector-container) {
    @apply text-[var(--headerFontColor)] transition-all hover:text-white focus-within:text-white focus:text-white
  }
}
</style>