<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/library/buttons/BaseButton.vue";

export default defineComponent({
  components: { BaseButton }
});
</script>

<script setup lang="ts">
import BasePanel from "@/components/library/wrappers/BasePanel.vue";

interface TabComponent {
  name: string,
  attrs: {
    icon: {
      type: string;
      name: string;
    },
    btnType?: "button" | "submit";
    tooltip?: "string";
    usePing?: boolean;
  },
  events: {},
  class: "";
}


export interface Tab {
  name: string;
  component: string;
  setup: {
    component: TabComponent;
    events: {},
    class: string;
  };
}

interface Props {
  tabs?: Tab[];
  activeComponent?: string | {};
  layout?: "vertical" | "horizontal";
  useDefaultTabStyle?: boolean;
}

withDefaults(defineProps<Props>(), {
  layout: "vertical",
  useDefaultTabStyle: true
});

type SetupTab = (tab: Tab) => TabComponent;

function setupTab(tab: Tab): SetupTab {
  if (tab.setup.componentName) {
    return tab.setup; // Defaults to base-button
  } else {
    return {
      componentName: "base-button",
      attrs: {
        icon: {
          type: tab.setup.icon?.type ? tab.setup.icon.type : "fal",
          name: tab.setup.icon?.name
        },
        btnType: tab.setup.btnType ? tab.setup.btnType : "button",
        tooltip: tab.setup.tooltip,
        usePing: tab.setup.shouldNotify,
        disabled: tab.setup.attrs?.disabled
      },
      events: {},
      class: ""
    };
  }
}
</script>

<template>
  <div>
    <transition-group
      v-if='tabs?.length > 1'
      tag="ul" name="fade"
                      class='pt-7'
                      :class="['flex', 'tabs-container', {vertical:'flex-col', 'horizontal':'flex-row'}[layout]]">
      <li class="tab"
          :class="{'tab--disabled': setupTab(tab).attrs.disabled,'tab--default': useDefaultTabStyle, active: tab.component === activeComponent.component}"
          v-for="(tab, i) in tabs" :key="tab.component + i"
          @click="()=> {setupTab(tab).attrs.disabled? '': $emit('change:tab', tab) }">
        <component :is="setupTab(tab).componentName" v-bind="setupTab(tab).attrs"
                   v-on="setupTab(tab).events" :class="[setupTab(tab).class]" />
      </li>
    </transition-group>
    <base-panel>
      <header class="header " v-if="activeComponent.header">
        <slot name="header">
          <h6>{{ activeComponent.header.heading }}</h6>
          <ul class="ml-auto flex ga-2" v-if="activeComponent?.header?.actions"
              v-for="(action,index) in activeComponent.header.actions" :key="index + action">
            <base-button :icon="action.icon" @click="$emit(action.name, action.value)" />
            <!--            <li v-for="action in activeComponent.header.actions">{{ action }}</li>-->
          </ul>
        </slot>
      </header>
      <section class="tab-content-container">
        <slot />
      </section>
    </base-panel>
  </div>
</template>

<style scoped lang="postcss">
header {
  @apply flex p-4 text-neutral-800;
}
.simple-tabs >>> .panel {
  @apply lg:!h-fit;
}
.header{
  @apply flex bg-neutral-200 rounded-t-md mb-4;
}
.tab {
  @apply transition-all cursor-pointer;

  &--default {
    @apply text-2xl flex justify-center items-center md:w-14 h-14 bg-dark-50 hover:bg-dark-200 text-light-950  rounded-r-lg;

    &.active {
      @apply bg-primary-800 text-light-100;
    }
  }

  &--disabled {
    @apply cursor-not-allowed hover:bg-dark-50;
  }
}

</style>