<template>
  <div class="section"
       v-if="type === 'btn'"
  >
    <base-button
      btn-type="text"
      class="btn-only"
      @click="setStoreState"
    >
      {{ $t(title) }}
    </base-button>
  </div>
  <div class="section cursor-pointer" v-else-if="type === 'dropdown' && children && children.length > 0">
    <div class="dropdown-header" @click="toggleDropdown = !toggleDropdown">
      <!--   Icon option     -->
      <p class="font-medium text-neutral-800  text-sm">
        <!--    Dropdown Title      -->
        {{ $t(title) }}
      </p>
      <base-button
        btn-type="icon"
        :icon="{name:toggleDropdown ? 'chevron-down' : 'chevron-right', type: 'far'}"
        :disabled="false"
        class="dropdown-btn"

      />
    </div>
    <transition name="slide-down">
      <ul v-show="toggleDropdown">
        <search-filter-option
          v-for="child in children"
          :key="child.storeKey"
          v-bind="child"
        />
      </ul>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import SearchFilterOption from "@/components/library/search/SearchFilterOption.vue";
import { useSearchStore } from "@/stores/useSearch";
import type { OptionsSetting } from "@/components/library/search/types/options";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

const searchStore = useSearchStore();
const { text } = storeToRefs(searchStore);
const { setVal, search } = searchStore;
const route = useRoute();

type Props = {
  type: "toggle" | "btn" | "dropdown", // if it has a value what type does it need
  title: string,
  storeKey?: string,
  children?: { // if it has childrens show dropdown icons
    title: string,
    storeKey: string,
    type: "toggle" | "date" | "slide" | "select"
  }[]
}

const props = defineProps<Props>();

const toggleDropdown = ref(false);

async function setStoreState() {
  const savedSearchTerm = text.value;
  searchStore.$reset();
  searchStore.$patch((state) => {
    state.text = savedSearchTerm;
  });

  // Search again without filters
  if (route.name === "ChannelsView") {
    setVal({ key: "type", value: "channel" });
  } else if (route.name === "ChannelView") {
    setVal({ key: "channelId", value: route.params.channelId });
    setVal({ key: "type", value: "mediaasset" });
  } else {
    setVal({ key: "type", value: "mediaasset" });
  }
  try {
    await search(savedSearchTerm);
  } catch (e) {
    console.error(e);
  }
}

</script>

<style scoped>
.section {
  @apply py-4 border-b border-neutral-300;
}

.section:first-child {
  @apply pb-4 pt-0 border-b border-neutral-300;
}

.btn-only {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  @apply cursor-pointer text-primary-500;
}

.dropdown-btn {
  @apply w-6 h-6 text-lg text-neutral-500 hover:text-secondary-500 active:text-secondary-500 focus:text-secondary-500 transition-colors duration-300;
}

.dropdown-header {
  @apply flex justify-between items-center pb-1;
}

</style>
