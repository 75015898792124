import { defineStore } from "pinia";
import { useFetchFullURL } from "@/utils/useService";
import api from "@/services/tenantService";

interface DesignList {
  boxColor: string | null,
  boxFontColor: string | null,
  customCss: string | null,
  facebookShareImageUrl: string | null,
  faviconUrl: string | null,
  footerColor: string | null,
  footerFontColor: string | null,
  headerColor: string | null,
  headerFontColor: string | null,
  headerfontUrl: string | null,
  logoUrl: string | null,
  mainColor: string | null,
  mainImageUrl: string | null,
  pageBackgroundColor: string | null,
  twitterShareImageUrl: string | null,
  bodyfontUrl: string | null;
}

const defaultDesign = {
  boxColor: "#fff",
  boxFontColor: "#121D29",
  customCss: null,
  facebookShareImageUrl: null,
  faviconUrl: null,
  footerColor: null,
  footerFontColor: null,
  headerColor: null,
  headerFontColor: "rgba(255,255,255,0.75)",
  headerfontUrl: null,
  logoUrl: null,
  mainColor: null,
  mainImageUrl: null,
  pageBackgroundColor: "rgba(18,29,41,0.95)",
  twitterShareImageUrl: null,
  bodyfontUrl: null
};


export const useStyleStore = defineStore("styleStore", {
  // ** STORE ** //
  state: () => ({
    designList: {} as DesignList,
    tenantDesignList: defaultDesign as DesignList,
    headerFontUrl: null as string | null,
    bodyFontUrl: null as string | null
  }),

  // ** ACTIONS ** //
  actions: {
    setList(styleList) {
      if (!styleList) return;
      this.designList = styleList;
      this.setRootsVariables();
    },

    setTenantDesign(designList) {
      Object.assign(this.tenantDesignList, designList);
    },

    async displayProtectedImage(el, url) {
      if (url == "null") {
        return false;
      }
      return await useFetchFullURL(url)
        .then((r) => {
          if (!r.ok) {
          } else {
            return r.blob();
          }
        })
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);

          if (el?.src) {
            el.src = objectUrl;
            el.onload = () => URL.revokeObjectURL(objectUrl);
          } else {
            el.href = objectUrl;
            el.onload = () => URL.revokeObjectURL(objectUrl);
          }
        })
        .catch((e) => {
          console.error(e);
          throw e;
        });
    },
    async displayProtectedFont(url, type) {
      let that = this;
      return useFetchFullURL(url)
        .then((r) => {
          if (!r.ok) {
            console.log(r);
          } else {
            return r.blob();
          }
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = function() {
            that[type + "FontUrl"] = this.result;
          };
          console.log(blob)
          const newURL = reader.readAsDataURL(blob);
          console.log(this.result, newURL )
          return this.result;
        })
        .catch((e) => {
          throw e;
        });
    },
    setRootsVariables(design = "channel") {
      let stateVariable;
      if (design === "channel") {
        stateVariable = this.designList;
      }
      if (design === "tenant") {
        stateVariable = this.tenantDesignList;
      }
      // Validation
      if (!["channel", "tenant"].includes(design)) {
        throw new Error("Please add source for design, valid are channel, tenant");
      }
      const style = document.createElement("style");
      style.textContent = `
    :root {
      --mainColor: ${stateVariable.mainColor}!important;
      --pageBackgroundColor: ${stateVariable.pageBackgroundColor}!important;
      --headerColor: ${stateVariable.headerColor}!important;
      --footerColor: ${stateVariable.footerColor}!important;
      --headerFontColor: ${stateVariable.headerFontColor}!important;
      --footerFontColor: ${stateVariable.footerFontColor}!important;

      --mainImageUrl: ${stateVariable.mainImageUrl}!important;
      --logoUrl: ${stateVariable.logoUrl}!important;
      --faviconUrl: ${stateVariable.faviconUrl}!important;
      --facebookShareImageUrl: ${stateVariable.facebookShareImageUrl}!important;
      --twitterShareImageUrl: ${stateVariable.twitterShareImageUrl}!important;

      --boxColor: ${stateVariable.boxColor};
      --boxFontColor: ${stateVariable.boxFontColor};
    }
    ${stateVariable.customCss}
    `;
      // if no data --> default values!
      document.head.append(style);
    }
  }
});
