import { capitalizeFirstLetter, replaceWord } from "@/utils/functions/useString";

export default function remodelResult(data, type) {
  // console.log('data',data)
  // console.log('type', type)
  if (type === "media") {
    return data.map(media => {
      // create tabs

      const infoTab = {
        title: "Info", // replace with i18n keyword
        children: [ // children components that belong together in a row
          {
            class: "flex items-center gap-2 justify-between",
            tags: [{
              type: "text",
              class: "text-sm text-magenta-500",
              value: capitalizeFirstLetter(media.type),
              component: "base-asset-type" // if none its just text ?
            },
              {
                type: "text",
                value: 0,
                class: "flex-grow", // custom styling
                component: "base-views" // if none its just text ?
              },
              {
                type: "text",
                value: media.audit.createdAtUtc,
                component: "base-date" // if none its just text ?
              }
            ]
          },
          {
            tags: [{
              type: "array",
              value: replaceWord(media.tags, media.tagsHighlights),
              component: "base-tag"
            }]
          },
          {
            tags: [{
              type: "text",
              value: media.descriptionHighlights.length > 0 ? replaceWord(media.description.split(" "), media.descriptionHighlights.map((hit) => hit.split(" ")).flat(2)).join(" ") : media.description,
              component: "p"
            }]
          }
        ]
      };
      const transcriptionTab = media.metadata.transcript.length > 0 && {
        title: "Transcription", // replace with i18n keyword
        children: [
          {
            tags: [{
              type: "text",
              value: media.metadata.transcript,
              component: "search-transcript"
            }]
          }
        ]
      };
      const indexingContent = Object.keys(media.metadata).map(keyName => {
        if (keyName !== "transcript" && keyName !== "videoText" && keyName !== "documentContent") {
          if (media.metadata[keyName].length > 0) {
            return {
              title: capitalizeFirstLetter(keyName),
              tags: media.metadata[keyName].map(e => {
                return {
                  type: "index",
                  value: { ...e, videoDuration: media.duration },
                  component: "metadata-timeline"
                };
              })
            };
          }
        }
      }).filter(e => e)
      const indexingTab = indexingContent.length > 0 && {
        title: "Indexing",
        children: indexingContent
      };
      const ocrContent = Object.keys(media.metadata).map(keyName => {
        if (keyName === "videoText") {
          if (media.metadata[keyName].length > 0) {
            return {
              title: capitalizeFirstLetter(keyName),
              tags: media.metadata[keyName].map(e => {
                return {
                  type: "ocr",
                  value: { ...e, videoDuration: media.duration },
                  component: "metadata-timeline"
                };
              })
            };
          }
        }
      }).filter(e => e);
      const ocrTab = ocrContent.length > 0 && {
        title: "Video OCR",
        children: ocrContent
      };
      const documentContent = Object.keys(media.metadata).map(keyName => {
        if (keyName === "documentContent") {
          if (media.metadata[keyName].length > 0) {
            return {
              title: capitalizeFirstLetter(keyName),
              tags: media.metadata[keyName].map(e => {
                return {
                  type: "pdftab",
                  value: { ...e },
                  component: "search-pdf"
                };
              })
            };
          }
        }
      }).filter(e => e);
      const documentTab = documentContent?.length && {
        title: 'PDF',
        children: documentContent,
      };
      const tabs = [infoTab, transcriptionTab, indexingTab, ocrTab, documentTab].filter(e => e);
      return {
        ...media,
        tabs,
        name: media.nameHighlights.length > 0 ? media.nameHighlights.join(" ") : media.name
      };
    });
  }
  if (type === "channel") {
    return data.map(channel => {
      const infoTab = {
        title: "Info", // replace with i18n keyword
        children: [ // children components that belong together in a row
          {
            tags: [{
              type: "text",
              value: channel.descriptionHighlights.length > 0 ? replaceWord(channel.description.split(" "), channel.descriptionHighlights.map((hit) => hit.split(" ")).flat(2)).join(" ") : channel.description,
              component: "p"
            }]
          }
        ]
      };
      return {
        ...channel,
        name: channel.nameHighlights.length > 0 ? channel.nameHighlights.join(" ") : channel.name,
        tabs: [infoTab]
      };
    });
  }
}
