<template>
  <li class="list-item" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
    <div class="col col__header">
      <router-link :to="mediaRoute">
        <base-title class="font-semibold" tag="h6">{{ name }}</base-title>
      </router-link>
      <slot name="header"></slot>
    </div>
    <ul class="col col__thumbnail-list">
      <li class="thumbnail-wrapper" v-for="m in media" :key="m.id">
        <!--        MISSING PREVIEW URL FROM API-->
        <!--        <transition name="fade" mode="out-in">-->
        <!--          <component class="portal-card__header" :is="mediaViewComponent" :route="getMediaRoute(m.id)"-->
        <!--                     :thumbnail="thumbnail"-->
        <!--                     :media-type="m.type" type="thumbnail"-->
        <!--                     :rendition="{source: m.preview.url, mimeType:'application/x-mpegurl'}"-->
        <!--                     :preview="m.preview"-->
        <!--                     :alt="$t('altText.thumbnail', {type: type, name: name, description: description})"-->
        <!--                     :id="m.id" />-->
        <!--        </transition>-->
        <base-image :route="getMediaRoute(m.id)" :mediaType="m.type" :id="m.id" :channel-id="id"
                    :thumbnail="{url:m.thumbnailUrl}"
                    :alt="$t('altText.thumbnail', {type: m.type, name: m.name, description: m.description})" />
      </li>
    </ul>
    <div class="col col__details">
      <base-views :count="mediaCount" />
      <base-date :date="audit?.createdAtUtc" />
    </div>
    <base-scroller class="col col__tags" :is-parent-hovered="isItemHovered">
      <base-tag color="blackish" v-for="(tag, index) in tags" :key="tag + index">{{ tag }}</base-tag>
    </base-scroller>
    <div class="col col__description">
      <base-truncator v-if="description" :text="description" :insert-html="true" :expandedLines="2"></base-truncator>
    </div>
  </li>
</template>

<script setup lang="ts">
import BaseImage from "@/components/library/ui/BaseImage.vue";
import BaseViews from "@/components/library/counter/BaseViews.vue";
import BaseDate from "@/components/library/counter/BaseDate.vue";

import { computed, inject, ref } from "vue";
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import BaseTruncator from "@/components/library/typography/BaseTruncator.vue";
import { paths } from "@/settings/settings";
import { useRoute } from "vue-router";
import BaseTag from "@/components/library/ui/BaseTag.vue";
import BaseScroller from "@/components/library/scrollContainers/BaseScroller.vue";
import BasePreview from "@/components/library/player/BasePreview.vue";

// import type { publicChannel } from "@/types/publicChannel";

interface PublicChannel {
  audit: { createdAtUtc: string, createdBy: object, updatedAtUtc: string, updatedBy: object },
  description: string,
  featuredAtUtc: string | null,
  id: string;
  thumbnail: Thumbnail;
  manualSort: number;
  mediaCount: number;
  media: Media[]
  tags: string[]
  name: string;
  type: "group";
  visibility: "public";
}

interface Media {
  id: string,
  name: string,
  thumbnailUrl: string,
  description?: string,
  preview?: {
    url: string
  }
  type: string,
}

interface Thumbnail {
  height: number,
  url: string,
  width: number
}

const props = defineProps<PublicChannel>();
const isItemHovered = ref(false);

const route = useRoute();


const mode = inject["mode"];

const mediaRoute = computed(() => {
  // On channel detail page we can take advantage of route
  if (route.params.channelId) {
    return paths.CHANNELS_ROUTE + route.params.channelId + paths.MEDIA_ROUTE + props.id;
  } else {
    // On channels page we get the channel id as a prop
    return paths.CHANNELS_ROUTE + props.id;
  }
});

// const mediaViewComponent = computed(() => {
//   return isItemHovered.value && (props.type === "video" || props.type === "audio") ? BasePreview : BaseImage;
// });

function getMediaRoute(mediaId) {
  return paths.CHANNELS_ROUTE + props.id + paths.MEDIA_ROUTE + mediaId;
}

function handleMouseEnter() {
  isItemHovered.value = true;
}

function handleMouseLeave() {
  isItemHovered.value = false;
}

</script>


<style lang="postcss" scoped>
.list-item {
  @apply px-4 pt-4 transition-all hover:bg-dark-100;

  > *:not(:last-child) {
    @apply mb-2
  }

  &:not(:last-child)::after {
    content: "";
    @apply mt-4 block h-1 w-[100%] border-b border-b-neutral-300
  }

  .col {
    @apply text-dark-800;

    &__header {
      @apply flex gap-2 items-center;

      :deep(.title) {
        @apply text-xl;
      }
    }

    &__thumbnail-list {
      @apply flex gap-2 overflow-x-scroll;

      &::-webkit-scrollbar {
        @apply hidden
      }
    }

    &__details {
      @apply flex justify-between;
    }

    &__tags {
      @apply flex gap-2 overflow-x-scroll;

      &::-webkit-scrollbar {
        @apply hidden
      }
    }

    &__description {
    }
  }
}

.thumbnail-wrapper {
  @apply basis-[10rem] flex-shrink-0;

}
</style>