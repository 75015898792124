<template>
  <div class="selector-container" ref="selectorContainer" :tabindex="tabindex" @blur="isOpen = false">
    <div
      class="selected container"
      :class="{ open: isOpen }"
      @click="isOpen = !isOpen"
    >
      <slot>
        <font-awesome-icon class="selector-container__selected-item" v-if="selected.icon"
                           :icon="[selected.icon?.type, selected.icon?.name, ]" />
        <component :is="selectedTag" class="container__selected-value">{{ selected.translation }} <span
          v-if="selected.count || selected.count === 0"> ({{ selected.count }}) </span></component>

        <font-awesome-icon :class="{'chevron-down': true ,'chevron-down--open': isOpen}"
                           :icon="mainIcon"></font-awesome-icon>
      </slot>
    </div>
    <transition name="fade-down">
      <div class="selection-list" v-if="isOpen">
        <div
          class="selection-list__item"
          :class="{'item-active': option.value === selected.value}"
          v-for="(option, i) of options"
          :key="i"
          @click="
            selected = option;
            isOpen = false;
            $emit('get:selection', option);
          "
        >
          <div :class="['radio', {'radio--active': option.value === selected.value}]"></div>
          <div>
            <font-awesome-icon v-if="option.icon" class="mr-2 selection-list__item-icon"
                               :class="{'text-white': option.value === selected.value}"
                               :icon="[option.icon.type, option.icon.name]" />
            <span>{{ option.translation }}</span>
          </div>
          <span v-if="option.count || option.count === 0" class="ml-auto">{{ option.count }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect } from "vue";

interface Props {
  options: Option[],
  tabindex: number,
  default?: Option,
  mainIcon?: String,
  selectedTag?: String
}

interface Option {
  value: string,
  translation: string,
  count?: number,
  icon?: Icon,
}

interface Icon {
  name: string,
  type: "fas" | "fal" | "far",
}

const selectorContainer = ref<HTMLElement>();

const props = withDefaults(defineProps<Props>(), {
  tabindex: 0,
  mainIcon: "fa-chevron-down fa-regular",
  selectedTag: "span"
});

defineEmits<{
  (event: "get:selection", value: object): void
}>();

watch(props, (value) => {
  // ref does not pick up changes on the default value
  selected.value = value.default;
});

const selected = ref<Option | null>(props.default ? props.default : props.options.length > 0 ? props.options[0] : null);
const isOpen = ref(false);

watch(isOpen, (isOpen) => {
  if (!isOpen) {
    selectorContainer.value?.blur();
  }
});

</script>

<style lang="postcss" scoped>
.selector-container {
  @apply relative text-left outline-0 flex items-center;

  .selected {
    @apply text-neutral-800 cursor-pointer select-none;
  }
}

.chevron-down {
  @apply transition-transform;

  &--open {
    @apply rotate-180
  }
}

.selection-list {
  @apply absolute bottom-0 z-20 translate-y-full left-1/2 -translate-x-1/2 text-dark-800 w-max z-20 shadow-lg bg-light-100 rounded-md overflow-hidden;

  &__item {
    @apply p-2 flex items-center gap-2 cursor-pointer hover:bg-primary-100
  }
}

/* Sets the active styles for the dropdown menu item */
.item-active {
  @apply bg-primary-600 text-light-100;
}

.radio {
  @apply w-3 h-3 rounded-full bg-white border border-gray-600 relative;

  &--active::before {
    content: '';
    @apply w-2 h-2 rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-blue-600
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

</style>
