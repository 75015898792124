import { defineStore, acceptHMRUpdate } from "pinia";
import { reactive } from "vue";

export const useAppStore = defineStore("appStore", () => {
  const state = reactive({
    isExtendedSearchOpen: false,
    isLoop: false,
    isStandAlone: false,    // Means the view is single no routing between views
    autoPlay: false,
    hideControls: false,
    mediaCover: false,
    captionOn: false,
    pdfPage: 0,
  });

  function updateState({ key, value }) {
    state[key] = value;
  }


  return {
    state, updateState
  };

});

// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
