<script setup lang="ts">

</script>

<template>
  <div>
    <p>SEARCH TAB</p>
  </div>
</template>

<style scoped lang="postcss">

</style>