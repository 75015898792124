<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

import BaseButton from '@/components/library/buttons/BaseButton.vue'

const activePage = ref(0)
const activeSectionInPage = ref(0)

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  pdfContent: {
    type: Array
  }
})

const pageContentInSection = computed(() => {
  return props.pdfContent && props.pdfContent[activePage.value].value
})
</script>

<template>
  <section v-if="pdfContent">
    <header class="header">
      <div class="flex items-center gap-2">
        <base-button
          :icon="{ name: 'chevron-left', type: 'far' }"
          btn-type="icon"
          class="icon"
          :disabled="activePage === 0"
          @click="activePage = activePage - 1"
        />
        <router-link
          prefetch
          :to="`/media/${id}?pdfPage=${pageContentInSection?.pageNumber}`"
          class="text-sm hover:text-secondary-500 underline"
        >
          Page: {{ pageContentInSection?.pageNumber }}
        </router-link>
        <base-button
          :icon="{ name: 'chevron-right', type: 'far' }"
          btn-type="icon"
          class="icon"
          :disabled="activePage === pdfContent.length - 1"
          @click="activePage = activePage + 1"
        />
      </div>
      <div class="flex items-center gap-1">
        <p class="text-xs">
          {{ activeSectionInPage + 1 }}/{{ pageContentInSection?.paragraphs?.length }}
        </p>
        <base-button
          :icon="{ name: 'chevron-up', type: 'far' }"
          btn-type="icon"
          class="icon"
          :disabled="activeSectionInPage === 0"
          @click="activeSectionInPage = activeSectionInPage - 1"
        />
        <base-button
          :icon="{ name: 'chevron-down', type: 'far' }"
          btn-type="icon"
          class="icon"
          :disabled="activeSectionInPage === pageContentInSection?.paragraphs?.length - 1"
          @click="activeSectionInPage = activeSectionInPage + 1"
        />
      </div>
    </header>
    <div class="mt-2">
      <ul>
        <li
          v-html="
            pageContentInSection?.paragraphs &&
            pageContentInSection?.paragraphs[activeSectionInPage]
          "
        />
      </ul>
    </div>
  </section>
</template>

<style scoped>
.icon {
  @apply h-6 w-6 text-neutral-500 text-base bg-white hover:bg-neutral-500 hover:text-white rounded-full flex justify-center items-center;
}

.icon :deep(button:disabled, button[disabled]) {
  @apply text-neutral-300 bg-white;
}

.icon >>> button:disabled:hover,
button[disabled]:hover {
  @apply bg-gray-300;
}

.header {
  @apply flex justify-between items-center text-neutral-500;
}
</style>
