export function capitalizeFirstLetter(word) {
  const result = word[0].toUpperCase() + word.substring(1);
  return result;
}

// used in search to replace words that have <em> tags
export function replaceWord(current: string[], newArr: string[]) {
  return current.map(word => {
    const match = newArr.find(e => {
      const newTag = e.replaceAll("<em>", "").replaceAll("</em>", "");
      if (newTag === word) {
        return e;
      }
    });
    if (match) {
      return match;
    } else {
      return word;
    }
  });
}

export function createQueryString(obj: { [key: string]: string | [] }) {
  if (!obj) return "";
  const queryString = Object.entries(obj)
    .filter(([key, value]) => value)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (!value.length) return;
        // @ts-ignore
        return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryString ? `?${queryString}` : "";
}

export function capitalizeLetter(text: string, { index } = { index : 0 }) {
  return text.slice(0, index) + text[index].toUpperCase() + text.slice(index + 1);
}