<template>
  <component :is="tag" class="panel" :class="!appState.isStandAlone && ' rounded-md limit-height'">
    <slot />
  </component>
  <!--  <div v-if="$slots.sidepanel" class="side-panel">-->
  <!--    <slot name="sidepanel"></slot>-->
  <!--  </div>-->
</template>

<script lang="ts" setup>
import { useAppStore } from '@/stores/useApp';

withDefaults(defineProps<{ tag?: "section" | "div", mediaType? }>(), {
  tag: "div",
  mediaType: ""
});

const { state: appState } = useAppStore();

</script>

<style lang="postcss" scoped>
.panel {
  @apply h-max w-full shadow-sm bg-light-100;
}

.panel.player-wrapper.limit-height {
  @apply h-fit max-h-[75vh];
}
</style>