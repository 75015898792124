<template>
  <transition-group tag="div" class="container" name="fade" ref="container">
    <base-button
      v-if="!reachedScrollBeginning"
      key="chevron--left"
      class="chevron chevron--left"
      :aria-label="$t('altText.chevronLeft')"
      :class="{'chevron--hovered': isParentHovered}"
      :icon="{type: 'far', name: 'chevron-left'}"
      @click.native="scrollForward"
    ></base-button>
    <ul key="list" class="tags-container" @scroll="handleScroll" ref="tagsContainer">
      <slot></slot>
    </ul>
    <base-button
      v-if="!reachedScrollEnd && isOverflow"
      key="chevron--right"
      class="chevron chevron--right"
      :class="{'chevron--hovered': isParentHovered}"
      :aria-label="$t('altText.chevronRight')"
      :icon="{type: 'far', name: 'chevron-right'}"
      @click.native="scrollBack"
    ></base-button>
  </transition-group>
</template>

<script lang="ts" setup>
import { computed, ref, watch, onMounted } from "vue";

const props = defineProps({
  isParentHovered: {
    type: Boolean,
    default: false
  }
});

const tagsContainer = ref(null);
const isOverflow = ref(false);
const scrollPositionLeft = ref(0);
const reachedScrollEnd = ref(false);
const reachedScrollBeginning = ref(true);
const scrollLeftMax = ref(null);

const hovered = computed(() => {
  const scrollPosition = tagsContainer.value.scrollLeft;
  const maxScrollLeft = tagsContainer.value.scrollWidth - tagsContainer.value.clientWidth;
  reachedScrollEnd.value = (maxScrollLeft - 20) <= scrollPosition;
  return props.isParentHovered && (maxScrollLeft - 20) <= scrollPosition;
});

function scrollForward() {
  tagsContainer.value.scrollLeft -= 100;
}

function scrollBack() {
  tagsContainer.value.scrollLeft += 100;
}

function handleScroll(event) {
  scrollPositionLeft.value = event.target.scrollLeft;
}

watch(() => scrollPositionLeft.value, (scrollPosition) => {
  let maxScrollLeft = tagsContainer.value?.scrollWidth - tagsContainer.value?.clientWidth;
  reachedScrollEnd.value = (maxScrollLeft - 20) <= scrollPosition;
  reachedScrollBeginning.value = scrollPosition <= 0;
});

onMounted(() => {
  if (tagsContainer.value) {
    const isOverflown = ({ clientWidth, scrollWidth }) => {
      return scrollWidth > clientWidth;
    };
    new ResizeObserver(function() {
      isOverflow.value = tagsContainer.value?.scrollWidth > tagsContainer.value?.getBoundingClientRect().width + 15;
      const scrollPosition = tagsContainer.value?.scrollLeft;
      const maxScrollLeft = tagsContainer.value?.scrollWidth - tagsContainer.value?.clientWidth;
      reachedScrollEnd.value = (maxScrollLeft - 20) <= scrollPosition;
    }).observe(tagsContainer.value);
  }
});

</script>

<style lang="postcss" scoped>
.container {
  @apply flex gap-2;

  .tags-container {
    @apply flex gap-2 overflow-y-auto;
    scrollbar-width: none; /* Firefox */

    &__tag {
      @apply cursor-pointer
    }
  }

  .tags-container::-webkit-scrollbar {
    @apply hidden;
    /* Safari and Chrome */
  }

  .chevron {
    @apply z-20 relative text-neutral-500;


    &--left {
      &::after {
        content: '';
        @apply w-24 h-full z-10 bg-gradient-light  absolute top-0 left-4;
      }
    }

    &--right {
      @apply ml-auto;

      &::after {
        content: '';
        @apply w-24 h-full z-10 bg-gradient-right  absolute top-0 right-4;
      }
    }

    :deep(.icon) {
      @apply opacity-0 transition-opacity;
    }

    &--hovered :deep(.icon) {
      @apply opacity-100
    }
  }
}

</style>
