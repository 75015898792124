<template>
  <section>
    <base-portal class="portal">
      <template #header>
        <featured-channels class="mb-3" />
        <portal-header />
      </template>
      <template #default>
        <portal-body v-if="!publicChannels.isFetchingAll && !isSearchRequestActive" />
        <transition name="fade-absolute" mode="out-in">
          <ul v-if="publicChannels.isFetchingAll || isSearchRequestActive"
              :class="['layout--' + portalView.channels, 'layout']">
            <component v-for="n in 10" :key="n" :is="loadingComponent" />
          </ul>
        </transition>
      </template>
    </base-portal>
  </section>
</template>

<script setup lang="ts">
// ** COMPONENTS ** //
import PortalBody from "@/components/library/portal/PortalBody.vue";
import PortalHeader from "@/components/library/portal/PortalHeader.vue";
import CardSkeleton from "@/components/library/skeleton/views/CardSkeleton.vue";
import ListSkeleton from "@/components/library/skeleton/views/ListSkeleton.vue";
import FeaturedChannels from "@/components/views/channelsView/FeaturedChannels.vue";
import BasePortal from "@/components/library/layouts/BasePortal.vue";
// ** FUNCTIONS ** //
import { provide, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
// ** FUNCTIONS ** //
import { useChannelsStore } from "@/stores/useChannels";
import { useToast } from "vue-toastification";
import { useConfigStore } from "@/stores/useConfig";
import { useI18n } from "vue-i18n";
import { useSearchStore } from "@/stores/useSearch";

// ** COMPOSABLES ** //
const toast = useToast();

// ** STORES ** //
const { portalView, resetPageCount } = useConfigStore();
const { fetchAll, state: publicChannels, fetchAllFeatured } = useChannelsStore();
const searchStore = useSearchStore();

// ** STATE ** //
const { request } = storeToRefs(searchStore);

// ** Provide ** //
provide("data", computed(() => publicChannels.data));
provide("mode", "channels");
provide("isLoadingAll", computed(() => publicChannels.isFetchingAll));

const isSearchRequestActive = computed(() => {
  return request.value.loading;
});

const loadingComponent = computed(() => {
  if (portalView.channels === "grid") {
    return CardSkeleton;
  } else if (portalView.channels === "list") {
    return ListSkeleton;
  }
  // Add on here in case of different view
});

onMounted(async () => {
  try {
    resetPageCount("channels");

    await Promise.allSettled([fetchAll(), fetchAllFeatured()]);
    document.title = "Public Portal";
  } catch (e) {
    toast.error("Could not retrieve all public channels!");
  }
});
</script>

<style lang="postcss" scoped>
section {
  @apply px-3;
}

.layout {
  @apply bg-light-100 w-full h-full;

  &--grid {
    @apply content-start grid grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7  grid-flow-row gap-x-4 gap-y-5 items-start transition-all
  }

  &--list {
    @apply flex flex-col
  }
}
</style>