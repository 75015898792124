<template>
  <div
    :class="[
      'list-container',
      { 'list-container--normal': size === 'normal' },
      { 'list-container--small': size === 'small' },
    ]"
  >
    <div
      :class="[
        'line-skeleton',
        { 'line-skeleton--normal': size === 'normal' },
        { 'line-skeleton--small': size === 'small' },
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ListSkeleton',

  props: {
    size: {
      type: String,
      validator(value) {
        return ['small', 'normal'].includes(value);
      },
      default: 'normal',
    },
  },
};
</script>

<style scoped>
.line-skeleton {
  @apply flex-grow rounded  bg-[#ccc] dark:bg-neutral-800 bg-gradient-to-r from-neutral-300 dark:from-neutral-600 via-neutral-100 dark:via-neutral-400 to-neutral-300 dark:to-neutral-600;
  /* background-size: 600px; */
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  animation: shine-lines 2s infinite linear;
}
.line-skeleton--normal {
  @apply h-[2.5rem] bg-[length:13rem] dark:bg-[length:18rem];
  /* background-size: 600px; */
}
.line-skeleton--small {
  @apply h-[1rem] dark:bg-[length:18rem];
  /* background-size: 18rem; */
}

.list-container {
  @apply flex mx-auto items-center justify-end px-4 lg:px-2  py-3   border-b border-neutral-300  transition-all;
}

.list-container--normal {
  @apply w-[95%] h-24;
}

.list-container--small {
  @apply w-6 lg:w-[95%] h-auto;
}

/* ANIMATION */
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 500px;
  }
}
</style>
