import { acceptHMRUpdate, defineStore } from "pinia";
import { reactive } from "vue";

export const useMetaDataStore = defineStore("metaDataStore", () => {
  const state = reactive({
    colors: {
      "speakers": "magenta-500",
      "entities": "error",
      "objects": "purple-800",
      "keywords": "success-600",
      "scenes": "warning",
      "actions": "success-500",
      "videotext": "neutral-500"
    },
    presets: null,
    availableTranscriptionLanguages: []
  });


  return { state };
});

// Import HMR module for pinia
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useMetaDataStore, import.meta.hot));
}
