<template>
  <ul :style="`color: ${styleStore?.tenantDesignList.footerFontColor} !important;`">
    <li class="body-2" :class="{'router--active': $route.path === '/imprint'}" >
      <router-link class="w-full inline-block" to="/imprint">
        <button class="button-footer">{{ $t("imprint") }}</button>
      </router-link>
    </li>
    <li class="body-2" :class="{'router--active': $route.path === '/privacy'}">
      <router-link class="w-full inline-block" to="/privacy">
        <button class="button-footer">{{ $t("privacy") }}</button>
      </router-link>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { useStyleStore } from '@/stores/useStyleApp';

const styleStore = useStyleStore();

</script>

<style lang="postcss" scoped>
ul {
    @apply border-t pb-14 border-t-light-300;

    li {
      @apply text-base md:text-xs transition-all hover:bg-dark-300 rounded-md;
      .button-footer {
        @apply text-xl text-center lg:text-left lg:text-sm;
      }
    }
}


</style>