<script setup lang="ts">
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import { ref } from "vue";
import { useConfigStore } from "@/stores/useConfig";

const { toggleMobileMenu, appSettings } = useConfigStore();

function toggleOpen() {
  toggleMobileMenu();
}

</script>

<template>
  <base-button class="hamburger-container" :class="{open: appSettings.isMobileNavOpen}" @click="toggleOpen">
    <span></span>
    <span></span>
    <span></span>
  </base-button>
</template>

<style scoped lang="postcss">
.hamburger-container {
  @apply w-[2.5rem] h-[2rem] relative rotate-0 cursor-pointer z-50;
  transition: .5s ease-in-out;

  span {
    @apply absolute bg-white block w-full rounded-lg opacity-100 left-0 rotate-0;
    height: 5px;
    width: 100%;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    @apply top-0 origin-top-left;
  }

  span:nth-child(2) {
    @apply origin-center;
    top: 13px;
  }

  span:nth-child(3) {
    top: 26px;
    transform-origin: left bottom;
  }

  &.open span:nth-child(1) {
    @apply rotate-45;
  }

  &.open span:nth-child(2) {
    @apply w-0 opacity-0;
  }

  &.open span:nth-child(3) {
    @apply -rotate-45;
  }
}


</style>