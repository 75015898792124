<template>
  <button :type="type" :disabled="disabled" v-tippy="{content:tooltip, theme: 'light'}" :class="[design, 'btn']">
    <font-awesome-icon class="icon" :class="{'mr-2': $slots.default}" :icon="[icon.type, icon.name]"
                       v-if="icon.name !== ''" />
    <slot></slot>
    <template v-if="usePing.isActive">
      <transition name="fade">
      <span
          :class="[
          'ping-dot',
          usePing.dotColor ? usePing.dotColor : 'bg-ocean-500',
        ]"
      ></span>
      </transition>
      <transition name="fade">
      <span
          :class="[
          'ping',
          usePing.pingColor ? usePing.pingColor : 'bg-ocean-300',
        ]"
      ></span>
      </transition>
    </template>
  </button>
</template>

<script>
import { directive } from "vue-tippy";

export default {
  directives: {
    tippy: directive
  },

  props: {
    icon: {
      type: Object,
      default: () => ({
        type: "fa-solid",
        name: ""
      }),
      validator: (value) => Object.keys(value).some(key => ["type", "name"].includes(key))
    },
    type: {
      type: String,
      default: () => "submit"
    },
    design: {
      type: String,
      default: () => "primary",
      validator: (value) => ["primary", "secondary", "icon"].includes(value)
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      type: String,
      required: false
    },
    usePing: {
      type: Object,
      default: () => {
        return {
          isActive: false,
          dotColor: "sky-500",
          pingColor: "sky-300"
        };
      },
      validator: (value) => Object.keys(value).includes("isActive")
    }
  }

};
</script>

<style lang="postcss" scoped>

.btn {
  @apply relative inline-block disabled:opacity-50 disabled:cursor-not-allowed;
}

/* PING DOT CLASSES */

.ping-dot {
  @apply absolute top-0 -right-1 h-1 w-1 inline-flex rounded-full;
}

.ping {
  @apply animate-ping absolute inline-flex h-2 w-2 rounded-full opacity-75  -top-[.1rem] -right-[.35rem];
}

</style>
