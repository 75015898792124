<template>
  <component class="card" :is="tag">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: "div",
      validator: (tag) => ["div", "li"].includes(tag)
    }
  }
};
</script>

<style lang="postcss" scoped>
.card {
    @apply rounded-md lg:hover:shadow-md lg:hover:scale-105 transition-all delay-300;
}
</style>
