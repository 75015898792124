<template>
  <time class="date">
    <span class="mr-1">{{ showTime ? formattedDate + "," : formattedDate }}</span>
    <span class="time" v-if="showTime">{{ formattedTime }} </span>
  </time>
</template>

<script setup lang="ts">
import { useDateFormat } from "@vueuse/core";

const props = defineProps({
  date: {
    type: [Date, String],
    default: () => new Date()
  },
  showTime: {
    type: Boolean,
    default: false
  }
});

const formattedDate = useDateFormat(props.date, "DD.MM.YYYY");
const formattedTime = useDateFormat(props.date, "hh:mm");

</script>


<style scoped>
.date {
    @apply text-dark-500;
    span {
      @apply text-sm;
    }
}
</style>