<template>
  <transition name="fade-up">
    <base-carousel
      v-if="!state.isFetchingAll && featuredFilter?.length > 0"
      class="featured-wrapper"
      :slidesData="featuredFilter"
      :isUpdating="state.isFetchingFeatured"
    >
      <ul class="flex" v-if="!state.isFetchingAll && featuredFilter?.length">
        <channels-list class="slide" v-for="(channel, index) in featuredFilter" :key="channel.id + index" v-bind="channel"
                       data-slide="slide"
                       data-test="slide">
          <template #header>
            <span>({{ index + 1 }}/{{ state.featuredList.length }})</span>
          </template>
        </channels-list>
      </ul>
      <list-skeleton
        v-if="state.isFetchingAll"
        class="w-full h-full py-4"
      ></list-skeleton>
    </base-carousel>
  </transition>
</template>

<script setup>
// Import components
import ListSkeleton from "@/components/library/skeleton/views/ListSkeleton.vue";
import BaseCarousel from "@/components/library/carousel/BaseCarousel.vue";

// Import functions
import { provide, computed } from "vue";
import { useChannelsStore } from "@/stores/useChannels";
import ChannelsList from "@/components/library/portal/views/listViews/ChannelsList.vue";
// PROPS
const props = defineProps({
  iconsGridHeader: {
    type: Array,
    required: false
  }
});

// app data //
const { state } = useChannelsStore();

const featuredFilter = computed(() => {
  return state.data.filter(e => e.featuredAtUtc)
})
// PROVIDE //
provide("isGridActive", true);
provide("setMode", "channels");
provide("useLayers", false);

// EMITS //
const emit = defineEmits(["handle:events"]);

</script>
<style scoped>

.featured-wrapper {
  @apply h-max bg-dark-300 rounded-lg px-16 py-4;

  :deep(.list-item) {
    @apply hover:bg-transparent;

    &:after {
      @apply hidden;
    }
  }

  :deep(.wrapper),
  :deep(.date) {
    @apply text-light-700
  }

  :deep(.col__header),
  :deep(.col__tags),
  :deep(.col__description) {
    @apply text-white;
  }

  :deep(.show-more-less-button) {
    @apply hidden
  }
}

/* LIST */
.list {
  @apply w-full overflow-y-auto;
  scrollbar-width: thin;
  scrollbar-color: var(--sd-secondary-500) white;
  scrollbar-gutter: stable;
}

/*  Typography */
.counter {
  color: rgba(255, 255, 255, 0.95);
}

/* Slide  */
.slide {
  @apply absolute top-0 left-0 right-0 w-full items-center justify-center;
  transition: transform 1s;
}

/* Button */
.page-link-btn {
  @apply mr-4 hover:text-light-700;
  color: rgba(255, 255, 255, 0.95);
}
</style>
