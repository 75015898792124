import { createApp } from "vue";
// @ts-ignore
import App from "@/App.vue";
import "./assets/index.css";
import { createPinia, setActivePinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { markRaw } from "vue";
import { createI18n } from "vue-i18n";

// @ts-ignore
import de from "@/locales/de.json";
// @ts-ignore
import en from "@/locales/en.json";
import router from "@/router/index";
import "nprogress/nprogress.css";
// COMPONENTS
// @ts-ignore
import BaseCard from "@/components/library/BaseCard.vue";
// @ts-ignore
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
// @ts-ignore
import BaseWrapper from "@/components/library/wrappers/BaseWrapper.vue";
// @ts-ignore
import BaseButton from "@/components/library/buttons/BaseButton.vue";
// @ts-ignore
import BasePortal from "@/components/library/layouts/BasePortal.vue";
// @ts-ignore
import BasePanel from "@/components/library/wrappers/BasePanel.vue";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import useFontawesome from "@/fontawesome";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueMatomo from "vue-matomo";
import mitt from "mitt";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(({ store }) => {
  store.router = markRaw(router);
});
setActivePinia(pinia);


const i18n = createI18n({
  legacy: false,
  locale: "de",
  fallbackLocale: "en",
  messages: {
    en: en,
    de: de
  }
});

const app = createApp(App);


useFontawesome();
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("font-awesome-layers", FontAwesomeLayers);
// Import Global Components
app.component("base-card", BaseCard);
app.component("base-wrapper", BaseWrapper);
app.component("base-button", BaseButton);
app.component("base-portal", BasePortal);
app.component("base-panel", BasePanel);
app.component("base-title", BaseTitle);
// GLOBAL EVENT BUS
const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.provide("emitter", emitter);
// Dependencies
app.use(router);
app.use(pinia);
app.use(Toast);
app.use(i18n);
app.use(VueMatomo, {
  router: router,
  requireConsent: false,
  host: "https://log.streamdiver.com",
  // trackerFileName: 'index',
  // trackerScriptUrl: 'https://log.streamdiver.com',
  trackerUrl: "https://log.streamdiver.com/js/tracker.php",
  debug: false,
  enableLinkTracking: true,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15
  /** Other configuration options **/
});
// optional
app.use(VueTippy
  // ,
  // {
  //   directive: "tippy", // => v-tippy
  //   component: "tippy" // => <tippy/>
  // }
);
app.mount("#app");