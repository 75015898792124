<script setup lang="ts">
import ChannelsList from "@/components/library/portal/views/listViews/ChannelsList.vue";
import MediaList from "@/components/library/portal/views/listViews/MediaList.vue";
import BaseHeader from "@/components/library/list/BaseHeader.vue";
import BaseObserver from "@/components/library/util/BaseObserver.vue";
import ListSkeleton from "@/components/library/skeleton/views/ListSkeleton.vue";
import ListSearch from "@/components/library/portal/views/listViews/search/ListSearch.vue";
import { inject, computed } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useInfiniteScroll } from "@/composables/useInfiniteScroll";
import { useSearchStore } from "@/stores/useSearch";

const allData = inject("data", []);
const mode = inject("mode", "channels");
const isLoadingAll = inject("isLoadingAll", true);

const { t } = useI18n();
const searchStore = useSearchStore();
const searchResults = storeToRefs(searchStore);


const portalView = computed(() => {
  if (searchResults.result.value) {
    return ListSearch;
  }
  if (mode === "channels") {
    return ChannelsList;
  } else {
    return MediaList;
  } // add on if we add different views
});

const mediaListHeader = computed(() => [{
  name: t("headers.portalList.title")
}, {
  name: t("headers.portalList.type")
}, {
  name: t("headers.portalList.created")
}, {
  name: t("headers.portalList.views")
}]);

const data = computed(() => {
  // search data
  if (searchResults.result.value?.channels && mode === "channels") {
    return searchResults.result.value.channels;
  } else if (searchResults.result.value) {
    return searchResults.result.value.singleChannel;
  } else {
    // standard data
    return allData.value;
  }
});


const { handleInfiniteScroll, isFetchingMore } = useInfiniteScroll();

</script>

<template>
  <!--  <transition-group tag="ul" class="list" name="fade-down">-->
  <ul class="list">
    <base-header v-if="mode === 'channel' && !searchResults.result.value" :headings="mediaListHeader" key="header" />
    <template v-if=" !isLoadingAll && data && data?.length">
      <component
        :is="portalView" v-for="(d, index) in data"
        :key="d.id + index" v-bind="d" />

    </template>
    <list-skeleton class="grow" v-if="isFetchingMore" v-for="(n, index) in 5" :key="'list'+ index" />
    <base-observer key="observer-card" :intersection-options="{root: null, rootMargin: '0px', threshold: 0.5,}"
                   @callback="handleInfiniteScroll" />
    <li class="text-xl px-8 text-dark-800 font-bold py-12" key="empty-list" v-if="!isFetchingMore && !isLoadingAll && !data?.length">
      {{ $t("feedback.emptyData") }}
    </li>
  </ul>
  <!--  </transition-group>-->
</template>

<style lang="postcss" scoped>
.list {
  @apply flex flex-col relative text-dark-500 w-full min-w-full;

  &::after {
    content: "";
    @apply bottom-0 left-0 absolute h-1 w-[100%] border-b border-b-neutral-300
  }

  :deep(.list__header) {
    @apply p-4 gap-3;

    :first-child {
      @apply basis-[10rem] grow pl-[9rem];
    }

    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      @apply basis-[12%] shrink-0
    }

    :nth-child(3) {
      @apply basis-[5.319rem];
    }

    :nth-child(4) {
      @apply text-right;
    }
  }
}
</style>