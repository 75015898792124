<template>
  <div class="tabs-container">
    <ul class="tabs-container__heading">
      <li
        v-for="(tab, i) in tabs"
        ref="tabHeadingsElements"
        :class="['tab', { 'tab--active': i === activeTabIndex }]"
        :key="tab.title + i"
      >
        <base-button @click="activeTabIndex = i">
          <h6 class="tab__heading">{{ tab.title }}</h6>
        </base-button>
      </li>
      <li class="tab-underline" ref="underlineEl"></li>
    </ul>

    <!--      v-for="(tab, i) in tabs"-->
    <transition name="fade">
      <base-vertical-tab
        v-if='tabs[activeTabIndex]'
        class="tabs-container__content"
        :index="activeTabIndex"
        :name="tabs[activeTabIndex]?.title"
      >
        <div
          v-if="
            tabs[activeTabIndex]?.title !== 'Indexing' && tabs[activeTabIndex]?.title !== 'Video OCR'
          "
        >
          <div
            v-for="(child, index) in tabs[activeTabIndex].children"
            :key="index"
            :class="child.class"
          >
            <div v-for="(tag, index) in child.tags" :tag="index" :class="tag.class">
              <div
                v-if="tag.type === 'array' && tag.value.length > 0"
                :class="tag.type === 'array' && 'taglist'"
                class="py-2"
              >
                <base-tag
                  :key="val"
                  v-if="tag.component === 'base-tag'"
                  v-for="val in tag.value"
                  :class="{ '!bg-success-600 !border-success-600': checkIfHighlighted(val) }"
                  size="small"
                >
                  <span :class="{ highlight: checkIfHighlighted(val) }" v-html="val" />
                </base-tag>
              </div>
              <base-truncator
                :insert-html="true"
                class="text-black"
                v-else-if="tag.component === 'p' && checkIfHTML(tag.value)"
                :text="extractContent(tag.value)"
                :expanded-lines="1"
              />
            <!--     Icon         -->
              <p
                class="text text-primary-500"
                :class="'hide'"
                v-html="tag.value"
                v-else-if="tag.component === 'p' && !checkIfHTML(tag.value)"
              ></p>
              <base-asset-type
                v-else-if="tag.component === 'base-asset-type'"
                class='mr-2' :type="tag.value" :file='null'/>
              <base-views
                v-else-if="tag.component === 'base-views'"
                :showNumberFirst="true"
                :label="$t('views')"
                :amountViews="tag.value"
              >
              </base-views>
              <base-date class="date" v-else-if="tag.component === 'base-date'" :date="tag.value" />
              <search-transcript
                v-else-if="tag.component === 'search-transcript'"
                :transcriptions="tag.value"
              />
            </div>
          </div>
        </div>
        <search-index
          v-if="tabs[activeTabIndex].title === 'Indexing'"
          :children="tabs[activeTabIndex].children"
        />
        <search-video-text
          v-if="tabs[activeTabIndex].title === 'Video OCR'"
          :children="tabs[activeTabIndex].children"
        />
        <search-pdf
          v-if="tabs[activeTabIndex].title === 'PDF'"
          :pdfContent="tabs[activeTabIndex].children[0].tags"
          :id="id"
        />
      </base-vertical-tab>
    </transition>
  </div>
</template>

<script setup lang="ts">
import BaseTag from '@/components/library/ui/BaseTag.vue'
import BaseDate from '@/components/library/counter/BaseDate.vue'
import SearchIndex from '@/components/library/search/SearchIndex.vue'
import SearchVideoText from '@/components/library/search/SearchVideoText.vue'

import { onMounted, ref, watch, watchEffect } from 'vue';
import BaseViews from '@/components/library/counter/BaseViews.vue'
import SearchTranscript from '@/components/library/search/SearchTranscript.vue'
import SearchPdf from '@/components/library/search/SearchPdf.vue'
import BaseVerticalTab from '@/components/library/tabs/BaseVerticalTab.vue'
import BaseButton from '@/components/library/buttons/BaseButton.vue'
import BaseTruncator from '@/components/library/typography/BaseTruncator.vue'
import BaseAssetType from '@/components/library/ui/BaseAssetType.vue';

type Props = {
  id: string
  tabs: {
    title: string // info, transcription, etc
    children: {
      class?: string
      title?: string
      tags: {
        type: 'array' | 'text'
        value: string | number
        color?: string
        component: string
        class?: string
      }[]
    }[]
  }[]
}
const props = defineProps<Props>()
const activeTabIndex = ref(0)
const underlineEl = ref<HTMLLIElement>()
const tabHeadingsElements = ref<HTMLLIElement[]>()

watchEffect(() => {
  if (!underlineEl.value || !tabHeadingsElements.value) return

  // Set the width of the underline element to the width of the active tab
  underlineEl.value.style.width =
    tabHeadingsElements.value.at(activeTabIndex.value)?.offsetWidth + 'px' || 0

  // translate the underline element to the active tab
  underlineEl.value.style.transform = `translateX(${
    tabHeadingsElements.value.at(activeTabIndex.value)?.offsetLeft || 0
  }px)`
})

onMounted(() => {
  if(props.tabs.length > 1) {
    activeTabIndex.value = 1;
  }
})
function extractContent(htmlText) {
  // Create a temporary div element
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlText

  // Get the first child element because api provides one element
  const firstChild = tempDiv.firstChild

  if (firstChild && firstChild.innerHTML) {
    const innerHtml = firstChild.innerHTML.trim()
    return innerHtml
  } else {
    // Return an empty string if the response is invalid
    return ''
  }
}

const checkIfHighlighted = (text) => text.includes('<em>')

function checkIfHTML(text) {
  return !(text === 'Media' || text === 'Video' || text === 'Audio' || text === 'File')
}
</script>

<style lang="postcss" scoped>
.tabs-container {
  @apply w-full overflow-hidden;

  &__heading {
    @apply flex gap-2 overflow-y-auto bg-neutral-200 relative;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  &__content {
    @apply bg-white !px-0 !py-2;
  }
}

.tab-underline {
  @apply w-8 h-[3px] bg-secondary-500 absolute bottom-0 left-0 transition-all ease-in duration-300;
}

.tab {
  @apply px-4 p-1 transition-all text-neutral-500;

  &__heading {
    @apply font-bold text-base;
  }

  &--active {
    @apply text-dark-800 font-bold;
  }
}

.taglist {
  @apply transition-all ease-in duration-500 overflow-hidden flex items-center max-h-14 flex-wrap gap-2 overflow-x-auto hover:max-h-full;
}

.highlight {
  :deep(em) {
    @apply text-white;
  }
}
</style>
