<template>
  <div class="search-container">
    <div class="relative">
      <font-awesome-icon
        class="text-neutral-500"
        :icon="searchIcon"
        size="lg"
      ></font-awesome-icon>
      <font-awesome-icon
        class="absolute bottom-0.5 -translate-x-full text-[0.75rem] text-neutral-800"
        :icon="['fas', 'magnifying-glass']"
      ></font-awesome-icon>
    </div>
    <base-badge mode="neutral" border-radius="r-large" size="smallest"
                v-for="(field, index) in searchState.fields.value"
                :key="index + field" v-if="searchState.fields.value.length">
      {{ field }}
    </base-badge>
    <input
      class="search-container__input body-1"
      type="search"
      name="search"
      id="search"
      key="search"
      autocomplete="off"
      v-model="state.searchTerm"
      :placeholder="searchPlaceholder"
    />
    <base-button
      :use-ping="{isActive: !!searchState.fields.value.length, dotColor:'bg-primary-500', pingColor:'bg-primary-200'}"
      @click="updateState({key:'isExtendedSearchOpen', value: !appState.isExtendedSearchOpen})">
      <font-awesome-layers class="fa-2xl cursor-pointer">
        <font-awesome-icon
          :class="{
                    opened: appState.isExtendedSearchOpen,
                  }"
          :icon="['fas', 'circle']"
        />

        <font-awesome-icon
          :class="{
                    opened: appState.isExtendedSearchOpen,
                  }"
          :icon="['fas', appState.isExtendedSearchOpen ? 'xmark' : 'bars-filter']"
          transform="shrink-7"
        >
        </font-awesome-icon>
      </font-awesome-layers>
    </base-button>
    <!--  Search Scope  -->
    <!--    <client-only>-->
    <!--      <search-dropdown v-if="state.isFocused && state.searchTerm.length > 0" :text=" state.searchTerm"-->
    <!--                       @submitSearch="handleSubmit($event)" />-->
    <!--    </client-only>-->
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useAppStore } from "@/stores/useApp";
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import { useSearchStore } from "@/stores/useSearch";
import BaseBadge from "@/components/library/ui/BaseBadge.vue";
import { useConfigStore } from "@/stores/useConfig";

const state = reactive({
  searchTerm: "",
  debounce: null,
  isFocused: false
});

const { t } = useI18n();
const { state: appState, updateState } = useAppStore();
const searchStore = useSearchStore();
const searchState = storeToRefs(searchStore);
const { setVal } = searchStore;
const { search, setAutoReset } = searchStore;
const { portalView, updatePortalView } = useConfigStore();
const route = useRoute();

const mode = inject("mode");

let lastPortalView = null;

const searchIcon = computed(() => {
  // If we have to adapt in future the icon
  if (route.name === "ChannelView") {
    return ["fal", "photo-film"];
  }

  if (route.name === "ChannelsView") {
    return ["far", "fa-rectangle-history"];
  }
});

const searchPlaceholder = computed(() => {

  if (route.name === "ChannelView") {
    return t("search.placeholder.media");
  }

  if (route.name === "ChannelsView") {
    return t("search.placeholder.channels");
  }
});


watch(searchState.text, (newVal, oldVal) => {
  // Makes the search on initial search jump to list view if the search was empty
  if (oldVal === "") {
    lastPortalView = portalView[mode];
    updatePortalView(mode, "list");
  }
  // if active means search got triggered through a tag click
  if (searchState.shouldAutoReset.value) {
    state.searchTerm = newVal;
  }

  if (newVal === "") {
    if (searchState.shouldAutoReset.value) {
      searchStore.$reset();
      setAutoReset(false);
    }
    updatePortalView(mode, lastPortalView);
  }
});

watch(() => state.searchTerm, async (searchTermNew) => {
  if (route.name === "ChannelsView") {
    setVal({ key: "type", value: "channel" });
  } else if (route.name === "ChannelView") {
    setVal({ key: "channelId", value: route.params.channelId });
    setVal({ key: "type", value: "mediaasset" });
  } else {
    setVal({ key: "type", value: "mediaasset" });
  }

  clearTimeout(state.debounce);

  try {
    state.debounce = setTimeout(async () => {
      await search(searchTermNew.trim());
    }, 500);
  } catch (e) {
    console.error(e);
  }
});

watch(route, () => {
  searchStore.$reset();
  state.searchTerm = "";
});

onBeforeUnmount(() => {
  searchStore.$reset();
  state.searchTerm = "";
});

</script>


<style lang="postcss" scoped>
.search-container {
  @apply flex items-center gap-2 h-12 bg-light-100 px-2 rounded-md;

  &__input {
    @apply inline-block bg-transparent grow;

    &::placeholder {
      @apply text-dark-300;
    }

    &:focus-visible {
      @apply outline-none
    }
  }

  .fa-circle {
    @apply text-white transition;
  }

  .fa-bars-filter {
    @apply text-neutral-500 transition;
  }

  .fa-circle.active.opened,
  .fa-circle.opened {
    @apply text-neutral-600 ;
  }

  .fa-circle.active {
    @apply text-primary;
  }

  .fa-xmark.opened,
  .fa-bars-filter.active {
    @apply text-white;
  }
}

</style>