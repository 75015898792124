<template>
  <ul class="list">
    <li v-for="channel in state.featuredList" class="list__item"
        :class="{'router--active': $route.path === paths.CHANNELS_ROUTE + channel.id}">
      <base-button :icon="{name: 'star', type:'fas'}" class="featured-btn" :aria-label="$t('altText.featuredBtn')"
                   v-if="channel.featuredAtUtc" />
      <router-link :to="paths.CHANNELS_ROUTE + channel.id" class="link" :class="{'pl-6': channel.featuredAtUtc}">
        <base-title class="body-2" tag="span">{{ channel.name }}</base-title>
        <span class="body-2">{{ channel.mediaCount? channel.mediaCount : 0 }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script setup lang="ts">
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import { useChannelsStore } from "@/stores/useChannels";
import { onMounted, ref } from "vue";
import { paths } from "@/settings/settings";

const { state, fetchAllFeatured } = useChannelsStore();

onMounted(() => {
  fetchAllFeatured();
})
</script>

<style lang="postcss" scoped>
.list {
  @apply w-full max-h-[100%] overflow-auto text-light-700 mt-1 flex flex-col;

  &__item {
    color: var(--headerFontColor);
    @apply grow px-3 relative cursor-pointer transition-all rounded-md;
  }

  .link {
    @apply flex justify-between py-3
  }

  .featured-btn {
    @apply absolute top-1/2 -translate-y-1/2
  }
}
</style>