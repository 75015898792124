<template>
  <div
    :class="{
    'form-group' : true,
    'horizontal': horizontal
  }">
    <label>
      <span class="caption">{{ label }}</span>
      <input
        ref="inputEl"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="val"/>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    showUnit: {
      type: Boolean,
      required: false,
      default: true,
    },
    selectedValue: 0,
    horizontal: false,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      val: this.selectedValue
    }
  },

  methods: {
    set(value) {
      this.value = value;
      // this.v$.value.$touch();
    },
    calculateSelectedRange() {
      const target = this.$refs.inputEl
      const min = target.min
      const max = target.max
      const val = target.value
      this.val = target.value
      target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    }
  },

  mounted() {
    this.calculateSelectedRange()
  },

  watch: {
    val(newVal, oldVal) {
      this.calculateSelectedRange()
    },
    selectedValue(newVal) {
      const target = this.$refs.inputEl
      target.value = newVal
      this.calculateSelectedRange()
    }
  }
};
</script>

<style lang="postcss" scoped>

.form-group {
  @apply w-full p-2;
}

.form-group.horizontal {
  @apply pr-1;
}


label span {
  @apply w-full block dark:text-white text-gray-500 pl-2 text-sm pr-2;
}

.form-group.horizontal label span {
  @apply text-neutral-500 pl-0;
  font-size: 12px;
}

input {
  @apply w-full;
}

.horizontal label {
  @apply flex items-center;
}

.horizontal label span {
  @apply w-[84px];
}


input[type='range']::-ms-track {
  @apply w-full;
  height: 5px;

  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}

input[type='range']::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type='range']::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
}

input[type='range']:focus::-ms-fill-lower {
  background: #888;
}

input[type='range']:focus::-ms-fill-upper {
  background: #ccc;
}

input[type=range] {
  -webkit-appearance: none;
  @apply bg-gradient-to-r from-secondary-500 to-secondary-500 z-10;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
  height: 5px;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply bg-opacity-20 bg-black z-0;
  height: 5px;
  border: none;
  border-radius: 3px;

  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply bg-white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .25);
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-top: -4px;
}

input[type='range']:focus {
  outline: none;
}

</style>
