<script setup lang="ts">
import { useAppStore } from "@/stores/useApp";

const { state: appState } = useAppStore();

</script>

<template>
  <section class="layout" :class="{'layout--stand-alone':appState.isStandAlone}">
    <div class="layout__top" v-if='!appState.isStandAlone'>
      <slot name="top"></slot>
    </div>
    <div class="content">
      <div class="layout__left" :class="{'layout__left--stand-alone':appState.isStandAlone}">
        <slot></slot>
      </div>
      <div class="layout__right" v-if='!appState.isStandAlone'>
        <slot name="right"></slot>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.layout {
  @apply w-full h-full flex flex-col p-4 2xl:p-3 gap-4 2xl:gap-3;

  &--stand-alone {
    @apply w-[100vw] h-screen p-0 overflow-hidden max-w-none block bg-black;
  }

  &__top {
    @apply lg:mb-0 w-full;
  }
  .content {
    @apply flex flex-col lg:flex-row gap-4 2xl:gap-3 flex-1 ;
  }
  &__left {
    @apply mb-2 lg:mb-0 max-h-[calc(100vh-9.5rem)] overflow-y-auto lg:basis-[49%] lg:flex-1;

    &--stand-alone {
      @apply !max-w-none !max-h-none !mb-0 flex-grow;
    }
  }

  &__right {
    @apply lg:basis-[44%] lg:max-w-none lg:flex-1;
  }
}
</style>