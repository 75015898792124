<template>
  <div ref="rootEl">
    <div
      v-for="(object, objectIndex) in metaData"
      :key="`seeker-object-index-${objectIndex}`"
      class="line">

      <span
        v-for="(instance, index) in object?.instances"
        :id="title+'-marker-' + objectIndex + '-' + index"
        :key="'object-'+index"
        ref="object"
        :class="[
            'marker',
            selectedObjects === undefined || selectedObjects.indexOf(objectIndex) > -1 ? `selected bg-[#14e29c] ${getClass(color)}` : '',
            selectedObjects === undefined ? 'h-12': ''
          ]"
        :style="{
            'left': instance.start/videoDuration*100 + '%',
            width: ( instance.duration > 1 ? instance.duration :  1)/videoDuration*100 + '%',
            background: color,
          }"
        @click="handleClick(instance.start), $emit('updateActiveSection', { name: object.name, type: title, start: instance.start })"
        v-on:mouseover="showTooltip(instance.start, objectIndex, index)">
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect } from "vue";
import { useTippy } from "vue-tippy";
import { useDateFormat } from "@vueuse/core";

type Props = {
  metaData: [],
  title: string,
  videoDuration: number,
  color: string
}

const rootEl = ref(null);
const props = defineProps<Props>();
const selectedObjects = inject("selectedObjects");
const emitter = inject("emitter");

const handleClick = (startTime) => {
  emitter.emit("player-jump-to-seconds", startTime);
};

const getClass = (color) => {
  return "bg-" + color;
};

const showTooltip = (startTime, objectIndex, index) => {
  let el = rootEl.value.querySelector("#timeline-marker-" + objectIndex + (index ? "-" + index : ''));
  const date = useDateFormat(startTime * 1000, "HH:mm:ss");
  const tt = useTippy(el, {
    content: date
  });
  // const tt = createTooltip(el, {
  //   triggers: [],
  //   content: $moment
  //     .utc(startTime * 1000)
  //     .format("HH:mm:ss")
  // })
  //
  // tt.show()

  // let handleMouseout = function () {
  //   setTimeout(() => {
  //     if (tt !== undefined) {
  //       tt.hide()
  //       setTimeout(() => {
  //         destroyTooltip(el)
  //         el.removeEventListener('mouseout', handleMouseout)
  //       }, 400)
  //     }
  //   }, 200)
  // }
  // el.addEventListener('mouseout', handleMouseout)
};

watchEffect(() => {
  // console.log("selectedObjects", props);
});
</script>

<style scoped>
span {
  @apply absolute block opacity-0 text-neutral-500;
  height: 0px;
}

span.selected {
  @apply absolute block opacity-100 cursor-pointer;
  height: 16px;
}

span.selected.h-12 {
  height: 3rem;
}

.marker {
  @apply hover:scale-x-100 hover:scale-y-125;
}
</style>
