<template>
    <div class="dialog-wrapper">
        <div class="dialog">
            <div class="dialog-title">
                <h6>{{ props.title }}</h6>
                <font-awesome-icon class="icon" icon="fa-xmark" @click="$emit('close')"/>
            </div>
            <div class="dialog-content">
          <div class="iframe-text">
            {{ iframeText }}
          </div>
          <sd-switch
            v-if="embedViaJs"
            class="switch"
            :use-flex="true"
            :label="$t('shareDialog.embedJsWidget')"
            :value="embedViaJs"
            @update:value="embedViaJs = $event"
            label-size="small"
          />
          <div v-else class="flex flex-col gap-2">
            <div class="flex flex-col gap-2">
              <div class="flex flex-row gap-2">
                <div
                  class="selection-list__item"
                  :class="{'item-active': option === selected}"
                  v-for="(option, i) of options"
                  :key="i"
                  @click="selected = option"
                >
                  <div :class="['radio', {'radio--active': option === selected}]"></div>
                  <div>
                    <span>{{ option }}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap gap-2 mb-2">
                <sd-simple-input
                  class="flex-1 share-options-input"
                  name="width"
                  :placeholder="'Width'"
                  :type="'number'"
                  v-model="width"
                  :disabled="selected === t('shareDialog.responsive')"
                />
                <sd-simple-input
                  class="flex-1 share-options-input"
                  name="height"
                  :placeholder="'Height'"
                  :type="'number'"
                  v-model="height"
                  :disabled="selected === t('shareDialog.responsive')"
                />
                <sd-simple-input
                  name="startTime"
                  class="share-options-input"
                  :type="'time'"
                  :step="1"
                  :placeholder="'Start Time'"
                  v-model="startTime"
                />
                <sd-simple-input
                  name="endTime"
                  class="share-options-input"
                  :type="'time'"
                  :step="1"
                  :placeholder="'End Time'"
                  v-model="endTime"
                />
              </div>
            </div>
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.embedJsWidget')"
              :value="embedViaJs"
              @update:value="embedViaJs = $event"
              label-size="small"
            />
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.standaloneMode')"
              :value="isStandalone"
              @update:value="isStandalone = $event"
              label-size="small"
            />
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.inLoop')"
              :value="inLoop"
              @update:value="inLoop = $event"
              label-size="small"
            />
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.allowAutoplay')"
              :value="allowAutoplay"
              @update:value="allowAutoplay = $event"
              label-size="small"
            />
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.controlbar')"
              :value="hideControlbar"
              @update:value="hideControlbar = $event"
              label-size="small"
            />
            <sd-switch
              class="switch"
              :use-flex="true"
              :label="$t('shareDialog.videoCover')"
              :value="videoCover"
              @update:value="videoCover = $event"
              label-size="small"
            />
          </div>
          <div class="w-full flex flex-row justify-end gap-4 w-full">
            <base-button class="copy-url-btn" @click="copyUrlToClipBoard()">{{ t('shareDialog.copyUrl') }}</base-button>
            <base-button class="copy-btn" @click="copyToClipBoard()">{{ t('copy') }}</base-button>
          </div>
        </div>
        </div>
    </div>

  </template>
  
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import SdSwitch from "@/components/library/forms/SdSwitch.vue";
import SdSimpleInput from "@/components/library/forms/SdSimpleInput.vue";
import { useConfigStore } from "@/stores/useConfig";
import { computed, ref } from "vue";
import { useToast } from 'vue-toastification';

const { t } = useI18n();
const toast = useToast();
const props = defineProps<Props>();

interface Props {
  title: String,
  id: String,
  duration?: String,
  embedViaJsText: String
  aspectRatio: String
}

defineEmits<{
  (event: "close"): void
}>();

const { apiConfig } = useConfigStore();

const options = [t('shareDialog.responsive'), t('shareDialog.static')]
const selected = ref(options[0])
const height = ref('')
const width = ref('')
const startTime = ref('00:00:00')
const endTime = ref(props.duration)
const embedViaJs = ref(false)
const isStandalone = ref(false)
const hideControlbar = ref(false)
const videoCover = ref(false)
const inLoop = ref(false)
const allowAutoplay = ref(false)

const timeParams = computed(() => {
  const [startHours, startMinutes, startSeconds] = startTime.value.split(":");
  const [endHours, endMinutes, endSeconds] = endTime.value.split(":");
  let start = parseInt(startHours) * 3600 + parseInt(startMinutes) * 60 + parseInt(startSeconds)
  let end = parseInt(endHours) * 3600 + parseInt(endMinutes) * 60 + parseInt(endSeconds)
  return `start=${start}&end=${end}`
});

const heightControl = computed(() => (height.value ? height.value : '100%'));
const widthControl = computed(() => (width.value ? width.value : '100%'));

const sourceLink = computed(() => {
  return `${window.location.host}/public/media/${props.id}`
});

const embedText = computed(() =>
  `https://${window.location.host}/public/media/${props.id}${endTime.value === '' ? '' : '?' + timeParams.value}${
      allowAutoplay.value ? '&autoplay=true' : ''
    }${isStandalone.value ? '&standAlone=true' : ''}${
      inLoop.value ? '&loop=true' : ''
    }${videoCover.value ? '&mediaCover=true': ''}${hideControlbar.value ? '&hideControls=true':''}`
)

const iframeText = computed(() => {
  return embedViaJs.value 
  ? props.embedViaJsText
  : `<iframe style="border:none; aspect-ratio: ${props.aspectRatio ? props.aspectRatio.replace(':', '/') : '16/9'};"
  src="${embedText.value}" width="${widthControl.value}" height="${
      heightControl.value
    }" allowfullscreen="true"></iframe>`
})

function copyUrlToClipBoard() {
  navigator.clipboard.writeText(embedText.value)
  toast.success(t('shareDialog.copied'));
}

function copyToClipBoard() {
  navigator.clipboard.writeText(iframeText.value as String)
  toast.success(t('shareDialog.copied'));
}
</script>
  
  <style lang="postcss" scoped>

  .dialog-wrapper {
    @apply fixed inset-0 w-full h-full bg-black bg-opacity-50 duration-300 overflow-y-auto backdrop-filter backdrop-blur z-50;
  }
  .dialog {
      max-height: 75vh;
      @apply relative p-4 top-[10%] w-[99%] md:w-[70%] lg:w-[50%] max-w-[600px] 2xl:w-1/3 mx-2 sm:mx-auto my-10 opacity-100 rounded-md bg-white text-neutral-800 transition-all delay-300;
  }

  .dialog-title {
    @apply flex flex-row justify-between my-2
  }

  .iframe-text {
    @apply border border-neutral-400 text-neutral-800 rounded-[.25rem] bg-neutral-100 p-2 w-full mb-2
    focus:outline-none focus:shadow-md w-full h-full overflow-hidden transition-all;
  }

  .selection-list {  
    &__item {
      @apply p-2 flex items-center gap-2 cursor-pointer
    }
  }  
  .radio {
    @apply w-3 h-3 rounded-full bg-white border border-gray-600 relative;
  
    &--active::before {
      content: '';
      @apply w-2 h-2 rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-blue-600
    }
  }

  .share-options-input >>> .text-area-container{
    @apply border border-neutral-400 text-neutral-800
  }

  .copy-url-btn{
    @apply h-10 w-fit px-4 text-lg rounded border-2 border-[var(--mainColor)] text-[var(--mainColor)] hover:border-blue-500 hover:text-blue-500;
  }
  .copy-btn{
    @apply h-10 px-4 w-fit text-lg rounded bg-[var(--mainColor)] text-light-100 hover:bg-blue-500;
  }
  </style>