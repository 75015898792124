import { useSearchStore } from "@/stores/useSearch";
import { useRoute } from "vue-router";

export const useSharedPortal = function() {

  const searchStore = useSearchStore();
  const route = useRoute();

  const { setVal, search, setAutoReset } = searchStore;
  const handleTagClick = async (tag: string) => {
    if (route.name === "ChannelsView") return;
    // set the tags active in search store
    if (!searchStore.fields.includes("tag")) {
      setVal({ key: "fields", value: "tag" });
    }
    // set the search text in search store
    searchStore.$patch(state => {
      state.text = tag;
    });
    // set auto reset
    setAutoReset(true);

    try {
      await search(tag);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    handleTagClick
  };
};