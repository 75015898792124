<script setup lang='ts'>
import {computed} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const props = defineProps<{
    type: string,
    file: {
      type: Object,
    }
  }>();

  const typeTitle = computed(() => {
    const str = props.type;
    if(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    }else{
      return '';
    }
  });

  const typeIcon = computed(() => {

    if (props.type.toLowerCase() === "video") {
      return "fa-solid fa-film";
    }
    if (props.type.toLowerCase() === "image") {
      return "fa-regular fa-image";
    }
    if (props.type.toLowerCase() === "audio") {
      return "fa-regular fa-music";
    }
    if (props.type.toLowerCase() === "document") {
      if(props.file && props.file?.mimeType === 'application/pdf') {
        return 'fa-regular fa-file-pdf';
      }
      return `fa-regular fa-file`;
    }
  })

</script>

<template>
  <p class="primary" v-tippy="{content:typeTitle, theme: 'light'}">
    <font-awesome-icon
      class='text-primary'
                       :icon="typeIcon" />
  </p>
</template>

<style scoped lang='postcss'>
  .primary {
    @apply text-[var(--mainColor)] text-sm;
  }
</style>