<template>
  <li class="media-item">
    <base-card class="card-container">
      <div class="img-skeleton"></div>
      <div class="wrapper">
        <div class="line-skeleton"></div>
        <div class="line-skeleton"></div>
      </div>
    </base-card>
  </li>
</template>

<script lang="ts" setup>
</script>

<style lang="postcss" scoped>
/* GENERAL STYLING */
.media-item {
    @apply block w-full list-none relative bg-transparent overflow-hidden;
}

.img-skeleton {
    @apply aspect-video rounded-lg bg-[#ccc] dark:bg-neutral-800 bg-gradient-to-r from-[#ddd] dark:from-neutral-600 via-[#e8e8e8] dark:via-neutral-400 to-[#ddd] dark:to-neutral-600 bg-[length:13rem] dark:bg-[length:18rem];
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    /* background-size: 600px; */
    /* background-size: 13rem; */
    animation: shine-lines 2s infinite linear;
}

.wrapper {
    @apply flex flex-col gap-3 mt-4;
}

.line-skeleton {
    @apply h-4 flex-grow rounded  bg-[#ccc] dark:bg-neutral-800 bg-gradient-to-r from-[#ddd] dark:from-neutral-600 via-[#e8e8e8] dark:via-neutral-100 to-[#ddd] dark:to-neutral-600 bg-[length:13rem] dark:bg-[length:18rem];
    /* background-size: 600px; */
    /* background-size: 13rem; */
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
    animation: shine-lines 2s infinite linear;
}

/* ANIMATION */
@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 300px;
    }
}
</style>
