<template>
  <div class="controls text-dark-500">
    <base-button
      id="order"
      @click="handleSorting"
      :icon="{name: 'arrow-down', type: 'fas'}"
      :tooltip="controlTooltip"
      :class="['mr-2', 'ml-auto', sorting ? 'rotate-180' : 'rotate-0']"
    />
    <base-selector :class="['portal-filter-selector', {'portal-filter-selector--disabled': text.length}]"
                   :options="filterOptions" :tabindex="0" :default="activeOption"
                   @get:selection="getPropertySelection" />
    <view-control />
  </div>
</template>
<script setup lang="ts">
// ** IMPORT COMPONENTS **  //
import BaseSelector from "@/components/library/dropdowns/BaseSelector.vue";
import ViewControl from "@/components/library/portal/controls/ViewControl.vue";

// ** IMPORT STORE STATES **  //
import { useConfigStore } from "@/stores/useConfig";
import { useChannelStore } from "@/stores/useChannel";
import { useChannelsStore } from "@/stores/useChannels";
import { useSearchStore } from "@/stores/useSearch";

// ** IMPORT Functions **  //
import { inject, computed, watchEffect, ref } from "vue";
import { useI18n } from "vue-i18n";
import NProgress from "nprogress";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";

// ** COMPOSABLES** //
const { header, filters, updateFilter } = useConfigStore();
const { fetchMedia } = useChannelStore();
const { fetchAll: fetchChannels } = useChannelsStore();
const { resetPageCount } = useConfigStore();
const searchStore = useSearchStore();
const { sortLocalResult } = searchStore;
const { text, sortOrder } = storeToRefs(searchStore);


const mode = inject("mode");
const { t } = useI18n();
const activeOption = ref(null);

const route = useRoute();

const controlTooltip = computed(() => sorting.value ? t("controls.sortingAsc") : t("controls.sortingDesc"));

const filterOptions = computed(() => {
    const defaultFilters = [{ value: "name", translation: t("filters.title") },
      { value: "updatedAtUtc", translation: t("filters.updatedAtUtc") },
      { value: "createdAtUtc", translation: t("filters.createdAtUtc") }];

    const searchFilters = [{ value: "relevance", translation: t("filters.relevance") }];
    // If search is active text is defined
    return text.value.length ? searchFilters : defaultFilters;
  }
);

const sorting = computed(() => {
  const defaultSort = filters[mode].sort === "";
  const searchSort = sortOrder.value === "desc" ? "" : "asc";

  return text.value.length ? searchSort : defaultSort;
});

watchEffect(() => {

  if (text.value.length) {
    return activeOption.value = filterOptions.value[0];
  }
  // Keeps the UI in sync what comes from persistent store
  const property = filters[mode].property;
  const activeIndex = filterOptions.value.findIndex(option => option.value === property);
  if (activeIndex === -1) {
    activeOption.value = false;
  } else {
    activeOption.value = filterOptions.value[activeIndex];
  }
});

// ** FUNCTIONS ** //
function handleSorting() {
  let sort = null;

  if (text.value) {
    searchStore.$patch(state => {
      if (state.sortOrder === "desc") {
        state.sortOrder = "asc";
      } else {
        state.sortOrder = "desc";
      }
    });
    return sortLocalResult(sortOrder.value);
  }

  // To start fetching from default page 1
  resetPageCount(mode);

  if (filters[mode].sort === "") {
    sort = ":desc";
  }
  if (filters[mode].sort === ":desc") {
    sort = "";
  }
  // Update store
  updateFilter({
    update: {
      value: sort, key: "sort"
    }, storeKey: mode
  });
  // Fetch and update UI
  fetchData();
}

function getPropertySelection({ value }) {
  // Update Store
  updateFilter({
    update: {
      value, key: "property"
    }, storeKey: mode
  });
  // Fetch and update UI
  fetchData();
}

async function fetchData() {
  NProgress.start();
  try {
    if (mode === "channels") {
      await fetchChannels().catch(e => console.error(e));
    } else if (mode === "channel") {
      await fetchMedia(route.params.channelId).catch(e => console.error(e));
    }
  } catch (e) {
    console.error(e);
  } finally {
    NProgress.done();
  }
}
</script>


<style scoped>
.controls {
  @apply flex;

  :deep(.selected) {
    @apply text-dark-500
  }

  :deep(.container) {
    @apply text-dark-500 md:bg-transparent p-4 md:p-0 rounded-full
  }

  :deep(.container__selected-value) {
    @apply hidden  md:inline-block
  }
}

.portal-filter-selector {
  &--disabled {
    @apply pointer-events-none;

    :deep(.chevron-down) {
      @apply invisible opacity-0 absolute;
    }

  }
}
</style>