<template>
  <li
    ref="observer"
    key="observer-list"
    class="opacity-0 relative h-0 bottom-12"
  >
    Infinite scroll not visible
  </li>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseObserver",
  props: {
    intersectionOptions: {
      type: Object,
      default: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.5
      }
    }
  },
  data() {
    return {
      observer: null as IntersectionObserver | null
    };
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      const isInView = entries.some(e => e.isIntersecting && e.intersectionRatio > 0.55);
      this.$emit("callback", isInView);
    }, {
      ...this.intersectionOptions,
      delay: 100
    });
    this.observer.observe(this.$refs.observer);
  },
  unmounted() {
    this.observer.disconnect();
  }
});
</script>

<style scoped>

</style>
