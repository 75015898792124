<template>
  <li class="list-item" @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave">
    <div class="col col__thumbnail-container">
      <component class="portal-card__header" :is="mediaViewComponent" :route="mediaRoute" :thumbnail="thumbnail"
                 :media-type="type" type="thumbnail"
                 :rendition="{source: preview?.url, mimeType:'application/x-mpegurl'}"
                 :preview="preview"
                 :alt="$t('altText.thumbnail', {type: type, name: name, description: description})"
                 :id="id" />
    </div>
    <div class="col col__title-container">
      <router-link :to="mediaRoute">
        <base-title tag="h6" class='!text-base' >{{ name }}</base-title>
      </router-link>
    </div>
    <div class="col col__media-type-container" >
      <font-awesome-icon class='text-primary' :icon="typeIcon" v-tippy="{content:capitalizeLetter(type), theme: 'light'}" />
    </div>
    <div class="col col__media-date-container">
      <base-date :show-time="true" :date="audit.createdAtUtc" />
    </div>
    <div class="col col__media-views">
      <base-views label="" :count="usage.views" />
    </div>
  </li>
</template>

<script setup lang="ts">
import BaseImage from "@/components/library/ui/BaseImage.vue";
import BaseViews from "@/components/library/counter/BaseViews.vue";
import BaseDate from "@/components/library/counter/BaseDate.vue";

import { computed, inject, ref, watchEffect } from "vue";
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import { paths } from "@/settings/settings";
import { useRoute } from "vue-router";
import { capitalizeLetter } from "@/utils/functions/useString";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import BasePreview from "@/components/library/player/BasePreview.vue";

export type Media = {
  audit: Audits,
  channels: [],
  description?: string,
  feedback?: null,
  file?: File,
  id: string,
  ingestInformation?: ingestInformation,
  name: string,
  permissions?: [],
  preview?: Preview,
  status?: string
  tags?: string[],
  thumbnail: {},
  type: string,
  usage: Usage,
}
type Audits = {
  createdAtUtc: string, createdBy: object, updatedAtUtc: string
}

interface Thumbnail {
  height: number,
  url: string,
  width: number
}

interface File {
  duration: string,
  durationInSeconds: string,
  mimeType: string,
  name: string,
  size: string,
}

interface ingestInformation {
  ingestJobStatus: "finished" | "error",
  progress: number
}

interface Preview {
  url: string,
}

interface Usage {
  downloads: number,
  views: number
}

const props = defineProps<Media>();

const typeIcon = computed(() => {
  if (props.type === "video") {
    return "fa-solid fa-film";
  }
  if (props.type === "image") {
    return "fa-regular fa-image";
  }
  if (props.type === "audio") {
    return "fa-regular fa-music";
  }
  if (props.type.toLowerCase() === "document") {
    if(props.file && props.file?.mimeType === 'application/pdf') {
      return 'fa-regular fa-file-pdf';
    }
    return `fa-regular fa-file`;
  }
});

const route = useRoute();
const mode = inject["mode"];

const delayTimeoutForComponentChange = ref(0);
const isItemHovered = ref(false);
const shouldChangeComponent = ref(false);

watchEffect(async () => {
  clearTimeout(delayTimeoutForComponentChange.value);

  if (isItemHovered.value) {
    shouldChangeComponent.value = await new Promise(resolve => {
      delayTimeoutForComponentChange.value = setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  } else {
    shouldChangeComponent.value = false;
  }
});

const mediaViewComponent = computed(() => shouldChangeComponent.value && (props.type === "video" || props.type === "audio") ? BasePreview : BaseImage);

const mediaRoute = computed(() => {
  // On channel detail page we can take advantage of route
  if (route.params.channelId) {
    return paths.CHANNELS_ROUTE + route.params.channelId + paths.MEDIA_ROUTE + props.id;
  } else {
    // On channels page we get the channel id as a prop
    return paths.CHANNELS_ROUTE + props.id;
  }
});

function handleMouseEnter() {
  isItemHovered.value = true;
}

function handleMouseLeave() {
  isItemHovered.value = false;
}
</script>


<style lang="postcss" scoped>
.list-item {
  @apply flex gap-3 items-center p-4 relative transition-all hover:bg-dark-100;

  &:not(:last-child)::after {
    content: "";
    @apply bottom-0 left-0 absolute h-1 w-[100%] border-b border-b-neutral-300
  }

  .col {
    &__thumbnail-container {
      @apply basis-[8rem] shrink-0;
    }

    &__title-container {
      @apply grow text-dark-800;
    }

    &__media-type-container {
      @apply flex gap-2 items-center
    }

    &__media-type-container,
    &__media-data-container,
    &__media-views {
      @apply basis-[12%] shrink-0;
    }

    :deep(.date) {
      @apply flex flex-col
    }

    &__media-views {
      @apply text-right;
    }

  }
}

.thumbnail-wrapper {
  @apply w-[10rem];

}
</style>