<script setup lang="ts">
import { useConfigStore } from "@/stores/useConfig";
import { inject } from "vue";

const mode = inject("mode");

const { portalView, updatePortalView } = useConfigStore();

</script>

<template>
  <div class="flex  mx-4 cursor-pointer">
    <font-awesome-layers
      class="fa-3x"
      @click="updatePortalView(mode,'list')"
    >
      <font-awesome-icon v-if="portalView[mode] === 'list'" icon="fa-solid fa-circle"
                         :class="[ portalView[mode] === 'list' ? 'selected' : '' ]" />
      <font-awesome-icon icon="fa-solid fa-list" transform="shrink-10"
                         :class="[portalView[mode] !== 'list' ? 'text-dark-500' : 'text-white', ]" />
    </font-awesome-layers>
    <font-awesome-layers
      class="fa-3x"
      @click="updatePortalView(mode,'grid')"
    >
      <transition name="fade">
        <font-awesome-icon v-if="portalView[mode] === 'grid'" icon="fa-solid fa-circle"
                           :class="[portalView[mode] === 'grid' ? 'selected' : '' ]" />
      </transition>
      <transition name="fade">
        <font-awesome-icon icon="fa-solid fa-grid" transform="shrink-10"
                           :class="[portalView[mode] !== 'grid' ? 'text-dark-500' : 'text-white' ]" />
      </transition>
    </font-awesome-layers>
  </div>
</template>

<style lang="postcss" scoped>
.selected {
  @apply text-dark-500
}
</style>