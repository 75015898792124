<template>
  <keep-alive>
    <component
      :class="['portal-body', {'portal-body--grid-channels': mode === 'channels' && portalView[mode] !== 'list'}, {'portal-body--grid': portalView[mode] !== 'list'}]"
      :is="viewComponent" />
  </keep-alive>
</template>

<script setup lang="ts">
import GridView from "@/components/library/portal/views/GridView.vue";
import ListView from "@/components/library/portal/views/ListView.vue";

import { computed, inject } from "vue";
import { useConfigStore } from "@/stores/useConfig";

const { portalView } = useConfigStore();

const mode = inject("mode");

const viewComponent = computed(() => {
  if (portalView[mode] === "grid") {
    return GridView;
  } else if (portalView[mode] === "list") {
    return ListView;
  } else return "grid"; // If we want to add more different views in the future
});
</script>

<style lang="postcss" scoped>

.portal-body {
  @apply w-full h-full rounded-b-lg overflow-y-auto bg-light-100;

  &--grid {
    @apply p-5;
  }

  &--grid-channels {
    @apply px-5 py-8
  }
}
</style>