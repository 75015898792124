<template>
  <section>
    <base-portal class="portal">
      <template #header>
        <transition name="fade-absolute">
          <component :is="headerComponent" class="mb-3" :details="state.details" />
        </transition>
        <portal-header />
      </template>
      <template #default>
        <portal-body v-if="!state.isFetchingAllMedia && !isSearchRequestActive" />
        <transition name="fade-up">
          <ul v-if="state.isFetchingAllMedia || isSearchRequestActive"
              :class="['layout--' + portalView.channel, 'layout']">
            <component v-for="n in 10" :is="loadingComponent" />
          </ul>
        </transition>
      </template>
    </base-portal>
  </section>
</template>

<script lang="ts" setup>
// Import Components

// Import Functions
import { computed, onMounted, provide, watch } from "vue";
// Import Stores
import { useConfigStore } from "@/stores/useConfig";
import { useChannelStore } from "@/stores/useChannel";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import PortalHeader from "@/components/library/portal/PortalHeader.vue";
import PortalBody from "@/components/library/portal/PortalBody.vue";
import CardSkeleton from "@/components/library/skeleton/views/CardSkeleton.vue";
import ListSkeleton from "@/components/library/skeleton/views/ListSkeleton.vue";
import {  useRoute } from "vue-router";
import ChannelHeader from "@/components/views/channelView/ChannelHeader.vue";
import { useChannelsStore } from "@/stores/useChannels";
import { useSearchStore } from "@/stores/useSearch";
import { storeToRefs } from "pinia";
import ListSkeletonWrapper from "@/components/library/skeleton/ListSkeletonWrapper.vue";

interface Props {
  channelId: string;
}

// Router injects id prop
const props = defineProps<Props>();
// ** APP DATA ** //
// ** USE STATES **  //
const { fetchMedia, fetchDetails, resetState, state } = useChannelStore();
const { fetchAll: fetchAllChannels, state: channelsState } = useChannelsStore();
// const styleStore = useStyleStore();
const searchStore = useSearchStore();
const { request } = storeToRefs(searchStore);

const { portalView, resetPageCount } = useConfigStore();
// ** COMPOSABLES **  //
const { t } = useI18n();

const route = useRoute();

watch(() => route.params, async (newId, oldId) => {
  if (newId.channelId !== oldId.channelId) {
    await Promise.all([fetchMedia(newId.channelId), fetchDetails(newId.channelId)]);
  }
});

provide("data", computed(() => state.media));
provide("isLoadingAll", computed(() => state.isFetchingAllMedia));
provide("mode", "channel");

const isSearchRequestActive = computed(() => {
  return request.value.loading;
});

const headerComponent = computed(() => state.isFetchingDetails ? ListSkeletonWrapper : ChannelHeader);

// ** COMPUTED ** //
const loadingComponent = computed(() => {
  if (portalView.channel === "grid") {
    return CardSkeleton;
  } else if (portalView.channel === "list") {
    return ListSkeleton;
  }
  // Add on here in case of different view
});

// ** FUNCTIONS ** //
onMounted(async () => {
  try {
    resetPageCount("channel");

    // Fetch channels if user goes directly to channel
    if (!channelsState.data.length) {
      await Promise.all([fetchMedia(props.channelId), fetchDetails(props.channelId), fetchAllChannels()]);
    } else {
      await Promise.all([fetchMedia(props.channelId), fetchDetails(props.channelId)]);
    }
    document.title = "Portal Channel";
  } catch (e) {
    e.channelId = props.channelId;
    throw e;
  }
});
</script>

<style lang="postcss" scoped>

section {
  @apply px-3;
}

.layout {
  @apply bg-light-100 w-full h-full;

  &--grid {
    @apply content-start grid grid-cols-1 p-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7  grid-flow-row gap-x-4 gap-y-5 items-start transition-all
  }

  &--list {
    @apply flex flex-col
  }
}


</style>
