<template>
  <main ref="mainElement" class="main">
    <the-header ref="headerElement" class="main__header" />
    <div ref="menuContainer" class="main__menu-container">
      <side-menu class="main__menu" />
    </div>
    <div id="mainContent" class="main__content" ref="mainContent">
      <slot></slot>
    </div>
    <transition name="slide-fade-right">
      <div class="main__search-container search-menu-container" v-if="appState.isExtendedSearchOpen">
        <search-side-menu />
      </div>
    </transition>
  </main>
</template>

<script lang="ts" setup>
// *** COMPONENTS *** //
import TheHeader from "@/components/TheHeader.vue";
import SideMenu from "@/components/library/menus/SideMenu.vue";
// *** FUNCTIONS *** //
import { onMounted, ref, Ref, ComponentPublicInstance, nextTick, watchEffect } from "vue";
import { useRoute } from "vue-router";
import SearchSideMenu from "@/components/library/menus/searchMenu/SearchSideMenu.vue";

import { useAppStore } from "@/stores/useApp";

const { state: appState, updateState } = useAppStore();
const route = useRoute();

interface CustomComponent extends ComponentPublicInstance {
}

const headerElement: Ref<CustomComponent | null> = ref(null);
const mainElement: Ref<HTMLDivElement | null> = ref(null);
const menuContainer: Ref<HTMLDivElement | null> = ref(null);
const mainContent: Ref<HTMLDivElement | null> = ref(null);

watchEffect(() => {
  if (route.name === "impressum" || route.name === "datenschutz") {
    updateState({ key: "isExtendedSearchOpen", value: false });
  }
});

onMounted(() => {
  nextTick(() => {
    if (headerElement.value.$el) {
      const headerRectangle = headerElement.value.$el.getBoundingClientRect();
      menuContainer.value.style.height = `calc(100vh - ${headerRectangle.height}px`;
      // To make the main menu scrollable
      mainContent.value.style.height = `calc(100vh - ${headerRectangle.height}px`;
    }
  });
});

</script>

<style lang="postcss" scoped>
.main {
  @apply grid grid-rows-1 relative pb-4 h-screen overflow-hidden bg-[var(--pageBackgroundColor)];
  grid-template-columns: [first] max-content [second] 1fr [third] max-content;

  &__header {
    @apply sticky left-0 top-0 z-50 row-end-1;
    grid-column: 1 / -1;
  }

  &__menu-container {
    @apply sticky top-0 z-50 row-start-2 row-end-3 col-end-2;
  }

  &__menu {
    @apply shadow-blur-lg bg-[var(--pageBackgroundColor)] lg:bg-transparent lg:shadow-none fixed lg:sticky top-0
  }

  &__content {
    @apply grow overflow-y-auto row-end-2 col-end-3;
  }

  &__search-container {
    @apply fixed right-0 -bottom-[5rem] z-30 shadow-blur-lg lg:shadow-none lg:static ml-2 row-start-2 h-full  row-end-3 col-start-3 col-end-4
  }
}
</style>