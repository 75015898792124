<template>
  <div :class="[ 'wrapper', { 'wrapper--flex' :useFlex} ]">
    <label v-if="label" :for="id">{{ label }}</label>
    <div :class="['text-area-container', 'text-area-container--' + styling, {'flex items-center gap-3': icon}]">
      <font-awesome-icon class="icon" v-if="icon" :icon="[icon.type, icon.name]" />
      <input
        :id="id"
        name="id"
        :class="['text-field', {'text-field--pseudo': useBrowserPseudoIcon}]"
        :type="type"
        :max="max"
        :min="min"
        :disabled="disabled"
        :placeholder="placeholder"
        v-tooltip="tooltip"
        :value="modelValue"
        :step="step"
        @input="emit('update:modelValue', $event.target.value)"
      />
      <!--        v-on:input="$emit('input', $event.target.value)"-->
      <!--        :value="value"-->
    </div>
  </div>
</template>

<script lang="ts" setup>
import uniqueId from "lodash.uniqueid";

interface Icon {
  name: string;
  type: "fas" | "far" | "fab" | "fal" | "fad";
}

interface Props {
  label?: string
  id?: string
  modelValue?: "",
  placeholder?: string,
  tooltip?: string,
  value: string | number
  type?: "text" | "number" | "email" | "password"
  useFlex?: boolean
  icon?: Icon
  disabled?: boolean
  styling?: "default" | "box-neutral"
  max?: number | string
  min?: number | string
  useBrowserPseudoIcon?: boolean
  step?: number | string
}

withDefaults(defineProps<Props>(
), {
  id: uniqueId(),
  type: "text",
  styling: "default", // has no specific styling
  disabled: false
});
const emit = defineEmits(["update:modelValue"]);


</script>

<style lang="postcss" scoped>
.wrapper {

  .text-area-container {
    @apply p-3 rounded-[.25rem] focus:outline-none focus:shadow-md w-full overflow-hidden transition-all;

    &--default {
      @apply border border-neutral-400 bg-neutral-100
    }

    &--box-neutral {
      @apply h-[2.5rem] p-0 px-3 rounded-md border text-base border-neutral-500 bg-neutral-100 text-neutral-800;
    }
  }

  &--flex {
    @apply flex justify-between items-center;

    > .text-area-container {
      @apply shadow-lg rounded-md;
    }
  }

}

.text-field {
  @apply resize-none w-full h-full text-base text-neutral-800 bg-transparent;

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    @apply hidden
  }

  &:disabled {
    @apply opacity-50
  }

  &--pseudo {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      @apply inline
    }
  }

  &:focus {
    @apply outline-none;
  }

  &::-webkit-scrollbar {
    @apply hidden;
  }

}


label {
  @apply text-neutral-500 text-base;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-area-container:focus-within {
  @apply shadow-lg;
}

.icon {
  @apply text-neutral-500
}
</style>
