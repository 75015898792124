import { useFetch } from "@/utils/useService";
import { useConfigStore } from "@/stores/useConfig";
import { StorageSerializers, useSessionStorage } from "@vueuse/core";

interface Resource {
  "data": {
    "id": string,
    "text": string,
    "languageCode": "en" | "de",
    "type": "privacy" | "imprint"
  },
  "status": "success"
}

export default {
  getSettings() {
    return useFetch("/tenants/current/settings", { method: "GET" });
  },
  getCurrentDesign() {
    return useFetch("/tenants/current/designs", { method: "GET" });
  },
  getCurrent() {
    return useFetch("/tenants/current", { method: "GET" });
  },
  getTenantResource(resourceType: string, languageCode = "de"): Promise<Response> {
    const { apiConfig } = useConfigStore();
    let tName;
    if (apiConfig.tenantName.includes("localhost")) {
      tName = "dev-internal";
    } else {
      tName = apiConfig.tenantName;
    }
    const url = `/center/textresources/${tName}/${resourceType}/${languageCode}`;
    useSessionStorage("tenantResource", url);
    return useFetch(url);
  }
};