<script setup lang="ts">
// ** IMPORT COMPONENTS ** //
import BaseBreadcrumb from "@/components/library/breadcrumbs/BaseBreadcrumb.vue";
// ** IMPORT FUNCTIONS ** //
import {  ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";
import { paths } from "@/settings/settings";
import { ChannelDetail } from "@/types/channelTypes";
import BaseScroller from "@/components/library/scrollContainers/BaseScroller.vue";
import BaseTag from "@/components/library/ui/BaseTag.vue";
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import BaseTruncator from "@/components/library/typography/BaseTruncator.vue";
import BaseImage from "@/components/library/ui/BaseImage.vue";
import { useSharedPortal } from "@/composables/useSharedPortal";

interface Props {
  details: ChannelDetail;
}

const props = defineProps<Props>();
// ** COMPOSABLES ** //
const { t } = useI18n();
const { handleTagClick } = useSharedPortal();

const isHovered = ref(true);

const items = computed(() => [
  { label: t("portalHeader.dropdown.allChannels"), path: "/" },
  reactive({ label: props.details?.name, path: paths.CHANNELS_ROUTE + props.details?.id })
]);

function handleMouseEnter() {
  isHovered.value = true;
}

function handleMouseLeave() {
  isHovered.value = false;
}
</script>

<template>
  <base-panel class="wrapper" v-if="details" @mouseenter="handleMouseEnter"
              @mouseleave="handleMouseLeave">
    <div class="wrapper__title-container">
      <base-breadcrumb :items="items" />
      <base-button class="featured-btn" :icon="{name: 'star', type:'fas'}" :aria-label="$t('altText.featuredBtn')"
                   v-if="details.featuredAtUtc" />
    </div>
    <div class="flex flex-col items-start gap-4 md:flex-row md:gap-6 lg:gap-10">
      <div class="tag-description-container ">
        <div class="wrapper__tag-container" v-if="details.tags?.length">
          <base-scroller class="absolute left-auto right-auto" :is-parent-hovered="isHovered">
            <base-tag size="small"  class="!cursor-pointer"  v-for="(tag, index) in details.tags"
                      @click="handleTagClick(tag)" :key="'t' + index">{{ tag }}
            </base-tag>
          </base-scroller>
        </div>
        <dl class="wrapper__description-container" v-if='details.description'>
<!--          <dt class="invisible absolute h-0">-->
<!--            {{}}-->
<!--          </dt>-->
          <dd>
            <base-truncator v-if="details.description" :text="details.description" :insert-html='true'/>
          </dd>
        </dl>
      </div>
      <base-image v-if="details.thumbnail" class="thumbnail-container"
                  :alt="$t('altText.thumbnail', {type: details.type, name: details.name, description: details.description})"
                  :thumbnail="details.thumbnail" />
    </div>
  </base-panel>
</template>

<style scoped lang="postcss">
.wrapper {
  @apply h-fit bg-light-100 py-4 px-6 rounded-lg;

  &__title-container {
    @apply flex justify-between mb-4
  }

  &__tag-container {
    @apply h-[1.625rem];
  }

  &__description-container {
    @apply overflow-hidden pb-4;
  }
  .tag-description-container {
    @apply relative grow flex flex-col gap-2;
  }
  .thumbnail-container {
    @apply basis-[12rem] shrink-0;
  }
  .thumbnail-container >>> img {
    @apply !object-cover;
  }
}

.featured-btn {
  @apply h-10 w-10 text-error-500 text-xl cursor-default;
}
</style>