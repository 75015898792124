import { defineStore } from "pinia";
import type { State, SearchType } from "@/stores/searchTypes/searchTypes";
import remodelResult from "@/stores/helper/remodelResult";
import api from "@/services/searchService";

export const useSearchStore = defineStore("searchStore", {
  state: () => ({
    // searchTerm: "",
    sideMenu: false,
    shouldAutoReset: false,
    request: {
      controller: null,
      loading: false,
      loadingMore: false,
      maxPage: 0,
      isAtMaxPage: false
    },
    type: "all",
    fields: [],
    text: "",
    perpage: 20,
    totalCount: 0,
    page: 0,
    channelId: null,
    dropdown: false,
    assetTypes: [],
    speakers: [],
    entities: [],
    actions: [],
    objects: [],
    keywords: [],
    filesize: {
      from: null,
      to: null
    },
    bitrate: {
      from: null,
      to: null
    },
    duration: {
      from: null,
      to: null
    },
    width: {
      from: null,
      to: null
    },
    height: {
      from: null,
      to: null
    },
    tags: [],
    // date: {
    //   from: null,
    //   to: null
    // },
    // views: {
    //   min: 0,
    //   max: 0
    // },
    users: {
      // privacy: [],
      // members: [],
      role: []
    },
    result: null,
    sortOrder: "desc"
  }) as State,

  getters: {},

  actions: {
    setVal(value: { key: string, value: string }) {
      if (value.key === "fields") {
        const findVal = this.fields.find(v => v === value.value);
        if (!findVal) {
          this.fields.push(value.value);
        } else {
          this.fields.splice(this.fields.indexOf(value.value), 1);
        }
      } else if (value.key === "assetTypes") {
        const findVal = this.assetTypes.find(v => v === value.value);
        if (!findVal) {
          this.assetTypes.push(value.value);
        } else {
          this.assetTypes.splice(this.assetTypes.indexOf(value.value), 1);
        }
      } else {
        this[value.key] = value.value;
      }
    },

    sortLocalResult(sort) {
      if (sort === "asc") {
        for (const key in this.result) {
          this.result[key] = this.result[key].reverse();
        }
      }
      if (sort === "desc") {
        for (const key in this.result) {
          this.result[key] = this.result[key].reverse();
        }
      }
    },


    setAutoReset(shouldReset: boolean) {
      this.shouldAutoReset = shouldReset;
    },

    incrementPageCount() {
      if (this.page < this.request.maxPage) {
        this.page++;
      } else {
        if (this.page === this.request.maxPage) {
          this.request.isAtMaxPage = true;
          this.request.loadingMore = false;
        }
      }
    },

    setPage(value) {
      this.page = value;
    },

    resetSearch(value) {
      this.request.loading = false;
      this.request.loadingMore = false;
      this.result = null;

      this.page = value;

      this.request.loadingMore = value;

      this.request.maxPage = value;

      this.request.isAtMaxPage = value;
    },

    async search(text) {
      try {
        const {
          type,
          fields,
          perpage,
          text: thisText,
          page,
          channelId,
          request: {
            loading,
            controller,
            loadingMore,
            isAtMaxPage
          },
          assetTypes: assettypes
        } = this;

        if (thisText !== text && text !== undefined) {
          this.text = text;
        }
        const durationfrom = this.duration.from ? this.duration.from : undefined;
        const durationto = this.duration.to ? this.duration.to : undefined;
        const widthfrom = this.width.from ? this.width.from : undefined;
        const widthto = this.width.to ? this.width.to : undefined;
        const heightfrom = this.height.from ? this.height.from : undefined;
        const heightto = this.height.to ? this.height.to : undefined;
        const bitratefrom = this.bitrate.from ? this.bitrate.from * 1000 : undefined;
        const bitrateto = this.bitrate.to ? this.bitrate.to * 1000 : undefined;
        const filesizefrom = this.filesize.from ? this.filesize.from * 1e+6 : undefined;
        const filesizeto = this.filesize.to ? this.filesize.to * 1e+6 : undefined;
        // No axios is used
        // if (loading) {
        //   // controller only for axios > 0.22 we are at 0.21 currently
        //   // controller.abort();
        //   // controller.cancel("Operation canceled due to new search");
        //   this.request.controller = null;
        // }
        if (text !== "") { // because text is a requirement
          this.result = null; // reset result
          if (page === 0 && !loadingMore) {
            this.request.loading = true;
          }
          if (!isAtMaxPage) {
            // const tempCancelToken = await axios.CancelToken.source();
            // this.request.controller = tempCancelToken;
            const result = await api.all({
                params: {
                  text,
                  channelId: channelId || undefined,
                  type,
                  perpage,
                  fields,
                  assettypes,
                  durationfrom,
                  durationto,
                  widthfrom,
                  widthto,
                  heightfrom,
                  heightto,
                  bitratefrom,
                  bitrateto,
                  filesizefrom,
                  filesizeto,
                  page
                },
                paramsSerializer: function(params) {
                  return qs.stringify(params, { arrayFormat: "repeat" });
                }
                // cancelToken: tempCancelToken.token
                // for axios >=v0.22
                // signal: controller.signal // abort last search if new search init
              }
            );
            const { data: resultData, status } = await result.json();
            if (status === "fail") {
              console.log(status + "No search results found");
              this.result = "empty";
              return "empty";
            }

            if (resultData) {
              const xTotalCount = Number(result.headers.get("x-total-count"));
              this.request.maxPage = (Math.ceil(xTotalCount / perpage) - 1);
              this.totalCount = xTotalCount;
              if (type === "mediaasset") {
                if (channelId) {
                  if (page > 0) {
                    this.request.loadingMore = false;
                    const value = {
                      singleChannel: remodelResult(resultData.mediaAssets, "media")
                    };
                    if (value.mediaAssets.length > 0) {
                      this.result.mediaAssets.push(...value.mediaAssets);
                    }
                  } else {
                    // this.request.controller = null;
                    this.request.loading = false;
                    this.result = {
                      singleChannel: remodelResult(resultData.mediaAssets, "media")
                    };
                  }
                } else {
                  if (page > 0) {
                    this.request.loadingMore = false;
                    if (resultData.mediaAsset.mediaAssets.length > 0) {
                      this.result.mediaAssets.push(...resultData.mediaAsset.mediaAssets);
                    }
                  } else {
                    // this.request.controller = null;
                    this.request.loading = false;
                    this.result = {
                      mediaAssets: remodelResult(resultData.mediaAssets, "media")
                    };
                  }
                }
              } else {
                if (type === "channel") {
                  if (page > 0) {
                    this.request.loadingMore = false;
                    const value = {
                      channels: remodelResult(resultData.channels, "channel")
                    };

                    if (value.mediaAssets.length > 0) {
                      this.result.mediaAssets.push(...value.mediaAssets);
                    }
                    this.request.loadingMore = false;
                    const newValue = {
                      channels: remodelResult(resultData.channels, "channel")
                    };
                    if (newValue.mediaAssets.length > 0) {
                      this.result.mediaAssets.push(...newValue.mediaAssets);
                    }

                  } else {
                    const value = {
                      channels: remodelResult(resultData.channels, "channel")
                    };
                    // this.request.controller = null;
                    this.request.loading = false;
                    this.result = value;
                  }
                }
              }
              this.sortOrder = "desc";
              return result.data;
            }
          } else {
            // isAtMaxPage
            this.request.loadingMore = false;
          }
        } else {
          // this.request.loading = false;
          this.request.loadingMore = false;
          this.result = null;
        }
      } catch (e) {
        // if (axios.isCancel(e)) {
        console.error(e);
        if (e) {
        } else {
          if (e.response) {
            if (e.response.status === 404) {
              // this.request.controller = null;
              this.request.loading = false;
              this.result = { error: true }; // or more detailed
              this.request.maxPage = 1;
              this.totalCount = 0;
            } else {
              // everything else
              // this.request.controller = null;
              this.request.loading = false;
              this.result = {
                error: true // or more detailed
              };
              this.request.maxPage = 1;
              this.totalCount = 0;
            }
          }
        }
      } finally {
        this.request.loading = false;
      }
    }
  }
});

