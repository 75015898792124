<template>
  <li>
    <sd-switch
      class="switch"
      :use-flex="true"
      v-if="type === 'toggle'"
      :label="$t(title)"
      :set-label-first="true"
      :value="storeVal"
      @update:value="storeVal = $event"
      label-size="small"
    />
    <!--    date not implemented yet -->
    <!--    <sd-date-->
    <!--      :label="$t(title)"-->
    <!--      v-else-if="type === 'date'"-->
    <!--    />-->
    <div class="range-input" v-else-if="type === 'range'">
      <sd-simple-input
        type="number"
        :label="$t(title)"
        :use-flex="true"
        :placeholder="placeholder"
        v-model="storeVal"
        class="col-span-2 text-xs"
      />
      <sd-range
        @input="e => storeVal = e.target.value"
        :selected-value="storeVal"
      />
    </div>

  </li>
</template>

<script setup lang="ts">
import { computed, watch, ref } from "vue";
import SdSwitch from "@/components/library/forms/SdSwitch.vue";
// import SdDate from '@Core/components/library/form/SdDate';
import SdRange from "@/components/library/forms/SdRange.vue";
import SdSimpleInput from "@/components/library/forms/SdSimpleInput.vue";
import { useSearchStore } from "@/stores/useSearch";
import { storeToRefs } from "pinia";

const searchStore = useSearchStore();
const searchStoreState = storeToRefs(searchStore);
const { search, setVal } = searchStore;

type Props = {
  title: string,
  storeKey: string,
  type: "toggle" | "date" | "slide" | "select",
  placeholder?: string,
  validation?: () => Boolean,
  min?: number,
  max?: number,
  step?: number

}
const props = defineProps<Props>();

const storeVal = computed({
  get: () => {
    const val = searchStore[props.storeKey.split("_")[0]];
    if (val) {
      if (Array.isArray(val)) {
        return val.includes(props.storeKey.split("_")[1]);
      } else {
        return val;
      }
    } else {
      const split = props.storeKey.split(".");
      return searchStore[split[0]][split[1]];
    }
  },
  set: async (val) => {
    const hasSplit = props.storeKey.split("_");
    if (hasSplit.length > 1) { // 1 because hasSplit will always return 1
      setVal({
        key: props.storeKey.split("_")[0],
        value: props.storeKey.split("_")[1]
      });
    } else {
      searchStore.$patch((state) => {
        const hastNestedObject = props.storeKey.split(".");
        if (hastNestedObject.length === 2) {
          state[hastNestedObject[0]][hastNestedObject[1]] = val;
        } else {
          state[props.storeKey] = val;
        }
      });
    }
    // searchStore.$reset();
    searchStore.$patch((state) => {
      // state[props.storeKey] = val;
      state.request.loading = false;
      state.request.loadingMore = false;
      state.result = null;
      state.page = 0;
      state.request.isAtMaxPage = 0;
    });

    await search(searchStoreState.text.value);
  }
});
</script>

<style scoped>
.switch {
  @apply justify-between py-1;
}

.switch :deep(label) {
  @apply text-xs;
}

.switch :deep(.toggle-container) {
  @apply m-0;
}

.range-input {
  @apply grid grid-flow-row grid-cols-3 text-xs items-center gap-2 py-1;
}

.range-input :deep(.text-area-container) {
  @apply w-16 bg-white;
}

.range-input :deep(.text-area-container) > .text-field {
  @apply text-xs;
}

.range-input :deep(label) {
  @apply text-xs;
}
</style>
