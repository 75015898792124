import { useFetch } from "@/utils/useService";

export default {
  getChannelMedia({ id, queryString = "" }) {
    if(id){
      return useFetch(`/channels/${id}/media${queryString}`, { method: "GET" });
    }
  },
  getChannelDetails({ id }) {
    if(id){
      return useFetch(`/channels/${id}`, { method: "GET" });
    }
  }
};