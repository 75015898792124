<script setup lang="ts">
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import BaseDate from "@/components/library/counter/BaseDate.vue";
import BaseAssetType from '@/components/library/ui/BaseAssetType.vue';
import BaseTruncator from "@/components/library/typography/BaseTruncator.vue";
import BasePlayer from "@/components/library/player/BasePlayer.vue";
import BaseViews from '@/components/library/counter/BaseViews.vue';
import { useMediaStore } from "@/stores/useMedia";
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import BaseImage from "@/components/library/ui/BaseImage.vue";
import BasePdf from "@/components/library/ui/BasePdf.vue";
import type { ChannelDetail } from "@/types/channelTypes";
import type { Media } from "@/types/mediaTypes";
import BaseExpandWrapper from "@/components/library/util/BaseExpandWrapper.vue";
import { useAppStore } from "@/stores/useApp";
import {useI18n} from 'vue-i18n';
import { useRoute } from 'vue-router';
import { Emitter } from 'mitt';
import ShareDialog from "@/components/library/ShareDialog.vue";
import { useChannelStore } from "@/stores/useChannel";

interface Props {
  mediaDetails: Media,
  channelDetails?: ChannelDetail,
}
const route = useRoute();
const { t } = useI18n();
const props = defineProps<Props>();
const { getPlaylistUrl, fetchChapter, fetchChapters } = useMediaStore();
const { state: channelState } = useChannelStore();
const { state: appState } = useAppStore();
const emitter = inject<Emitter<any>>('emitter')
const chapters = ref(null);
const shareDialogIsOpen = ref(false)

const playerReady = ref(false);

const rendition = computed(() => {
  try {
    if (props.mediaDetails?.renditions) {
      const rendition = getPlaylistUrl(props.mediaDetails.type, props.mediaDetails.renditions);
      return {
        source: rendition.source,
        type: rendition.mimeType,
        subtitles: props.mediaDetails.subtitles ? props.mediaDetails.subtitles : []
      };
    } else {
      return {};
    }
  } catch (e) {
    console.error(e);
  }
});
onMounted(() => {
  emitter.on('sd-player-ready', () => {
    playerReady.value = true;
  });
  emitter.on('sd-player-removed', () => {
    playerReady.value = false;
  });
  emitter.on('sd-player-play', () => {
    if(chapters.value?.length)
    emitter.emit('sd-player-load-chapters', JSON.parse(JSON.stringify(chapters.value)));
  });

})
onBeforeUnmount(() => {
  emitter.off('sd-player-ready');
  emitter.off('sd-player-removed');
})
watch(() => props.mediaDetails, async (newVal) => {
  const newMedia = newVal;
  let selectedChapter;
  if(newMedia.id){
    const chaptersList = await fetchChapters(newMedia.id);
    const channelContextChapter = chaptersList.find(chapter => chapter.channels.find(channel => channel.id === route.params.channelId));
    if(channelContextChapter){
      const data = await fetchChapter(newMedia.id, channelContextChapter.id);
      selectedChapter = data;
    }
    chapters.value = selectedChapter?.entries
      .map((chapter) => {
        if (chapter.section) {
          return chapter;
        } else {
          if (chapter.childs.length > 0) {
            return chapter.childs
              .map((subChapter) => {
                if (subChapter.childs.length > 0) {
                  return subChapter.childs.map((entry) => {
                    if (entry.childs.length > 0) {
                      return entry.childs.map((subEntry) => {
                        if (subEntry.childs.length > 0) {
                          return subEntry.childs;
                        } else {
                          return subEntry;
                        }
                      });
                    } else {
                      return entry;
                    }
                  });
                } else {
                  return subChapter;
                }
              })
              .flat(Infinity)
              .filter((subChapter) => subChapter && subChapter.section)
          }
        }
      })
      .flat(Infinity)
      .filter((chapter) => chapter && chapter.section)
      .map((chapter) => {
        return {
          start: chapter.section.start ?? 0,
          end: chapter.section.end,
          label: chapter.title,
          title: chapter.title,
          id: chapter.section.id,
          origin: selectedChapter.type,
        };
      });
  }
}, {immediate: true});

</script>

<template>
  <base-panel v-if='mediaDetails' class="player-wrapper" :class="{'!m-0': appState.isStandAlone, 'flex-grow': appState.isStandAlone, 'rounded-lg': !appState.isStandAlone}" :mediaType="mediaDetails.type">
    <base-image :thumbnail="mediaDetails.thumbnail"
                :alt="$t('altText.thumbnail', {type: mediaDetails.type, name: mediaDetails.name, description: mediaDetails.description})"
                :media-type="mediaDetails.type"
                v-if="mediaDetails.type === 'image'" />
    <base-player :id="mediaDetails.id" :key="mediaDetails.id"
                 :write-to-store="true"
                 :player-options="{
                    fluid: true,
                    autoplay: false,
                    controls: true,
                    preload: 'auto',
                    aspectRatio: appState.mediaCover ? mediaDetails.file.aspectRatio : '16:9',
                    controlBar: {
                    volumePanel: { inline: false }
                    },
                    html5: {
                    nativeAudioTracks: false,
                    nativeVideoTracks: false,
                    vhs: {
                    overrideNative: false,
                    bandwidth: 5260000,
                    limitRenditionByPlayerDimensions: false,
                    useDevicePixelRatio: true,
                    },
                    hls: {
                    overrideNative: false,
                    bandwidth: 5260000,
                    limitRenditionByPlayerDimensions: false,
                    useDevicePixelRatio: true,
                    },
                    },
                  }"
                 :thumbnail="mediaDetails.thumbnail"
                 v-if="(mediaDetails.type === 'video'|| mediaDetails.type === 'audio')"
                 :rendition="rendition"
                 :duration="mediaDetails.file.duration" />
    <base-pdf :id="mediaDetails.id" :key="mediaDetails.id"
              :page="appState.pdfPage"
              :source="mediaDetails.renditions[0].source"
              class="h-fit w-full"
              v-if="mediaDetails.type === 'document'"/>

  </base-panel>

  <base-panel class="px-6 py-4 h-fit" v-if="!appState.isStandAlone">
    <template v-if="mediaDetails.audit">
      <base-title class="title" tag="h5">{{ mediaDetails.name }}</base-title>
      <div class="w-full grid justify-items-end ">
        <base-button v-show="channelState.details.publicPortalSharingEnabled" class="share-button" @click="shareDialogIsOpen = true" :icon="{name: 'share-nodes', type: 'fa'}"></base-button>
      </div>
      <div class="asset-details">
        <div class='flex items-center gap-2'>
          <base-asset-type  :type='mediaDetails.type' :file='mediaDetails.file' />
          <base-views class='!text-base' :label="t('views')" :count='mediaDetails.usage.views'/>
        </div>
        <base-date class="inline-block" :date="mediaDetails.audit.createdAtUtc" :showTime="true" />
      </div>
      <base-expand-wrapper :data="mediaDetails.tags" />
      <base-truncator :class="{
        'mt-4': mediaDetails.tags.length === 0
      }" v-if="mediaDetails.description" :text="mediaDetails.description" :insert-html='true'/>
      <share-dialog
        v-if="shareDialogIsOpen"
        @close="shareDialogIsOpen=false"
        :title="t('share')"
        :id="mediaDetails.id"
        :duration="mediaDetails.file.duration"
        :embedViaJsText="mediaDetails.player"
        :aspectRatio="mediaDetails.file.aspectRatio"
      ></share-dialog>
    </template>
  </base-panel>

</template>

<style scoped lang="postcss">

:deep(.show-more-less-button) {
  @apply inline-block;
}

.title {
  line-height: normal;
}
/deep/ .hide {
  line-clamp: 5 !important;
  -webkit-line-clamp: 5 !important;
}

.title {
  @apply !text-xl lg:!text-2xl xl:!text-3xl;
//height: fit-content;
}
.asset-details {
  @apply flex gap-2 justify-between mt-2;
}
.asset-details >>> span, p.primary {
  @apply text-base;
}
.player-wrapper {
  @apply bg-transparent overflow-hidden mb-4 2xl:mb-3 h-full;

  &__player {
    @apply h-fit w-full;
  }
}

.share-button {
  @apply text-xl text-dark-500;
}

</style>