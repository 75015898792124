import { useRoute, useRouter } from "vue-router";
import { useChannelStore } from "@/stores/useChannel";
import { useChannelsStore } from "@/stores/useChannels";
import { useConfigStore } from "@/stores/useConfig";
import { computed, inject } from "vue";


export function useInfiniteScroll() {
  const route = useRoute();

  const { fetchMoreMedia, state: channelState } = useChannelStore();
  const { apiConfig, incrementPageCount } = useConfigStore();
  const { state: channelsState, fetchMore: fetchMoreChannels } = useChannelsStore();

  const mode = inject<"channels" | "channel">("mode");

  async function handleInfiniteScroll(isVisible) {
    // GUARD if we reached max page
    if (apiConfig.payloadConfig[mode].page >= apiConfig.payloadConfig[mode].MAX_PAGE) return;

    // Missing stop for total page
    try {
      if (isVisible && mode === "channel" && !channelState.isFetchingAllMedia && !channelState.isFetchingMoreMedia && route.params.channelId) {
        if(apiConfig.payloadConfig.channel.page < apiConfig.payloadConfig.channel.MAX_PAGE){
          incrementPageCount(mode);
          await fetchMoreMedia(route.params.channelId);
        }
      }

      if (isVisible && mode === "channels" && !channelsState.isFetchingAll && !channelsState.isFetchingMoreChannels) {
        if(apiConfig.payloadConfig.channels.page < apiConfig.payloadConfig.channels.MAX_PAGE){
          incrementPageCount(mode);
          await fetchMoreChannels();
        }
      }
    } catch (e) {
      throw e;
    }
  }
  // router.beforeEach((to, from, next) => {
  //   // resets channel channels default page count to 1
  //   if(to.name !== from.name){
  //     console.log('reset Page Count')
  //     resetPageCount(mode);
  //   }
  //   next();
  // });
  const isFetchingMore = computed(() =>
    channelState.isFetchingMoreMedia || channelsState.isFetchingMoreChannels
  );

  return { handleInfiniteScroll, isFetchingMore };
}