<template>
  <detail-layout class="layout-media" v-if="mediaState.details && channelState.details">
    <template #top>
      <!--      <base-panel class="p-2 mb-3">-->
      <!--        <search-bar />-->
      <!--      </base-panel>-->
      <base-panel class="breadcrumb-panel" v-if="mediaState.details">
        <base-breadcrumb class="breadcrumb-panel__component" :items="items" />
      </base-panel>
    </template>
    <template #default>
      <media-panel :key='mediaId' v-if="mediaState && channelState" :media-details="mediaState.details"
                   :channel-details="channelState.details" />
    </template>
    <template #right>
      <simple-tabs class="simple-tabs mb-4"  :tabs="tabs" :active-component="activeComponent" @change:tab="changeTab">
        <template #default>
          <!--          <transition name="slide-fade-right" mode="out-in">-->
          <component :is="activeTabComponent" v-bind="activeComponent.attrs"></component>
          <!--          </transition>-->
        </template>
      </simple-tabs>
    </template>
  </detail-layout>
</template>

<script lang="ts">
import TranscriptTab from "@/components/views/channelMediaView/tabs/TranscriptTab.vue";
import ChaptersTab from "@/components/views/channelMediaView/tabs/ChaptersTab.vue";
import SearchTab from "@/components/views/channelMediaView/tabs/SearchTab.vue";
import LinksTab from "@/components/views/channelMediaView/tabs/LinksTab.vue";
import PlaylistTab from "@/components/views/channelMediaView/tabs/PlaylistTab.vue";
import type { Tab } from "@/components/library/tabs/SimpleTabs.vue";


export default defineComponent({
  components: {
    TranscriptTab,
    SearchTab,
    ChaptersTab,
    LinksTab,
    PlaylistTab
  }
});
</script>

<script lang="ts" setup>
// ** IMPORT COMPONENTS ** //
import BaseBreadcrumb from "@/components/library/breadcrumbs/BaseBreadcrumb.vue";
import DetailLayout from "@/components/library/layouts/DetailLayout.vue";
import MediaPanel from "@/components/views/channelMediaView/MediaPanel.vue";
import BasePanel from "@/components/library/wrappers/BasePanel.vue";
import SimpleTabs from "@/components/library/tabs/SimpleTabs.vue";

// ** IMPORT FUNCTIONS ** //
import {
  onMounted,
  onUnmounted,
  computed,
  watch,
  provide,
  inject,
  watchEffect,
  ref,
  defineComponent
} from "vue";
import { Emitter } from "mitt";

// ** IMPORT STORES ** //
import { useChannelStore } from "@/stores/useChannel";
import { useMediaStore } from "@/stores/useMedia";
import { useConfigStore } from "@/stores/useConfig";
import { paths } from "@/settings/settings";
// ** IMPORT COMPOSABLES ** //
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useAppStore } from '@/stores/useApp';
// ** STORE ** //
const { state: mediaState, fetchDetailsFromChannel: fetchMediaDetails, getTranscription, getActiveTranscript, getMetadata, setPlayerTime, resetStore: resetMediaStore } = useMediaStore();
const { state: channelState, fetchDetails: fetchChannelDetails, fetchMedia } = useChannelStore();
// ** COMPOSABLES ** //
const { t } = useI18n();
const route = useRoute();

type Props = {
  mediaId: String // passed by router
  channelId: String // passed by router
}
const props = defineProps<Props>();

provide("mode", "channel");

const emitter = inject<Emitter<any>>("emitter");

const tabs = computed<Tab[]>(() => {
  return [
    {
      header: {
        heading: "Search",
        // actions: [{ icon: { name: "file-magnifying-glass", type: "fas" }, event: { name: "on:search", value: {} } }]
        actions: []
      },
      component: "search-tab",
      attrs: {
        disabled: true
      },
      setup: {
        icon: {
          name: "file-magnifying-glass",
          type: activeTabComponent.value === "search-tab" ? "fas" : "fal"
        },
        tooltip: "search-tab",
        attrs: {
          disabled: true
        }
      }
    },
    {
      header: {
        heading: t('tabs.transcription'),
        actions: []
      },
      component: "transcript-tab",
      attrs: {
        // disabled: mediaState.details.type === 'image' || !mediaState.transcription?.paragraphs?.length
        disabled: true
      },
      setup: {
        icon: {
          name: "message-text",
          type: activeTabComponent.value === "transcript-tab" ? "fas" : "fal"
        },
        tooltip: t('tabs.transcription'),
      }
    },
    {
      heading: "Search",
      component: "chapters-tab",
      attrs: {
        disabled: true
      },
      setup: {
        icon: {
          name: "list-tree",
          type: activeTabComponent.value === "chapters-tab" ? "fas" : "fal"
        },
        attrs: {
          disabled: true
        },
        tooltip: "chapters-tab"
      }
    },
    {
      component: "playlist-tab",
      attrs: {
        channelDetails: channelState.details, channelMedia: channelState.media
      },
      setup: {
        icon: {
          name: "rectangle-history",
          type: activeTabComponent.value === "playlist-tab" ? "fas" : "fal"
        },
        tooltip: t('tabs.playlist'),
      }
    },
    {
      component: "links-tab",
      attrs: {
        disabled: true
      },
      setup: {
        icon: {
          name: "link",
          type: activeTabComponent.value === "links-tab" ? "fas" : "fal"
        },
        tooltip: "links-tab"
      }
    }
  ].filter(e => !e.attrs.disabled);
});

const activeTabComponent = ref("");
const activeComponent = computed(() => {
  const activeTab = tabs.value.find((t) => t.component === activeTabComponent.value);
  return activeTab ? activeTab : tabs.value[0];
});

watchEffect(() => {
  if (channelState.details.name && mediaState.details?.name) {
    document.title = "Channel: " + channelState.details.name + "-" + mediaState.details.name;
  }
});

watch(() => mediaState.metadata, async (metadata) => {
  if (!metadata) return;
  const transcriptId = getActiveTranscript()?.id;
  if(!transcriptId){
    return;
  }
  await getTranscription({ mediaId: props.mediaId, transcriptId });
  try {
  } catch (e) {
    console.error(e);
  }
}, {
  immediate: true
});


watch(() => route.params, async (_) => {
  try {
    // Only fetch all if user does navigate directly
    if (!channelState.details.media) {
      await Promise.all([fetchMediaDetails(props.channelId, props.mediaId),
        // getMetadata(props.mediaId),
        fetchChannelDetails(props.channelId), fetchMedia(props.channelId)]);
    } else {
      await Promise.all([fetchMediaDetails(props.channelId, props.mediaId),
        // getMetadata(props.mediaId)
        fetchChannelDetails(props.channelId)
      ]);
    }
    activeTabComponent.value = tabs.value[0].component;
    emitter.on("get:playerTime", (value) => {
      setPlayerTime(value);
    });
    window.scrollTo(0,0)
  } catch (e) {
    e.mediaId = props.mediaId;
    e.channelId = props.channelId;
    throw e;
  }
}, {immediate: true});

const items = computed(() => [
  { label: t("portalHeader.dropdown.allChannels"), path: "/" },
  { label: channelState.details.name, path: paths.CHANNELS_ROUTE + props.channelId },
  { label: mediaState.details.name, path: paths.CHANNELS_ROUTE + props.channelId + paths.MEDIA_ROUTE + props.mediaId }
]);

function changeTab(tab: Tab) {
  activeTabComponent.value = tab.component;
}

onUnmounted(() => {
  resetMediaStore();
});
</script>

<style lang="postcss" scoped>

.layout-media {
  @apply pt-0;
}

.breadcrumb-panel {
  @apply bg-dark-100 p-3;

  :deep(.breadcrumb) {
    @apply text-xs lg:text-base;

    > :last-child {
      @apply text-xs lg:text-base;
    }
  }

  :deep(.chevron) {
    @apply text-light-500 text-[0.75rem]
  }
}

.panel {
  @apply h-full lg:max-h-[calc(100vh-10rem)] lg:overflow-y-auto py-4;
}

.simple-tabs {
  @apply flex h-full;

  > :first-child {
    @apply order-1
  }
}
</style>
