<template>
  <div class="section-wrapper">
    <!-- BTN LEFT -->
    <div class="chevron-container chevron--left" @click="prevSlide">
      <base-button
        class="chevron"
        :icon="{name:'chevron-left', type: 'far'}"
      ></base-button>
    </div>
    <!-- SLIDER  -->
    <div class="slider">
      <slot></slot>
    </div>
    <!-- BTN RIGHT -->
    <div class="chevron-container chevron--right" @click="nextSlide">
      <base-button
        class="chevron"
        :icon="{name:'chevron-right', type: 'far'}"
      ></base-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, watchEffect, computed, ref, nextTick, onMounted } from "vue";

// props //
const props = defineProps({
  slidesData: {
    type: Array,
    required: true
  },
  isUpdating: {
    type: Boolean,
    required: true
  }
});

// Data //
const curSlide = ref(0);
const slideElements = ref([]); // Node list

// computed //
const maxSlide = computed(() => props.slidesData.length);

// watch //
watch(
  maxSlide,
  (count) => {
    nextTick(() => {
      goToSlide(0);
    });
  },
  { immediate: true }
);

watchEffect(() => {
  // Re-evaluate if data has changed
  if (props.isUpdating) return; // Guard clause
  goToSlide(0);
  if (slideElements.value.length !== props.slidesData.length) {
    getSlideNodes();
    goToSlide(0);
  }
});

// functions //
function nextSlide() {
  // Go to next slide
  if (curSlide.value === maxSlide.value - 1) {
    curSlide.value = 0;
  } else {
    curSlide.value++;
  }
  goToSlide(curSlide.value);
}

function prevSlide() {
  // Go to previous slide
  if (curSlide.value === 0) {
    curSlide.value = maxSlide.value - 1;
  } else {
    curSlide.value--;
  }
  goToSlide(curSlide.value);
}

function goToSlide(slide) {
  // Put the slides in position
  nextTick(() => {
    // Compare the Dom nodes with the selected data list
    // next Tick to wait until DOM is updated
    if (slideElements.value.length !== props.slidesData.length) {
      getSlideNodes();
    }
    props.slidesData.forEach((_, index) => {
      // Only if slide nodes are available
      if (slideElements.value[index]) {
        slideElements.value[index].style.transform = `translateX(${
          100 * (index - slide)
        }%)`;
      }
    });
  });
}

function getSlideNodes() {
  if (!document) return; // In case of pre-rendered server-side)
  nextTick(() => {
    const allSlides = document.querySelectorAll("[data-slide=slide]") as HTMLDListElement[];
    if (allSlides.length) {
      slideElements.value = allSlides;
    }
  });
}

//  Lifecycle
onMounted(() => {
  // Sets initial featured channels
  getSlideNodes();
  goToSlide(0);
});
</script>

<style scoped>
/* LAYOUT */
.section-wrapper {
    @apply relative h-auto;
}

/* BUTTONS */
.chevron-container {
    @apply absolute top-1/2 z-10 -translate-y-1/2 flex items-center justify-center w-[3rem] h-[3rem] rounded-full bg-dark-200;
}

.chevron {
    @apply transition-colors text-white hover:text-secondary-500;
}

.chevron--right {
    @apply right-2;
}

.chevron--left {
    @apply left-2;
}

/* SLIDER */
.slider {
    @apply h-[17rem] w-full relative overflow-hidden;
}
</style>
