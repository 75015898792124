<template>
  <header class="header">
    <router-link
      v-if='mediaRoute'
      :to='`${mediaRoute}?searchStart=${timeStamps}`'
      class="text-sm underline">{{ formattedTimestamp }}</router-link>
    <div class="flex items-center gap-1">
      <p class="text-xs">
        {{ activeSection + 1 }}/{{ sections }}
      </p>
      <base-button
        :icon="{name:'chevron-up', type: 'fa-regular'}"
        class="icon"
        design="icon"
        :disabled="activeSection === 0"
        @click="$emit('updatePage', (activeSection - 1))"
      />
      <base-button
        :icon="{name:'chevron-down', type: 'fa-regular'}"
        class="icon"
        design="icon"
        :disabled="activeSection === sections - 1"
        @click="$emit('updatePage', (activeSection + 1))"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed, inject, onMounted } from 'vue';
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import { Emitter } from 'mitt';

const emitter = inject<Emitter<any>>("emitter");

const props = defineProps({
  activeSection: {
    type: Number,
    default: 0,
    validator(val) {
      return val >= 0;
    }
  },
  sections: {
    type: Number,
    default: 0,
    validator(val) {
      return val >= 0;
    }
  },
  timeStamps: {
    type: Number
    // add validator that matches time
    // validator: val => {
    //   //
    // }
  }
});

defineEmits(["updatePage"]);
const mediaRoute = inject('mediaRoute');
const formattedTimestamp = computed(() => {
    if (props.timeStamps) {
      const totalSeconds = props.timeStamps;
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      // Pad with leading zeros if necessary
      const hoursStr = String(hours).padStart(2, '0');
      const minutesStr = String(minutes).padStart(2, '0');
      const secondsStr = String(seconds).padStart(2, '0');
      return `${hoursStr}:${minutesStr}:${secondsStr}`;
    }
  })
;
onMounted(() => {
  emitter.emit('player-jump-to-seconds', props.timeStamps)
})
</script>

<style scoped>
.icon {
  @apply h-6 w-6 text-neutral-500 text-base bg-white;
}

.icon :deep(button:disabled),
:deep(button[disabled]) {
  @apply text-neutral-300 bg-white;
}

.icon:deep(button:disabled:hover),
button[disabled]:hover {
  @apply bg-gray-300;
}

.header {
  @apply flex justify-between items-center text-neutral-500;
}
</style>
