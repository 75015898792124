<script setup lang="ts">
import { reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";

interface Props {
  text: string,
  expandedLines?: number,
  insertHtml?: boolean
}

const { t } = useI18n();

const props = withDefaults(defineProps<Props>(), {
  expandedLines: 0
});

const textEl = ref(null);

const state = reactive({
  expandedLines: props.expandedLines,
  hasReachedEnd: false,
  isOverflowing: false
});

const buttonText = computed(() => {
  return !state.hasReachedEnd ? t("button.showAll") : t("button.showLess");
});


watchEffect(() => {
  if (textEl.value) {
    const isOverflown = ({ clientHeight, scrollHeight }) => {
      return scrollHeight > clientHeight;
    };
    if (!isOverflown(textEl.value)) {
      state.isOverflowing = false;
    } else {
      new ResizeObserver(function() {
        state.isOverflowing = textEl.value?.scrollHeight >= textEl.value?.getBoundingClientRect().height;
        const scrollPosition = textEl.value?.scrollLeft;
        const maxScroll = textEl.value?.scrollHeight - textEl.value?.clientHeight;
        state.hasReachedEnd = maxScroll <= scrollPosition;
      }).observe(textEl.value);
    }
  }
});

function expandText() {
  !state.hasReachedEnd ? state.expandedLines -= 3 : state.expandedLines += 3;
  checkIfReachedMaxHeight();
}

function checkIfReachedMaxHeight() {
  const hasReachedEnd = textEl.value.scrollHeight <= textEl.value.clientHeight;
  hasReachedEnd ? state.hasReachedEnd = true : state.hasReachedEnd = false;
}

</script>

<template>
  <p v-if="!insertHtml" v-bind="$attrs" ref="textEl" class="text description">{{ text }}</p>
  <div v-else v-bind="$attrs" ref="textEl" v-html="text" class="text description">
  </div>
  <base-button v-if="state.isOverflowing" type="button" class="show-more-less-button" @click="expandText">{{ buttonText
    }}
  </base-button>
</template>

<style scoped lang="postcss">
.text {
  @apply overflow-hidden break-words;
  display: -webkit-box;
  -webkit-line-clamp: v-bind(state.expandedLines); /* number of lines to show */
  line-clamp: v-bind(state.expandedLines);
  -webkit-box-orient: vertical;
}

.show-more-less-button {
  color: var(--mainColor);
}
</style>