<template>
  <base-panel class="advanced-search-container">

    <div class="tabs-container">
      <ul class="tabs-container__heading">
        <li v-for="(tab, i) in tabs" :class="['tab' ,{'tab--active': i === activeTabIndex}]" :key="tab.title + i">
          <base-button @click=" activeTabIndex = i">
            <h6>{{ tab.title }}</h6>
          </base-button>
        </li>
        <li  class="mobile-close-button">
          <base-button
            :use-ping="{isActive: !!searchState.fields.value.length, dotColor:'bg-primary-500', pingColor:'bg-primary-200'}"
            @click="updateState({key:'isExtendedSearchOpen', value: !appState.isExtendedSearchOpen})">
            <font-awesome-layers class="fa-xl cursor-pointer">
              <font-awesome-icon
                :class="{
                    opened: appState.isExtendedSearchOpen,
                  }"
                :icon="['fas', 'circle']"
              />

              <font-awesome-icon
                :class="{
                    opened: appState.isExtendedSearchOpen,
                  }"
                :icon="['fas', appState.isExtendedSearchOpen ? 'xmark' : 'bars-filter']"
                transform="shrink-7"
              >
              </font-awesome-icon>
            </font-awesome-layers>
          </base-button>

        </li>
      </ul>
      <search-advanced class="tabs-container__content" />
    </div>

  </base-panel>
</template>

<script setup lang="ts">
import BasePanel from "@/components/library/wrappers/BasePanel.vue";
import SearchAdvanced from "@/components/library/search/SearchAdvanced.vue";
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import { useSearchStore } from "@/stores/useSearch";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores/useApp";
import { ref } from "vue";

const { state: appState, updateState } = useAppStore();
const searchStore = useSearchStore();
const searchState = storeToRefs(searchStore);
const { search } = searchStore;

const activeTabIndex = ref(0);

const tabs = [{ title: "Advanced" }];

</script>

<style lang="postcss" scoped>
.advanced-search-container {
  @apply pb-4 h-[calc(100vh-5rem)] w-[17.5rem] rounded-lg overflow-hidden mr-2;

  &::-webkit-scrollbar {
    @apply hidden
  }

  .mobile-close-button {
    @apply lg:hidden absolute top-2 right-0 m-2 z-30;
  }
}

.tabs-container {
  @apply w-full overflow-y-auto h-[calc(100vh-9rem)];

  &__heading {
    @apply flex gap-2 overflow-y-auto bg-neutral-200 mb-4 relative;
  }

  &__content {
    @apply px-4;
  }
}

.tab {
  @apply px-4 py-4 transition-all;

  &--active {
    @apply text-dark-800 border-b-2 border-secondary-500;
  }
}


.fa-circle {
  @apply text-white transition;
}

.fa-bars-filter {
  @apply text-neutral-500 transition;
}

.fa-circle.active.opened,
.fa-circle.opened {
  @apply text-neutral-600 ;
}

.fa-circle.active {
  @apply text-primary;
}

.fa-xmark.opened,
.fa-bars-filter.active {
  @apply text-white;
}
</style>
