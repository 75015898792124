<script setup lang="ts">

import NavigationMenu from "@/components/NavigationMenu.vue";
import LowerMenu from "@/components/library/menus/sideMenu/LowerMenu.vue";
import { useConfigStore } from "@/stores/useConfig";

const { toggleMobileMenu } = useConfigStore();

function handleClick(event) {
  if (event.target.tagName === "A" || event.target.tagName === "BUTTON") {
    toggleMobileMenu();
  }
}
</script>

<template>
  <dialog class="nav-container" @click="handleClick">
    <navigation-menu />
    <lower-menu />
  </dialog>
</template>

<style scoped lang="postcss">
.nav-container {
  @apply lg:hidden flex flex-col justify-center items-center  absolute top-0 left-0 w-screen h-screen bg-[var(--pageBackgroundColor)]  z-40;

  :deep(.nav__list) {
    @apply flex-col gap-8;
  }

  :deep(.body-2),
  :deep(.nav__item) {
    @apply text-white text-xl;
  }

  :deep(.selector-container) {
    @apply border-none absolute top-[15%] left-12 -translate-x-1/2 -translate-y-1/2;
  }

  :deep(.nav__list) {
    @apply items-center;
  }
}
</style>