<template>
  <li class="item" @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave">
    <div class="first-row">
      <router-link :to="mediaRoute">
        <base-title tag="h6" v-for="name in nameHighlights" v-html="name" v-if="nameHighlights?.length" />
        <base-title tag="h6" v-else v-html="name" />
      </router-link>

    </div>
    <div class="second-row">
      <component class="portal-card__header media-player" :is="mediaViewComponent" :route="mediaRoute"
                 :thumbnail="thumbnail"
                 :source="type === 'document' && placeholder"
                 :media-type="type" :type="thumbnail"
                 :rendition="{source: preview?.url, mimeType:'application/x-mpegurl'}"
                 :preview="preview"
                 :alt="$t('altText.thumbnail', {type: type, name: name, description: description})"
                 :id="id" />
      <search-tab-bar :tabs="tabs" :id="id"/>
    </div>
  </li>
</template>

<script setup lang="ts">
import { watchEffect, computed, ref, provide } from 'vue';
import BaseImage from "@/components/library/ui/BaseImage.vue";
import placeholder from "@/assets/placeholder.png";
import { paths } from "@/settings/settings";
import { useRoute } from "vue-router";
import BaseTitle from "@/components/library/typography/BaseTitle.vue";
import SearchTabBar from "@/components/library/search/SearchTabBar.vue";
import BasePreview from "@/components/library/player/BasePreview.vue";

const route = useRoute();

type Tag = {
  type: "text",
  value: string,
}

type Child = Tag[]

interface Tab {
  title: string,
  children: Child[]
}

interface Channel {
  id: string;
  name: string;
  thumbnailUrl: string;
}

interface ChannelSearch {
  audit: { createdAtUtc: string, createdBy: object, updatedAtUtc: string, updatedBy: object };
  bitrate: number;
  channels: Channel[];
  description: string;
  descriptionHighlight?: string[];
  featuredAtUtc: string | null;
  fileSize: number;
  videoHeight: number;
  videoWidth: number;
  id: string;
  preview: {
    url: string;
  };
  manualSort: number;
  media: Media[];
  mediaCount: number;
  nameHighlights?: string[];
  name: string;
  tabs: [];
  tags: Tag[];
  thumbnail: Thumbnail;
  type: string | undefined;
  visibility: string;
}

interface Media {
  id: string,
  name: string,
  thumbnailUrl: string,
  description?: string,
  preview?: {
    url: string
  }
  type: string,
}

interface Thumbnail {
  height: number,
  url: string,
  width: number
}


// interface Props extends PublicChannel{
//   audit: {};
//   featuredAtUtc?: string | null;
//   id: string;
//   manualSort: number;
//   media: [];
//   mediaCount: number;
//   name: string;
//   tags: string[];
//   thumbnail: {};
//   type: string;
//   visibility: string;
//
// }

const props = defineProps<ChannelSearch>();

const delayTimeoutForComponentChange = ref(0);
const isItemHovered = ref(false);
const shouldChangeComponent = ref(false);

const mediaRoute = computed(() => {
  // On channel detail page we can take advantage of route
  if (route.params.channelId) {
    return paths.CHANNELS_ROUTE + route.params.channelId + paths.MEDIA_ROUTE + props.id;
  } else {
    // On channels page we get the channel id as a prop
    return paths.CHANNELS_ROUTE + props.id;
  }
});
provide('mediaRoute', mediaRoute.value)
const mediaViewComponent = computed(() => shouldChangeComponent.value && (props.type === "video" || props.type === "audio") ? BasePreview : BaseImage);

watchEffect(async () => {
  clearTimeout(delayTimeoutForComponentChange.value);

  if (isItemHovered.value) {
    shouldChangeComponent.value = await new Promise(resolve => {
      delayTimeoutForComponentChange.value = setTimeout(() => {
        resolve(true);
      }, 1000);
    });
  } else {
    shouldChangeComponent.value = false;
  }
});

function handleMouseEnter() {
  isItemHovered.value = true;
}

function handleMouseLeave() {
  isItemHovered.value = false;
}

</script>

<style lang="postcss" scoped>
.item {
  @apply px-6 py-4 border-b border-neutral-300 box-border flex flex-col;

  .first-row {
    @apply w-full max-w-full flex justify-between items-center mb-4 gap-2 xl:gap-5 overflow-x-hidden;
  }
}

.second-row {
  @apply flex items-start gap-4 md:gap-3 xl:gap-5;
}

.second-row :deep(d) l {
  @apply text-base;
}

.second-row :deep(.text) {
  @apply text-base;
}

.second-row :deep(.header) {
  @apply text-base;
}

.second-row :deep(.transcript) {
  @apply text-base;
}

.second-row :deep(.date) {
  @apply text-base;
}

.media-player {
  @apply hidden sm:block flex-shrink-0 w-28 md:w-60 border-none rounded-lg transition-all;
}

.link {
  @apply flex-1 py-[7.5px] text-black w-3/4;
}

.title {
  @apply break-all w-full h-auto font-medium text-[1.125rem];
}

.actions {
  @apply flex flex-shrink items-center justify-end gap-5;
}

.info {
  @apply flex flex-1 gap-2 items-center overflow-hidden;
}
</style>
