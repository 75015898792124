<template>
  <div class="h-full">
    <search-time-header
      :active-section="selectedTime"
      :sections="tagMatch.length"
      :time-stamps="timeStamps"
      @updatePage="(val) => (selectedTime = val)"
    />
    <div class="pt-2 w-full overflow-scroll">
      <div class="big-metadata-timeline">
        <metadata-timeline
          :title="tag.title"
          v-for="(tag, index) in mergedTimeLine"
          :key="'metadata' + index"
          :video-duration="tag.value.videoDuration"
          :meta-data="[
            {
              ...tag.value,
              name: tag.value.lines,
              instances: [
                {
                  start: tag.value.start,
                  duration: tag.value.duration
                }
              ]
            }
          ]"
          :color="getMetaColor(tag.title)"
          @updateActiveSection="setSelected($event)"
        />
      </div>
      <div class="flex flex-col py-2" v-for="child in children">
        <div v-for="tag in sortedTags(child.tags)" class="meta-row">
          <metadata-tag
            :selected="tagMatch[selectedTime]?.lines === tag.value?.lines"
            :color="getMetaColor(child.title)"
            @click.native="
              setSelected({
                name: tag.value?.lines,
                type: child.title
              })
            "
          >
            {{ replaceEmTag(tag.value?.lines) }}
          </metadata-tag>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// TO BE IMPLEMENTED
import { ref, computed, inject, watch, onMounted } from 'vue'
import SearchTimeHeader from '@/components/library/search/SearchTimeHeader.vue'
import MetadataTimeline from '@/components/library/metaData/MetadataTimeline.vue'
import MetadataTag from '@/components/library/metaData/MetadataTag.vue'
import { useDateFormat } from '@vueuse/core'
import { useMetaDataStore } from '@/stores/useMetaData'

const { state: metaState } = useMetaDataStore()

type Props = {
  children: {
    title?: string // Object, Keywords, etc
    tags: {
      value: { lines: string; instances: any[]; videoDuration: Number; start: Number } //
      color: string
      component: string
    }[]
  }[]
}

const props = defineProps<Props>()
const emitter = inject('emitter')
const selectedTime = ref(0) // select time

const mergedTimeLine = computed(() => {
  return props.children
    .map((e) => {
      return e.tags.map((f) => {
        return { ...f, title: e.title, start: f.value.start }
      })
    })
    .flat(2)
    .sort((a, b) => a.start - b.start)
})

const tagMatch = computed(() => {
  return props.children
    .map((child) => {
      return child.tags
        .map((tag) => {
          return tag.value
        })
        .flat(2)
    })
    .flat(2)
    .sort((a, b) => a.start - b.start)
})

const timeStamps = computed(() => {
  if (tagMatch.value[selectedTime.value]?.start !== null) {
    return tagMatch.value[selectedTime.value].start
  } else {
    return ''
  }
})
const getMetaColor = (val: string) => {
  return metaState.colors[val.toLowerCase()]
}

const sortedTags = (tags) => {
  return tags.sort((a, b) => a.value.start - b.value.start)
}

function replaceEmTag(val: string) {
  if (!val) return
  return val.replaceAll('<em>', '').replaceAll('</em>', '')
}

function setSelected(selected) {
  if (selected.start) {
    selectedTime.value = tagMatch.value.findIndex((e, i) => {
      return e.start === selected.start
    })
    return
  }
  selectedTime.value = tagMatch.value.findIndex((e) => {
    return e.lines === selected.name
  })
}

watch(
  props,
  () => {
    selectedTime.value = 0
  },
  { deep: true }
)

watch(selectedTime, () => {
  emitter.emit('player-jump-to-seconds', tagMatch.value[selectedTime.value].start)
})
</script>
<style></style>
<style scoped>
span {
  @apply inline-block text-neutral-500 border rounded mr-0 mb-0 cursor-pointer pt-1 pb-[5px] px-2;
  font-weight: 500;
  font-size: 12px;
  line-height: 15.22px;
  letter-spacing: 0.2px;
}

.big-metadata-timeline {
  @apply h-4 relative bg-neutral-200 overflow-hidden;
}

.meta-row {
  @apply flex flex-wrap items-center gap-2 pt-2;
}
</style>
