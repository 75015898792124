import { useFetch } from "@/utils/useService";

export default {
  getDetails(id) {
    return useFetch("/media/" + id, { method: "GET" });
  },

  getDetailsFromChannel(channelId, mediaId) {
    return useFetch("/media/" + mediaId + `?channelId=${channelId}`, { method: "GET" });
  },
  getChapters(mediaId){
    return useFetch(`/media/${mediaId}/chapters`, {method: 'GET'});
  },
  getChapterById(mediaId, chapterId){
    return useFetch(`/media/${mediaId}/chapters/${chapterId}`, {method: 'GET'});
  }
};