<template>
  <transition-group name="fade" class="tag-wrapper" tag="ul" v-if="paginatedTags?.length > 0">
    <base-tag v-for="(item, index) in paginatedTags" :key="item + index" class="{!cursor-pointer: allowSearch}"
              @click="handleTagClick(item)"
              size='small'
    >{{ item }}
    </base-tag>
    <li class="basis-full relative z-10" key="buttons">
      <transition name="fade-absolute">
        <base-button class="btn" v-if="data.length > 10 && state.maxTags + 1 <= data.length "
                     @click.native="state.maxTags = data.length">{{ $t("button.showAll") }}
        </base-button>
      </transition>
      <base-button class="btn" v-if="data.length > 10 && state.maxTags - 10"
                   @click.native="state.maxTags = 10">{{ $t("button.collapse") }}
      </base-button>
      <transition name="fade-absolute">
      </transition>
    </li>
  </transition-group>
</template>

<script setup lang="ts">
import { reactive, computed } from "vue";

import BaseTag from "@/components/library/ui/BaseTag.vue";
import BaseButton from "@/components/library/buttons/BaseButton.vue";
import { useSharedPortal } from "@/composables/useSharedPortal";

type Props = {
  data: string[]
  allowSearch?: boolean,
};

const props = withDefaults(defineProps<Props>(), {
      data: []
    })
;

const state = reactive({
  maxTags: 10,
  isMenuOpen: false
});


const paginatedTags = computed(() => props.data.slice(0, state.maxTags));

function onTagClick(tag: string) {
  if (props.allowSearch) {
    handleTagClick(tag);
  }
}

const { handleTagClick } = useSharedPortal();

</script>

<style lang="postcss" scoped>
.tag-wrapper {
  @apply mt-2 mb-4 flex flex-wrap gap-2 items-center;
}

.btn {
  @apply transition-all text-primary-600 hover:text-black font-bold;
}

.hide-tag {
  @apply hidden pointer-events-none
}
</style>
