<template>
  <header>
    <router-link to="/channels">
      <base-image :source="tenantDesignList.logoUrl ? tenantDesignList.logoUrl : defaultImage" class="image-wrapper"
                  imgMode="image" alt="Logo" />
    </router-link>
    <navigation-menu class="desktop-nav" />
    <base-hamburger class="btn-hamburger" />
    <teleport to="#app">
      <transition name="fade-down">
        <mobile-navigation open v-if="appSettings.isMobileNavOpen" />
      </transition>
    </teleport>
  </header>
</template>

<script setup lang="ts">
// *** COMPONENTS *** //
import NavigationMenu from "@/components/NavigationMenu.vue";
import BaseImage from "@/components/library/ui/BaseImage.vue";

// *** IMPORTS FOR STORE *** //
import { useStyleStore } from "@/stores/useStyleApp";
import BaseHamburger from "@/components/library/menus/BaseHamburger.vue";
import MobileNavigation from "@/components/library/navigation/MobileNavigation.vue";
// *** IMPORTS FUNCTIONS *** //
import { useConfigStore } from "@/stores/useConfig";
// *** STATICS *** //
import defaultImage from "@/assets/icon.png";

const {tenantDesignList } = useStyleStore();

const { appSettings } = useConfigStore();


</script>

<style lang="postcss" scoped>
header {
  @apply w-full p-4 flex justify-between items-center;
  color: var(--headerFontColor);
  background-color: var(--headerColor);
  font-family: 'headerFont', sans-serif;

  .image-wrapper {
    @apply h-[3rem];

    :deep(img) {
      @apply h-full;
    }
  }
}

.btn-hamburger {
  @apply block lg:hidden;
}

.desktop-nav {
  @apply hidden lg:block
}

</style>