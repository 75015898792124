<template>
  <div class="wapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "BaseWrapper"
};
</script>

<style lang="postcss" scoped>
.wapper {
    @apply overflow-x-hidden;
}
</style>
