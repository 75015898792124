import { useFetch } from "@/utils/useService";
import { createQueryString } from "@/utils/functions/useString";

const uploadUrl = "/search";
export default {
  all(options = {}) {

    let queryString = createQueryString(options.params);

    // remove last & from query string
    if (queryString.at(-1) === "&") {
      queryString = queryString.slice(0, -1);
    }
    return useFetch(`${uploadUrl}${queryString}`, {
      method: "GET"
    });
  }
};